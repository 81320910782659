import React, { useEffect, useState, useContext } from 'react';
import Sidebar from '../components/Sidebar';
import ChatSection from '../components/ChatSection';
import Navbar from '../components/Navbar';
import MainSidebar from '../components/MainSidebar';
import { AuthContext } from '../context/AuthContext'; // Import the AuthContext to access authState

const ChatPage = () => {
  const { authState } = useContext(AuthContext); // Access authState from context
  const [selectedChat, setSelectedChat] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    
    // Initial check
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if the user is a creator
  const user = authState?.user;
  const isCreator = user?.user_type === 'creator';

  return (
    <div className="min-h-screen bg-black h-full text-white font-dm-sans">
      {/* Conditionally render MainSidebar for creator, Navbar for regular user */}
      {isCreator ? <MainSidebar /> : <Navbar />}
      
      <div className={`flex h-[calc(100vh-80px)] ${isCreator ? 'pt-0' : 'pt-0'}`}>

        {/* Sidebar for Contacts */}
        {(!isMobileView || (isMobileView && !selectedChat)) ? (
          <Sidebar setSelectedChat={setSelectedChat} />
        ) : null}

        {/* Chat Section */}
        <div className="flex-1">
          {selectedChat ? (
            <ChatSection 
              selectedChat={selectedChat} 
              isMobileView={isMobileView} 
              setSelectedChat={setSelectedChat} // Pass the setter function
            />
          ) : (
            isMobileView ? (
              <div></div>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-500">
                Select a contact to start chatting
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
