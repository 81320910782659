import React from "react";
import { Link } from "react-router-dom";

function SubFooter() {
  const sharedClasses = {
    footerText: "font-roboto font-semibold text-base",
  };

  return (
    <footer className="p-4">
      <div className="flex flex-wrap justify-center space-x-6 md:space-x-12">
        <Link to="/" className={sharedClasses.footerText}>
          About
        </Link>
        <Link to="/contact-us" className={sharedClasses.footerText}>
          Contact
        </Link>
        <Link to="/tos" className={sharedClasses.footerText}>
          Terms of Service
        </Link>
        <Link to="/policy" className={sharedClasses.footerText}>
          Privacy Policy
        </Link>
        <Link to="/socials" className={sharedClasses.footerText}>
          Social Media
        </Link>
      </div>
    </footer>
  );
}

export default SubFooter;
