import React, { useState, useEffect, useRef, useContext } from "react";
import { db, storage } from "../firebase";
import { FaArrowLeft } from "react-icons/fa";


import Default from "../assets/default.jpg";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDoc,
  onSnapshot,
  doc,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from "../context/AuthContext";

const ChatSection = ({ selectedChat, isMobileView, setSelectedChat  }) => {
  const { authState } = useContext(AuthContext);
  const user = authState?.user;

  const [chatID, setChatID] = useState(selectedChat.id || null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [media, setMedia] = useState(null); // To hold the selected media file
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // Control the image modal visibility
  const [imageToShow, setImageToShow] = useState(null); // Store the image to show in the modal
  const [loader, setLoader] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [noChats, setNoChats] = useState(false);
  const [contentCreatorAvatar, setContentCreatorAvatar] = useState(null);
  const chatEndRef = useRef(null);
  const fileInputRef = useRef(null); // Reference to the hidden file input
  const unsubscribeRef = useRef(null);


  useEffect(() => {
    setNoChats(false);
    setChatID(selectedChat.id);
    fetchProfilePicture(selectedChat.participants[0])
    setMessages([]); // Clear messages when switching chats
    fetchChat();

    return () => {
      // Clean up the old listener if any
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [selectedChat]);

  const base64ToBlob = (base64, contentType = 'image/jpeg') => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  };
  
  // Function to fetch profile picture and set it in state
  const fetchProfilePicture = async (id) => {
    try {
      console.log(id);
      const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/get-avatar/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch profile picture');
      }
  
      const data = await response.json();
      const { profilePictureUrl } = data;
  
      // Extract the base64 string from the URL if it is in base64 format
      const base64String = profilePictureUrl.split(',')[1]; // Adjust based on the format
  
      // Convert the base64 string to a Blob
      const blob = base64ToBlob(base64String, 'image/jpeg');
  
      // Create a URL for the Blob
      const imageUrl = URL.createObjectURL(blob);
  
      setContentCreatorAvatar(imageUrl);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
      // return null;
    }
  };



  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Ensure the chat is scrolled to the bottom when messages are updated
  }, [messages]);

  const sendMessage = async () => {
    try {
      let content = newMessage;
      setNewMessage("");
      let mediaUrl = null;

      if (media) {
        setSendLoader(true);
        console.log("Media Present");
        const mediaRef = ref(storage, `chats/${chatID || "new"}/${media.name}`);

        const snapshot = await uploadBytes(mediaRef, media);

        mediaUrl = await getDownloadURL(snapshot.ref);
      }

      if (chatID) {
        console.log("Chat exists");
        await addDoc(collection(db, "chats", chatID, "messages"), {
          content: content,
          sender: user.user_id,
          senderName: user.user_name,
          type: media
            ? media.type.startsWith("image")
              ? "image"
              : "video"
            : "text",
          timestamp: serverTimestamp(),
          mediaUrl,
          senderType: user.user_type,
        });
      } 
      else {
        console.log("Chat does not exist");
      }

      
      setMedia(null);
      setMessages([
        ...messages,
        {
          id: messages.length + 1,
          content: newMessage,
          sender: user.user_id,
          senderName: user.user_name,
          type: media
            ? media.type.startsWith("image")
              ? "image"
              : "video"
            : "text",
          timestamp: serverTimestamp(),
          mediaUrl,
          senderType: user.user_type,
        },
      ]);
      setSendLoader(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const fetchChat = async () => {
    try {
      setLoader(true);
      if (selectedChat && selectedChat.id) {
        const chatRef = doc(db, "chats", selectedChat.id);
        const chatSnapshot = await getDoc(chatRef);

        if (chatSnapshot.exists()) {
          const existingChat = { id: chatSnapshot.id, ...chatSnapshot.data() };

          setChatID(existingChat.id); // Update chatID state

          const messagesRef = collection(db, "chats", existingChat.id, "messages");
          const messagesQuery = query(messagesRef, orderBy("timestamp", "asc"));

          // Clean up the old listener before setting a new one
          if (unsubscribeRef.current) {
            unsubscribeRef.current();
          }

          // Listen for real-time updates with onSnapshot
          unsubscribeRef.current = onSnapshot(messagesQuery, (messagesSnapshot) => {
            const messagesData = messagesSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setMessages(messagesData); // Store the messages in state
            console.log(messagesData);
            setLoader(false);
          });
        } else {
          setLoader(false);
          setNoChats(true)
          setMessages([]);
           // No chat found, clear messages
        }
      }
    } catch (error) {
      console.error("Error fetching chat:", error);
    }
  };

  useEffect(() => {
    console.log(messages);
  }, [messages]);
  

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type.startsWith("image") || file.type.startsWith("video"))
    ) {
      setMedia(file); // Set the selected file as media
    }
  };

  // Trigger file input click when the icon is clicked
  const handleMediaIconClick = () => {
    fileInputRef.current.click(); // Simulate click on hidden file input
  };

  // Open the modal to show the clicked image
  const handleImageClick = (image) => {
    setImageToShow(image); // Set the image to be shown
    setIsImageModalOpen(true); // Open the modal
  };

  // Close the modal
  const closeModal = () => {
    setIsImageModalOpen(false); // Close the modal
    setImageToShow(null); // Clear the image to show
  };

  // Close modal when clicking outside of the image
  const handleOverlayClick = (e) => {
    if (e.target.className.includes("modal-overlay")) {
      closeModal();
    }
  };

  function convertFirestoreTimestampToAMPM(timestamp) {
    // If timestamp is invalid, use the current time
    if (!timestamp || !timestamp.seconds) {
      const now = new Date();
      return formatDate(now);
    }
  
    // Convert Firestore timestamp to Date object
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  
    // Format the date and time
    return formatDate(date);
  }
  
  function formatDate(date) {
    // Extract date components
    const month = date.getMonth() + 1; // getMonth() is 0-indexed, so add 1
    const day = date.getDate();
    const year = date.getFullYear();
  
    // Extract time components
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
  
    // Format with leading zeros for minutes
    const minutesFormatted = minutes < 10 ? "0" + minutes : minutes;
  
    // Return formatted date and time in the format MM/DD/YYYY h:mm AM/PM
    return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year} ${hours}:${minutesFormatted} ${ampm}`;
  }

  function capitalizeWords(str) {
    return str
      .split(' ') // Split the string into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
      .join(' '); // Join the words back into a string
  }
  
  // Example usage
  const firestoreTimestamp = { seconds: 1726055707, nanoseconds: 27000000 };
  return (
    <div className="flex flex-col h-full bg-black w-full">
      {/* Chat Header */}
      <div className="flex items-center p-4 bg-black border-b border-slate-400">
        {isMobileView && (
          <div className="mr-2 cursor-pointer" onClick={() => setSelectedChat(null)}>
            <FaArrowLeft size={24} /> {/* Optional: Adjust size as needed */}
          </div>
        )}
        <img
          src={selectedChat.picture || Default}
          alt={selectedChat.chatName}
          className="w-10 h-10 rounded-full mr-3 object-cover"
        />
        <h2 className="text-lg font-semibold text-white truncate">{selectedChat.chatName}</h2> {/* Added truncate for overflow handling */}
      </div>


      {/* Chat Messages */}
      <div className="flex-1 p-4 overflow-y-auto flex flex-col-reverse">
  <div>
    {messages.length === 0 && (
      <>
        {noChats ? (
          <div className="text-center text-gray-500">No chats found. Start a new chat</div>
        ) : loader ? (
          <div className="text-center text-gray-500">Loading...</div>
        ) : (
          <div className="text-center text-gray-500">Say Hi to start the conversation</div>
        )}
      </>
    )}
    {messages.map((message, index) => {
  const isSameSenderAsPrevious =
    index > 0 &&
    message.sender === messages[index - 1].sender &&
    message.senderType === messages[index - 1].senderType;

  return (
    <div
      key={message.id}
      className={`mb-${isSameSenderAsPrevious ? '4' : '1'} flex items-end ${message.sender === user?.user_id && message.senderType === user?.user_type ? "justify-end mr-1" : "justify-start"}`}
    >
      {/* Render avatar only for received messages */}
      {(message.sender !== user?.user_id || message.senderType !== user?.user_type) && (
        <img
          src={message.senderType === 'creator' ? contentCreatorAvatar : Default} // Use the default image as a fallback
          alt="Avatar"
          className="w-12 h-12 rounded-full mr-3 object-cover"
        />
      )}

      <div
        className={`flex flex-col p-2 rounded break-word max-w-[70%] ${
          message.sender === user?.user_id && message.senderType === user?.user_type 
            ? "bg-[#493676] text-white" 
            : message.senderType === "creator" 
              ? "bg-blue-900" // Use the appropriate Tailwind color class or hex code for light blue
              : "bg-gray-800"
        }`}
      >
        {message.type === "text" && (
          <>
            <div
              className={`flex items-center ${
                message.sender === user?.user_id && message.senderType === user?.user_type
                  ? 'justify-end'
                  : 'justify-start'
              }`}
            >
              <p className="text-white font-bold text-lg font-roboto mr-4">
                {capitalizeWords(message?.senderName)}
              </p>
              <div className="text-xs text-gray-400 mr-2">
                {convertFirestoreTimestampToAMPM(message.timestamp)}
              </div>
            </div>
            <span className="whitespace-pre-wrap overflow-hidden text-overflow-ellipsis">
              {message.content}
            </span>
          </>
        )}
      </div>
    </div>
  );
})}

    <div ref={chatEndRef} />
  </div>
</div>

      {/* Input Section */}
      <div className="p-4 border-t border-slate-400 bg-black">
  {media && (
    <div className="mt-2 mb-4 flex justify-center">
      {media.type.startsWith("image") && (
        <img
          src={URL.createObjectURL(media)}
          alt="preview"
          className="w-full max-w-[150px] border rounded-lg" // Adjusted width
        />
      )}
      {media.type.startsWith("video") && (
        <video controls className="w-full max-w-[150px] rounded-lg"> 
          <source src={URL.createObjectURL(media)} type={media.type} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  )}
  <div className="flex items-center">
    <input
      type="text"
      className="flex-1 p-2 border border-gray-300 rounded text-black"
      placeholder="Type a message..."
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      onKeyDown={(e) => {
      if (e.key === "Enter" && !sendLoader && (newMessage !== "" || media !== null)) {
        sendMessage();
      }
    }}
    />

    {/* Hidden File Input */}
    <input
      type="file"
      accept="image/*,video/*"
      className="hidden"
      ref={fileInputRef}
      onChange={handleMediaChange}
    />
    <button
      onClick={sendMessage}
      className="ml-2 px-4 py-2 bg-[#493676] text-white rounded hover:bg-[#493676]/80"
      disabled={(newMessage === "" && media === null) || sendLoader}
    >
      {sendLoader ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 animate-spin mx-auto fill-white"
          viewBox="0 0 32 32"
        >
          <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
        </svg>
      ) : (
        "Send"
      )}
    </button>
  </div>
</div>
    </div>
  );
};

export default ChatSection;
