import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import Logo from '../assets/logo.png';

const ProfileComplete = () => {
    const { authState } = useContext(AuthContext);
    const [dob, setDob] = useState({ day: '1', month: 'Jan', year: `${new Date().getFullYear()}` });
    const [gender, setGender] = useState(''); // Set a default value for gender
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");


    const handleDobChange = (e) => {
        const { id, value } = e.target;
        setDob((prevDob) => ({
            ...prevDob,
            [id]: value,
        }));
    };

    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };

    const handleSubmit = async () => {

        if (gender === '') {
            setErrorMessage('Please enter your gender.');
            return;
        }

        const formattedDob = `${dob.year}-${dob.month}-${dob.day}`;
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/complete-profile`, 
                { dob: formattedDob, gender }, 
                {
                    headers: {
                        Authorization: `Bearer ${authState.accessToken}`,
                    },
                }
            );
    
        // Check if the response includes the redirectUrl
        if (response.status === 200) {
            navigate('/search-subscriptions');
        }

        } catch (error) {
            console.error('Error completing profile:', error);
        }
    };
    
    return (      
        <div className="min-h-screen flex items-center justify-center bg-[#131313] font-roboto text-white">
            <div className="w-full max-w-md p-8 space-y-6">
                <div className="text-center">
                    <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
                    <h2 className="text-2xl font-bold font-dm-sans">Complete your Profile</h2>
                </div>
                <div className="space-y-4">
                    <div className="mb-4">
                        <label htmlFor="dob" className="block text-sm font-medium mb-1">Date of birth <span className="text-muted-foreground">ⓘ</span></label>
                        <div className="flex space-x-2">
                            <select id="day" onChange={handleDobChange} className="bg-[#131313] border border-border text-white rounded-md p-2 w-1/3 max-h-40 overflow-y-auto">
                                {Array.from({ length: 31 }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                ))}
                            </select>
                            <select id="month" onChange={handleDobChange} className="bg-[#131313] border border-border text-white rounded-md p-2 w-1/3 max-h-40 overflow-y-auto">
                                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(month => (
                                    <option key={month} value={month}>{month}</option>
                                ))}
                            </select>
                            <select id="year" onChange={handleDobChange} className="bg-[#131313] border border-border text-white rounded-md p-2 w-1/3 max-h-40 overflow-y-auto">
                                {Array.from({ length: 50 }, (_, i) => (
                                    <option key={new Date().getFullYear() - i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="gender" className="block text-sm font-medium mb-1">Gender <span className="text-muted-foreground">ⓘ</span></label>
                        <div className="flex space-x-2">
                            <label className="flex items-center bg-input border border-border text-foreground rounded-md p-2 w-1/3 cursor-pointer accent-[#7055ad]">
                                <input type="radio" name="gender" value="female" className="form-radio text-primary mr-2" onChange={handleGenderChange} />
                                Female
                            </label>
                            <label className="flex items-center bg-input border border-border text-foreground rounded-md p-2 w-1/3 cursor-pointer accent-[#7055ad]">
                                <input type="radio" name="gender" value="male" className="form-radio text-primary mr-2" onChange={handleGenderChange} />
                                Male
                            </label>
                            <label className="flex items-center bg-input border border-border text-foreground rounded-md p-2 w-1/3 cursor-pointer accent-[#7055ad]">
                                <input type="radio" name="gender" value="custom" className="form-radio text-primary mr-2" onChange={handleGenderChange} />
                                Custom
                            </label>
                        </div>
                    </div>
                    {errorMessage && (
              <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
            )}
                    <div className="w-full flex justify-center mt-4">
                        <button onClick={handleSubmit} className="py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80">Sign up</button>
                    </div>
                </div>
                <div className="text-center text-xs text-muted-foreground mt-4">
                    By signing up, you are creating a Unindulgent account and agree to Unindulgent's{' '}
                    <Link to="#" className="underline">Terms</Link> and{' '}
                    <Link to="#" className="underline">Privacy Policy</Link>.
                </div>
            </div>
        </div>
    );
};

export default ProfileComplete;
