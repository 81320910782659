import React, { useContext } from "react";
import { FaPlay } from "react-icons/fa"; // Importing icons from react-icons
import { AuthContext } from "../context/AuthContext";

const VideoPost = (props) => {
  const { authState } = useContext(AuthContext);

  function formatDate(dateString) {
    const date = new Date(dateString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${hours}:${formattedMinutes} ${ampm} · ${month} ${day}, ${year}`;
  }

  return (
    <div className="bg-[#1b1c20] rounded-xl mx-auto p-4 w-full max-w-[510px] md:max-w-[475px] lg:max-w-[510px] text-white">
      <div className="flex items-center mb-2">
        <img
          className="w-10 h-10 rounded-full object-cover"
          src={props.profilePicture}
          alt=""
        />
        <div className="ml-3">
          <span className="font-bold mr-4">{props.contentCreator}</span>
        </div>
      </div>
      <p className="text-lg mb-2">{props.caption}</p>
      
      {/* Video container with responsive classes */}
      <div className="border border-gray-500 rounded-lg overflow-hidden mb-2 w-full h-[60vh] md:h-[80vh] lg:h-[855px] relative">
        <div
          className="relative w-full h-full"
          onClick={() => props.setCurrentFilteredReelIndex(props.index)}
        >
          {authState?.accessToken && (
            <img
              src={props.thumbnailSrc}
              className="absolute inset-0 w-full h-full object-cover"
              alt="Video Thumbnail"
            />
          )}
          <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
            <FaPlay className="w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16 text-white opacity-90" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mb-2">
        {props.tags.map((tag) => (
          <span
            key={tag}
            className="inline-block bg-[#493676] text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
          >
            {tag}
          </span>
        ))}
      </div>
      <span className="text-sm text-gray-400">{formatDate(props.date)}</span>
    </div>
  );
};

export default VideoPost;
