import React from "react";
import { useNavigate } from "react-router-dom";

const FilterCard = ({
  reel,
  index,
  currentFilteredReelIndex,
  setCurrentFilteredReelIndex,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="font-dm-sans relative w-[250px] h-[440px] sm:w-[300px] sm:h-[480px] lg:w-[280px] lg:h-[460px] bg-gradient-to-b from-gray-900 via-gray-800 to-black rounded-2xl overflow-hidden shadow-lg p-3 cursor-pointer transition-transform duration-300 hover:scale-105"
      onClick={() => {setCurrentFilteredReelIndex(index);
      console.log(reel.thumbnail)}}
    >
      {/* Video Thumbnail */}
      <div className="absolute top-0 left-0 w-full h-full">
        <img
          src={reel.thumbnail}
          alt="Reel Thumbnail"
          className="w-full h-full object-cover"
        />
      </div>

      {/* User Info and Subscribe Button */}
      <div className="absolute bottom-4 left-2 right-0 text-white">
        <div className="flex flex-col items-start mb-2 pr-2">
          {/* User Profile Image */}
          <img
            src={reel.ContentCreator.profilePicture}
            alt="User Profile"
            className="w-8 h-8 sm:w-10 sm:h-10 rounded-full object-cover border border-white cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              navigate('/creator/' + reel.ContentCreator.id);
            }}
          />
          <div>
            <p
              className="font-semibold cursor-pointer text-sm sm:text-base"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/creator/' + reel.ContentCreator.id);
              }}
            >
              {reel.ContentCreator.name}
            </p>
            <div className="flex items-end">
              <p className="text-xs sm:text-sm mr-4 line-clamp-2 w-32 sm:w-40">
                {reel.title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterCard;
