import React from 'react';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const UnderReview = () => {
 
return (
    <div className="min-h-screen font-roboto bg-black text-white flex items-center justify-center p-4">
        <div className="text-center">
        <Link to="/">
            <div className="text-center">
            <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
            </div>
        </Link>
        <h1 className="text-3xl font-bold mb-4">
            Your Application is Still Under Review
        </h1>
        <p className="text-xl">
            Thank you for your patience. Your application is currently under review, and we will notify you once a decision has been made.
        </p>
        </div>
    </div>
);
}

export default UnderReview;