import React, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import SubFooter from "../components/SubFooter";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";
import ReplaceModal from "../components/ReplaceModal";

const sharedClasses = {
  primaryBtn: "rounded-lg bg-[#493676] p-1 mt-2",
  secondaryBtn: "px-4 py-2 rounded bg-white text-black",
  card: "bg-card",
  cardText: "text-card-foreground",
  mutedText: "text-muted-foreground",
  destructiveBtn:
    "bg-destructive text-destructive-foreground px-4 py-2 rounded",
};

function ProfileSection({
  profilePicture,
  name,
  category,
  isSubscribed,
  onSubscribe,
  onUnsubscribe,
  loading,
}) {
  const sharedClasses = {
    mutedText: "text-gray-500",
    button: "py-1 px-3 rounded-full text-base mt-1 flex items-center",
    subscribedButton: "bg-green-500 text-white hover:bg-green-600",
    subscribeButton: "bg-[#493676] text-white hover:bg-[#3e2a5a]",
    icon: "h-6 w-5 flex-none text-white mr-2",
  };

  return (
    <section className="flex flex-col md:flex-row space-x-0 md:space-x-8 mb-8">
      <img
        src={profilePicture}
        alt="content-creator-profile-picture"
        className="rounded-full h-20 w-20 md:h-24 md:w-24 object-cover mb-4 md:mb-0"
      />
      <div className="flex-1">
        <h1 className="text-xl md:text-2xl font-bold">{name}</h1>
        <p className={sharedClasses.mutedText}>{category}</p>
        <div className="flex mb-4">
          {isSubscribed ? (
            <button
              className={`${sharedClasses.button} ${sharedClasses.subscribedButton}`}
              onClick={onUnsubscribe}
              disabled={loading} // Add loading state if needed
            >
              <CheckIcon aria-hidden="true" className={sharedClasses.icon} />
              Subscribed
            </button>
          ) : (
            <button
              className={`${sharedClasses.button} ${sharedClasses.subscribeButton}`}
              onClick={onSubscribe}
              disabled={loading} // Add loading state if needed
            >
              Subscribe
            </button>
          )}
        </div>
      </div>
    </section>
  );
}


function AboutSection({ about, content, reviews }) {
  const sharedClasses = {
    // mutedText: "text-gray-500", // Add this class to maintain consistent styling
    navDiv: "text-lg cursor-pointer px-4 py-2",
    activeTab: "border-b-2 border-white-700",
  };

  return (
    <>
      <h1 className="text-3xl font-bold mb-4">About</h1>
      <section className="mb-8">
        <p className={`${sharedClasses.mutedText} text-base md:text-lg lg:text-xl`}>
          {about}
        </p>
      </section>
    </>
  );
}


const SuggestedCard = ({
  id,
  name,
  profilePicture,
  bio,
  isSubscribed,
  userSubscriptions,
  onSubscribe,
  onUnsubscribe,
  setUserSubscriptions,
}) => {
  const navigate = useNavigate();

  const sharedStyles = {
    card: "bg-card p-4 rounded-lg shadow-lg hover:bg-zinc-800 duration-300 cursor-pointer w-full md:w-[20rem]", // Use full width on small screens
    button: "py-1 px-3 rounded-full text-base mt-1 mr-2 flex items-center",
    subscribedButton: "bg-green-500 text-white hover:bg-green-600",
    subscribeButton: "bg-[#493676] text-white hover:bg-[#3e2a5a]",
    icon: "h-6 w-5 flex-none text-white mr-2",
  };

  const handleNavigation = () => {
    navigate(`/creator/${id}`);
  };

  const handleButtonClick = (e, action) => {
    e.stopPropagation();
    action();
  };

  const handleSubscribe = () => {
    if (userSubscriptions.length < 3) {
      onSubscribe();
      setUserSubscriptions((prev) => [...prev, id]);
    } else {
      onSubscribe();
    }
  };

  const handleUnsubscribe = () => {
    onUnsubscribe();
    setUserSubscriptions((prev) =>
      prev.filter((subscription) => subscription !== id)
    );
  };

  return (
    <div className={sharedStyles.card} onClick={handleNavigation}>
      <img src={profilePicture} alt={name} className="rounded-lg mb-2 h-36 md:h-48 object-cover" />
      <h2 className="text-lg font-bold">{name}</h2>
      <p className="mb-1 line-clamp-2">{bio}</p>
      <div className="flex justify-end mb-4">
        {isSubscribed ? (
          <button
            className={`${sharedStyles.button} ${sharedStyles.subscribedButton}`}
            onClick={(e) => handleButtonClick(e, handleUnsubscribe)}
          >
            <CheckIcon aria-hidden="true" className={sharedStyles.icon} />
            Subscribed
          </button>
        ) : (
          <button
            className={`${sharedStyles.button} ${sharedStyles.subscribeButton}`}
            onClick={(e) => handleButtonClick(e, handleSubscribe)}
          >
            Subscribe
          </button>
        )}
      </div>
    </div>
  );
};

const SuggestedCreatorsCarousel = ({
  userSubscriptions,
  handleSubscription,
  currentCreatorId,
  setUserSubscriptions,
}) => {
  const [contentCreators, setContentCreators] = useState([]);

  useEffect(() => {
    async function fetchContentCreators() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/suggested`
        );
        if (!response.ok) throw new Error("Failed to fetch content creators");

        const data = await response.json();
        setContentCreators(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchContentCreators();
  }, []);

  const filteredCreators = contentCreators.filter(
    (creator) => creator.id !== currentCreatorId
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet screens
        settings: {
          slidesToShow: 2, // Show 2 slides on tablets
        },
      },
      {
        breakpoint: 600, // Mobile screens
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '40px',
        },
      },
    ],
  };

  return (
    <div className="px-4 md:px-0 carousel-container">
      <Slider {...settings}>
        {filteredCreators.map((creator) => (
          <div className="suggested-card" key={creator.id}>
            <SuggestedCard
              id={creator.id}
              setUserSubscriptions={setUserSubscriptions}
              userSubscriptions={userSubscriptions}
              name={creator.name}
              profilePicture={creator.profilePicture}
              bio={creator.bio}
              isSubscribed={userSubscriptions.includes(creator.id)}
              onSubscribe={() => handleSubscription("subscribe", creator.id)}
              onUnsubscribe={() => handleSubscription("unsubscribe", creator.id)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

function CreatorInfo() {
  const { id } = useParams(); // Get the ID from the URL
  const { authState } = useContext(AuthContext);
  const [contentCreator, setContentCreator] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isPaying, setIsPaying] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [newCreatorId, setNewCreatorId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userSubData, setUserSubData] = useState([]);
  const [introductionVideo, setIntroductionVideo] = useState(null);
  const [introVid, setIntroVid] = useState(null);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  async function fetchContentCreator(id) {
    try {
      if (!id) throw new Error("Invalid ID");
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/${id}`;
      const response = await fetch(url);

      if (!response.ok) throw new Error("Failed to fetch content creator");

      const data = await response.json();
      setContentCreator(data);
      if (data.introductionVideo) {
        setIntroVid(true)
        const getVideoUrl = `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/introductory-video/${id}`;
        const videoResponse = await fetch(getVideoUrl);
        console.log(videoResponse);
        if (!videoResponse.ok) throw new Error("Failed to fetch video");

        const videoBlob = await videoResponse.blob();
        console.log(videoBlob);
        const videoUrl = URL.createObjectURL(videoBlob);
        setIntroductionVideo(videoUrl);
      }
      else {
        setIntroVid(false);
        setIntroductionVideo(null);
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function checkSubscriptionStatus(userId, contentCreatorId) {
    try {
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/check-subscription/${userId}/${contentCreatorId}`;
      const response = await fetch(url);

      if (!response.ok) throw new Error("Failed to check subscription status");

      const data = await response.json();
      setIsSubscribed(data.status === "active");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (authState.user) {
      setUserId(authState.user.user_id);
      checkSubscriptionStatus(authState.user.user_id, id);
    }
  }, [authState.user, contentCreator]);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (authState.user) {
        console.log("Checking subscription status...");
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_ADDRESS}/api/subscription/check`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authState.accessToken}`,
              },
            }
          );

          if (response.ok) {
            const subscription = await response.json();
            if (subscription.status === "active") {
              setIsPaying(true);
            } else {
              setIsPaying(false); // Ensure that subscription status is false if not active
            }
          } else {
            console.error("Failed to fetch subscription status.");
            setIsPaying(false); // Set to false if response is not OK
          }
        } catch (error) {
          console.error("Error checking subscription status:", error);
          setIsPaying(false); // Handle errors by setting subscription status to false
        }
      }
    };

    checkSubscriptionStatus();
  }, [authState.user, authState.accessToken]);

  const handleReplace = async (oldCreatorId, newCreatorId) => {
    try {
      const urlUnsubscribe = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/unsubscribe`;
      const urlSubscribe = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/subscribe`;
      console.log(oldCreatorId, newCreatorId);

      // Unsubscribe from the old content creator
      let response = await fetch(urlUnsubscribe, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, contentCreatorId: oldCreatorId }),
      });
      if (!response.ok)
        throw new Error("Failed to unsubscribe content creator");

      if (oldCreatorId === contentCreator?.id) {
        setIsSubscribed(false);
      }

      // Subscribe to the new content creator
      response = await fetch(urlSubscribe, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, contentCreatorId: newCreatorId }),
      });
      if (!response.ok) throw new Error("Failed to subscribe content creator");

      if (newCreatorId === contentCreator?.id) {
        setIsSubscribed(true);
      }

      // Update user subscriptions state
      setUserSubscriptions((prev) =>
        prev.filter((id) => id !== oldCreatorId).concat(newCreatorId)
      );

      // Close modal after replacing
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  };

  async function handleSubscription(action, contentCreatorId) {
    if (!userId) {
      navigate("/login");
      return;
    } else {
      if (action === "subscribe" && userSubscriptions.length >= 3) {
        setNewCreatorId(contentCreatorId);
        handleOpenModal();
        return;
      }
      try {
        console.log(action);
        const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/${action}`;
        const response = await fetch(url, {
          method: action === "subscribe" ? "POST" : "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: authState.user.user_id,
            contentCreatorId: contentCreatorId,
          }),
        });

        if (!response.ok) throw new Error(`Failed to ${action}`);
        if (contentCreatorId === contentCreator?.id) {
          if (action === "subscribe") {
            setUserSubscriptions((prev) => [...prev, contentCreatorId]);
            setIsSubscribed(action === "subscribe");
          } else {
            setUserSubscriptions(
              userSubscriptions.filter((id) => id !== contentCreatorId)
            );
            setIsSubscribed(action === "subscribe");
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  async function fetchUserSubscriptions() {
    if (userId) {
      try {
        const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/subscriptions/${userId}`;
        const response = await fetch(url);

        if (!response.ok) throw new Error("Failed to fetch user subscriptions");

        const data = await response.json();
        setUserSubscriptions(data.map((sub) => sub.contentCreatorId));
        console.log(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const fetchSubscribedCreatorsData = async () => {
      try {
        const promises = userSubscriptions.map((id) =>
          fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/${id}`).then(
            (response) => response.json()
          )
        );
        const results = await Promise.all(promises);
        setUserSubData(results);
      } catch (error) {
        console.error("Error fetching subscribed creators data:", error);
      }
    };

    if (userSubscriptions.length > 0) {
      fetchSubscribedCreatorsData();
    }
  }, [userSubscriptions]);

  useEffect(() => {
    if (authState.user) {
      setUserId(authState.user.user_id);
      checkSubscriptionStatus(authState.user.user_id, id);
    }
  }, [authState.user]);

  useEffect(() => {
    fetchContentCreator(id);
  }, [id]);

  useEffect(() => {
    if (userId) {
      fetchUserSubscriptions();
    }
  }, [userId]);

  return (
    <div className="bg-black text-white font-dm-sans min-h-screen">
      <Navbar />
      <main className="mx-4 md:mx-10 lg:mx-20 mt-10 md:mt-20">
        <ReplaceModal
          showModal={showModal}
          subscribedCreators={userSubData}
          onClose={handleCloseModal}
          onReplace={handleReplace}
          newCreatorId={newCreatorId}
        />
        <div className="grid grid-cols-1 md:grid-cols-[2.2fr_0.8fr] gap-4 md:gap-8 mb-8">
          <div>
            <ProfileSection
              name={contentCreator?.name}
              profilePicture={contentCreator?.profilePicture}
              category={contentCreator?.category || "Motivational Speaker"}
              isSubscribed={isSubscribed}
              onSubscribe={() => handleSubscription("subscribe", contentCreator?.id)}
              onUnsubscribe={() => handleSubscription("unsubscribe", contentCreator?.id)}
              loading={loading}
            />
            <AboutSection about={contentCreator?.bio} />
          </div>
        </div>
  
        {introVid && (
          <div className="flex flex-col mb-8">
            <h1 className="text-2xl md:text-3xl font-bold mb-4">Introductory Video</h1>
            <div className="relative flex justify-center w-full mb-6">
              <div className="relative w-full md:w-[50rem] h-0" style={{ paddingBottom: '25.25%' }}>
                {introductionVideo ? (
                  <video
                    controls
                    className="absolute inset-0 rounded-lg h-full w-full"
                    src={introductionVideo}
                  ></video>
                ) : (
                  <svg
                    aria-hidden="true"
                    className="absolute top-1/2 w-16 h-16 inline text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        )}
  
        <div className="flex flex-col">
          <h1 className="text-2xl md:text-3xl font-bold mb-4">Suggested Content Creators</h1>
          <div className="p-4 md:p-8 rounded-lg">
            <SuggestedCreatorsCarousel
              userSubscriptions={userSubscriptions}
              setUserSubscriptions={setUserSubscriptions}
              handleSubscription={handleSubscription}
              currentCreatorId={contentCreator?.id}
            />
          </div>
        </div>
  
        {authState.paid && <SubFooter />}
        {!authState.paid && userSubscriptions.length >= 3 && (
          <div className="fixed bottom-4 right-4 md:right-8">
            <button
              className="bg-[#493676] text-white py-2 px-4 rounded-lg shadow-md hover:bg-[#493676]/80"
              onClick={() => navigate('/pricing')}
            >
              Continue to Pricing
            </button>
          </div>
        )}
      </main>
    </div>
  );
  
}

export default CreatorInfo;
