import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import Image1 from '../assets/image1.png';
import Image2 from '../assets/image2.png';
import Image3 from '../assets/image3.png';
import Image4 from '../assets/image4.png';
import Image5 from '../assets/image5.png';
import Image6 from '../assets/image6.png';
import Image7 from '../assets/image7.png';
import Image8 from '../assets/image8.png';
import Image9 from '../assets/image9.png';
import Image10 from '../assets/image10.png';
import Image11 from '../assets/image11.png';
import Image12 from '../assets/image12.png';
import Image13 from '../assets/image13.png';
import Image14 from '../assets/image14.png';
import Image15 from '../assets/image15.png';
import Logo from '../assets/logo.png';
import Navbar from '../components/Navbar';
import MainSidebar from '../components/MainSidebar';
import { AuthContext } from '../context/AuthContext'; 
import axios from 'axios';

const images = [
  [
    { src: Image1, alt: 'Image 1' },
    { src: Image2, alt: 'Image 2' },
    { src: Image3, alt: 'Image 3' },
  ],
  [
    { src: Image4, alt: 'Image 4' },
    { src: Image5, alt: 'Image 5' },
  ],
  [
    { src: Image6, alt: 'Image 6' },
    { src: Image7, alt: 'Image 7' },
  ],
  [{ src: Image8, alt: 'Image 8' }],
  [
    { src: Image9, alt: 'Image 9' },
    { src: Image10, alt: 'Image 10' },
  ],
  [
    { src: Image11, alt: 'Image 11' },
    { src: Image12, alt: 'Image 12' },
  ],
  [
    { src: Image13, alt: 'Image 13' },
    { src: Image14, alt: 'Image 14' },
    { src: Image15, alt: 'Image 15' },
  ],
];

const GridGallery = () => {
  const { authState } = useContext(AuthContext); // Use authState from AuthContext
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    // Fetch the Quote of the Day
    const fetchQuoteOfTheDay = async () => {
      try {
        console.log({host:process.env.REACT_APP_SERVER_ADDRESS})
        const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/quote/quote-of-the-day`);
        setQuote(response.data.quoteOfTheDay);  // Adjusted to match the data structure
      } catch (error) {
        console.error('Error fetching Quote of the Day:', error);
      }
    };

    fetchQuoteOfTheDay();
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isCreator = authState?.user?.user_type === 'creator';


  return (
    <>
      {/* <Navbar /> */}
      {isCreator ? <MainSidebar /> : <Navbar />}  {/* Conditionally render Sidebar for creators or Navbar */}
      {isMobile ? (
        <div className="flex flex-col items-center min-h-screen bg-black text-white">
          <div className="text-center mt-20">
            <h1 className="text-4xl font-bold font-dm-sans">Unindulgent</h1>
            {quote ? (
              <motion.p className="mt-4 italic">"{quote.text}" <br /> - {quote.author}</motion.p>
            ) : (
              <p className="mt-4 italic">...</p>
            )}
          </div>
          <div className="flex flex-wrap justify-center items-center mt-8">
            {images.flat().map((image, index) => (
              <motion.img
                key={index}
                src={image.src}
                alt={image.alt}
                className="rounded-3xl my-2 mx-2 w-24 h-24 object-cover"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-black text-white flex flex-col items-center overflow-hidden">
          <div className="text-center mt-10">
            <div className="flex justify-center items-end">
              <img src={Logo} alt="Unindulgent Logo" className="h-16 mb-4" />
            </div>
            <h1 className="text-4xl font-bold font-dm-sans">Unindulgent</h1>
            {quote ? (
              <motion.p className="mt-4 italic">"{quote.text}" <br /> - {quote.author}</motion.p>
            ) : (
              <p className="mt-4 italic">...</p>
            )}
          </div>
          <div className="grid grid-cols-7 gap-4 px-4">
            {images.map((column, colIndex) => (
              <div
                key={colIndex}
                className={`flex flex-col items-center space-y-4 ${
                  colIndex === 1 ? 'mt-16' : ''
                } ${colIndex === 2 ? 'mt-32' : ''} ${colIndex === 3 ? 'mt-48' : ''} ${
                  colIndex === 4 ? 'mt-32' : ''
                } ${colIndex === 5 ? 'mt-16' : ''}`}
              >
                {column.map((image, index) => (
                  <motion.img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className="rounded-3xl my-4 mx-4 w-40 h-44 object-cover"
                    initial={{ opacity: 0, scale: 0.5, y: 20 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default GridGallery;
