import React, { useState, useEffect, useContext } from 'react';
import { FaHeart, FaShare } from 'react-icons/fa'; // Importing icons from react-icons
import axios from 'axios'; // For making API calls
import { AuthContext } from '../context/AuthContext';
import { TbPinnedFilled } from "react-icons/tb";

const TextPost = (props) => {
    const [liked, setLiked] = useState(false); // State to manage like status
    const [likesCount, setLikesCount] = useState(0); // State to store total likes
    const { authState } = useContext(AuthContext);

    const postId = props.postId; // Assuming postId is passed as a prop
    const videoId = props.videoId; // Assuming videoId is passed as a prop (if applicable)

    // Function to format date
    function formatDate(dateString) {
        const date = new Date(dateString);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        return `${hours}:${formattedMinutes} ${ampm} · ${month} ${day}, ${year}`;
    }

    // Check if the user has liked the post or video when the component mounts
    useEffect(() => {
        // Ensure authState.accessToken is available
        if (authState.accessToken) {
            const fetchLikesData = async () => {
                try {
                    const likesResponse = await axios.get(
                        `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${props.postId}/likes`, 
                        {
                            headers: {
                                Authorization: `Bearer ${authState.accessToken}`
                            }
                        }
                    );
                    setLikesCount(likesResponse.data.totalLikes);

                    const hasLikedResponse = await axios.get(
                        `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${props.postId}/hasLiked`,
                        {
                            headers: {
                                Authorization: `Bearer ${authState.accessToken}`
                            }
                        }
                    );
                    setLiked(hasLikedResponse.data.hasLiked);
                } catch (error) {
                    console.error("Failed to fetch like data", error);
                }
            };

            fetchLikesData();
        }
    }, [authState.accessToken, props.postId]);

    // Handle like/unlike actions
    const handleLikeClick = async () => {
        try {
            if (liked) {
                await axios.delete(
                    `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${props.postId}/unlike`, 
                    {
                        headers: {
                            Authorization: `Bearer ${authState.accessToken}`
                        }
                    }
                );
                setLiked(false);
                setLikesCount((prevCount) => Math.max(0, prevCount - 1));
            } else {
                await axios.post(
                    `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${props.postId}/like`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${authState.accessToken}`
                        }
                    }
                );
                setLiked(true);
                setLikesCount((prevCount) => prevCount + 1);
            }
        } catch (error) {
            console.error("Failed to update like status", error);
        }
    };

    return (
        <div className="bg-[#1b1c20] rounded-xl mx-auto p-4 w-full max-w-[500px] text-white md:p-6">
            <div className="flex items-center mb-2">
                <img
                    className="w-10 h-10 rounded-full object-cover md:w-12 md:h-12"
                    src={props.profilePicture}
                    alt=""
                />
                <div className="ml-3 w-full flex justify-between items-center">
                    <span className="font-bold text-sm md:text-base mr-4">{props.contentCreator}</span>
                    {props.pinned && (
                        <div className="flex">
                            <TbPinnedFilled className="text-white ml-2" size={20} />
                        </div>
                    )}
                </div>
            </div>

            <p className="text-sm md:text-lg mb-2">{props.content}</p>
            <div className="flex flex-wrap mb-2">
                {props.tags.map((tag) => (
                    <span
                        key={tag}
                        className="inline-block bg-[#493676] text-white text-xs md:text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                        {tag}
                    </span>
                ))}
            </div>

            <span className="text-xs md:text-sm text-gray-400">{formatDate(props.date)}</span>

            <div className="flex items-center mt-3 space-x-4">
                <button
                    onClick={handleLikeClick}
                    className={`flex items-center duration-200 ${
                        liked ? "text-purple-500" : "text-gray-400 hover:text-white"
                    }`}
                >
                    <FaHeart className="w-5 h-5 mr-1 md:w-6 md:h-6" />
                    <span className="text-xs md:text-sm">{liked ? "Liked" : "Like"}</span>
                </button>
            </div>
        </div>
    );
};

export default TextPost;
