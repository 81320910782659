import React, { useState, useEffect, useContext } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const includedFeatures = [
  'Access to 3 content creators of your choice',
  'Exclusive content updates',
  'Member-only community access',
  "One to One messaging with creators",
];

export default function Example() {

  const { authState,setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  


  const checkSubscriptionStatus = async () => {
    if (authState.user) {
      console.log('Checking subscription status...');
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/subscription/check`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });

        if (response.ok) {
          const subscription = await response.json();
          if (subscription.status === 'active') {
            setIsSubscribed(true);
            setAuthState({
              ...authState,
              paid: true,
            });
          } else {
            setIsSubscribed(false); // Ensure that subscription status is false if not active
            setAuthState({
              ...authState,
              paid: false,
            });

          }
        } else {
          console.error('Failed to fetch subscription status.');
          setIsSubscribed(false); // Set to false if response is not OK
          setAuthState({
            ...authState,
            paid: false,
          });

        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
        setIsSubscribed(false); // Handle errors by setting subscription status to false
      }
    }
  };


  useEffect(() => {
    checkSubscriptionStatus();
  }, [authState.user, authState.accessToken]); 


  async function fetchUserSubscriptions() {
    if (userId) {
    try {
      
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/subscriptions/${userId}`;
      const response = await fetch(url);

      if (!response.ok) throw new Error("Failed to fetch user subscriptions");

      const data = await response.json();
      setUserSubscriptions(data.map(sub => sub.contentCreatorId));
    } catch (error) {
      console.error(error);
    }
  }
  }


  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      // setIsSubscribed(true);
      checkSubscriptionStatus();
    }

    if (query.get('canceled')) {
      setIsSubscribed(false);
    }
  }, [sessionId]);

  useEffect(() => {
    if (authState.user) {
      setUserId(authState.user.user_id);
      
    }
  }, [authState.user]);

  useEffect(() => {
    if (userId) {
      fetchUserSubscriptions();
    }
  }, [userId]);


  const handleCheckout = async () => {

    //Check that length of userSubscriptions is less than 3
    if (userSubscriptions.length < 3) {
      //Redirect to search subscriptions page with a toast message
      toast.error("You must subscribe to 3 content creators", {
        position: "bottom-right",
      });
      navigate('/search-subscriptions');
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/stripe/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authState.accessToken}`,
      },
      body: JSON.stringify({
        lookup_key: 'unindulgent', // Replace with your actual price lookup key
      }),
    });

    const session = await response.json();

    if (session.url) {
      window.location.href = session.url;
    } else {
      setMessage('Failed to create checkout session');
    }
  };

    return (
      <div className="py-24 sm:py-32 min-h-screen font-dm-sans" style={{ background: 'radial-gradient(circle, rgb(84, 44, 106) 10%, rgb(17, 24, 39)50%)' }}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Simple no-tricks pricing</h2>
            <p className="mt-6 text-lg leading-8 text-white">
              Subscribe to 3 content creators of your choice for just $12 per month.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none bg-white">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Monthly Subscription</h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
              Subscribe to your favorite content creators and gain access to exclusive content. Enjoy direct interaction, behind-the-scenes insights, and more, all for just $12 per month.

              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">Subscribe now</p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">$12</span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD / month</span>
                  </p>
                  <button
                    onClick={() => {
                      if (authState.user) {
                        handleCheckout();
                      } else {
                        navigate('/login');
                      }
                    }}
                    className={`mt-10 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                      isSubscribed ? 'bg-green-500 cursor-not-allowed' : 'bg-[#493676] hover:bg-[#493676]/80 focus-visible:outline-indigo-600'
                    }`}
                    disabled={isSubscribed}
                  >
                    {isSubscribed ? (
                      <span className="flex items-center justify-center gap-x-2">
                        <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                        Subscribed
                      </span>
                    ) : (
                      'Subscribe'
                    )}
                  </button>
                  {isSubscribed && (
                    <form action={`${process.env.REACT_APP_SERVER_ADDRESS}/api/stripe/create-portal-session`} method="POST">
                    <input
                      type="hidden"
                      id="user-id"
                      name="user_id"
                      value={authState.user.user_id}
                    />
                  <button
                  id="checkout-and-portal-button" type="submit"
                    className="mt-6 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold text-white bg-[#493676] hover:bg-[#493676]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Manage Billing
                  </button>
                  </form>
                )}


                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Invoices and receipts available for easy tracking
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
