import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Play from "../assets/play.png";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import MainSidebar from "../components/MainSidebar";

function PostVideo() {
  const { authState } = useContext(AuthContext);
  const [video, setVideo] = useState(null);
  const [user, setUser] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [caption, setCaption] = useState("");
  const [videoType, setVideoType] = useState("public");
  const [selectedTags, setSelectedTags] = useState([]);
  const [videoPreview, setVideoPreview] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  // Reference to the video element
  const videoRef = useRef(null);

  const handleVideoTypeChange = (event) => {
    setVideoType(event.target.value); // Update state when radio option is selected
  };

  const handleVideoChange = (event) => {
    const selectedVideo = event.target.files[0];
    setVideo(selectedVideo);
    // Generate preview URL
    const previewUrl = URL.createObjectURL(selectedVideo);
    setVideoPreview(previewUrl);
  };

  const handleThumbnailChange = (event) => {
    const selectedThumbnail = event.target.files[0];
    setThumbnail(selectedThumbnail);
  };
  const availableTags = [
    "Fitness",
    "Meditation",
    "Lifestyle",
    "Coaching",
    "Mental Wellness",
    "Technology",
    "Health",
    "Education",
    "Business",
    "Music",
  ];

  useEffect(() => {
    if (authState.user) {
      setUser(authState.user);
    }
  }, [authState.user]);

  useEffect(() => {
    if (user) {
      if (user.user_type !== "creator") {
        navigate("/");
      }
    }
  }, [user]);

  const handleTagClick = (tag) => {
    // Check if the tag is already selected
    if (selectedTags.includes(tag)) {
      // Remove the tag if already selected
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else if (selectedTags.length < 3) {
      // Add the tag if less than 3 tags are selected
      setSelectedTags([...selectedTags, tag]);
    }
  };
  useEffect(() => {
    if (authState.user) {
      if (authState.user.user_type === "creator") {
        setUser(authState.user);
      } else {
        navigate("/"); // Redirect to home if user is not a creator
      }
    } else {
      navigate("/"); // Redirect to home if user is not a creator
    }
  }, [authState.user, navigate]);

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const allFieldsFilled = video && caption && videoType;

  // Function to toggle play/pause
  const playOrPause = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      if (video.paused || video.ended) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  const handleRemoveVideo = () => {
    setVideo(null);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
    }
  };

  const handleUpload = () => {
    // if (!allFieldsFilled || !user) {
    //     return; // Ensure all fields are filled and user data is available
    // }

    setIsLoading(true); // Set loading to true when upload starts
    const formData = new FormData();
    formData.append("title", caption); // Adding 'caption' as title
    formData.append("description", "lorem ipsum"); // Adding 'lorem ipsum' as description
    formData.append("tags", selectedTags.join(",")); // Adding selected tags
    formData.append("ContentCreatorID", user.user_id); // Adding user ID as ContentCreatorID
    if (video) {
      formData.append("videoFile", video); // Adding the video file
      formData.append("type", videoType); // Adding the video type
    }
    if (thumbnail) {
      formData.append("thumbnail", thumbnail); // Adding the thumbnail file
    }

    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          // Handle successful upload
          toast.success("Video uploaded successfully", {
            position: "bottom-right",
          });
          // Clear all fields
          setVideo(null);
          setThumbnail(null);
          setCaption("");
          setSelectedTags([]);
          setVideoType("public");
          handleRemoveVideo();
          setVideoPreview(null);
          setIsPlaying(false);
        } else {
          if (response.status === 400) {
            toast.error("Video should be no longer than 3 minutes.", {
              position: "bottom-right",
            });
          } else if (response.status === 401) {
            toast.error("You added a video less than a day ago", {
              position: "bottom-right",
            });
          }

          // Handle server errors
          // alert("Failed to upload video. Please try again.");
        }
      })
      .catch((error) => {
        // Handle network errors
        toast.error(
          "An error occurred during upload. Please check your connection and try again.",
          { position: "bottom-right" },
        );
        console.error("Upload error:", error);
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after upload
      });
  };

  return (
    <div className="min-h-screen pb-8 bg-black h-full text-white font-dm-sans relative">
      {/* MainSidebar Component */}
      <MainSidebar />
  
      {/* Main content */}
      <main className="max-w-7xl mx-auto pt-0">
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-4 pt-24">
          Create a Public Video Post
        </h1>
        <div className="flex flex-col md:flex-row w-full mt-12">
          <div className="w-full md:w-1/2">
            <h1 className="text-2xl mb-4 ml-8 md:ml-32">Upload Video</h1>
            <label
              htmlFor="uploadFile1"
              className="text-gray-500 font-semibold text-base rounded-xl w-[350px] h-[625px] flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed mx-auto font-[sans-serif]"
            >
              {video ? (
                <div className="relative w-full h-full flex justify-center items-center">
                  <div
                    className="relative w-full h-full flex justify-center items-center"
                    onClick={playOrPause} // Play/pause functionality
                  >
                    <video
                      className="w-full h-auto"
                      controls={false} // Disable default controls for custom play/pause
                      ref={videoRef}
                      src={videoPreview}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                    ></video>
                    {!isPlaying && (
                      <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-28 h-28 fill-white opacity-90"
                          viewBox="0 0 32 32"
                        >
                          <path d="M12 10v12l10-6z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="absolute bottom-0 right-0 m-4">
                    <button
                      className="border border-[#493676] text-white text-sm px-2 py-1 rounded-full"
                      onClick={handleRemoveVideo}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-14 mb-4 fill-[#493676]"
                    viewBox="0 0 32 32"
                  >
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                  </svg>
                  <span className="text-sm text-[#493676]">Upload Video</span>
                  <p className="text-xs font-medium text-gray-400 mt-2">
                    MP4, WEBM allowed
                  </p>
                </>
              )}
              <input
                type="file"
                id="uploadFile1"
                className="hidden"
                accept="video/mp4,video/webm"
                onChange={handleVideoChange}
                disabled={video}
              />
            </label>
          </div>
          <div className="w-[87%] ml-8 md:w-1/2 md:ml-24">
            <h1 className="text-2xl mb-4 mt-4 md:mt-0">Upload Thumbnail</h1>
            <label
              htmlFor="uploadThumbnail"
              className="text-[#493676] font-semibold text-base rounded max-w-md h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed font-[sans-serif]"
            >
              {thumbnail ? (
                <span className="text-sm text-[#493676]">{thumbnail.name}</span>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-11 mb-2 fill-[#493676]"
                    viewBox="0 0 32 32"
                  >
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                  </svg>
                  Upload Thumbnail
                  <p className="text-xs font-medium text-gray-400 mt-2">PNG, JPG, JPEG allowed.</p>
                </>
              )}
              <input
                type="file"
                id="uploadThumbnail"
                className="hidden"
                accept="image/png, image/jpeg"
                onChange={handleThumbnailChange}
              />
            </label>
            <h1 className="text-xl my-4">Video Caption</h1>
            <textarea
              className="w-full h-20 text-black p-4 border border-[#493676] rounded-lg resize-none"
              placeholder="Write a caption for your video..."
              value={caption}
              onChange={handleCaptionChange}
            ></textarea>
            <div>
              <h1 className="text-xl my-4">Select Tags</h1>
              <div className="flex flex-wrap gap-2">
                {availableTags.map((tag) => (
                  <button
                    key={tag}
                    className={`${
                      selectedTags.includes(tag)
                        ? "bg-[#493676] text-white"
                        : "bg-gray-200 text-gray-800"
                    } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
                    onClick={() => handleTagClick(tag)}
                    disabled={
                      selectedTags.length >= 3 && !selectedTags.includes(tag)
                    }
                  >
                    {tag}
                  </button>
                ))}
              </div>
              {selectedTags.length === 3 && (
                <p className="text-red-500 text-sm mt-2">
                  You can select up to 3 tags only.
                </p>
              )}
            </div>
          </div>
        </div>
        {allFieldsFilled && (
          <div className="fixed bottom-8 right-8">
            <button
              className="bg-[#493676] text-white px-6 py-3 rounded-lg shadow-lg text-lg"
              onClick={handleUpload}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 animate-spin mx-auto fill-white"
                  viewBox="0 0 32 32"
                >
                  <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                </svg>
              ) : (
                "Publish"
              )}
            </button>
          </div>
        )}
      </main>
    </div>
  );
  
}

export default PostVideo;
