import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import Default from "../assets/default.jpg";
import {
  addDoc,
  collection,
  serverTimestamp,
  where,
  query,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
// import Skeleton from 'react-loading-skeleton';

const Sidebar = ({ setSelectedChat }) => {
  const { authState } = useContext(AuthContext);
  const [followedCreators, setFollowedCreators] = useState([]);
  const [chatIds, setChatIds] = useState([]);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = authState?.user;

  async function fetchChatIds(userID) {
    try {
      const requestOptions = {
        method: "GET",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/chat-ids/` + userID,
          requestOptions,
        );
        const result = await response.json();
        console.log({ result });
        setChatIds(result);
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchChats(ids) {
    try {
      setLoading(true); // Set loading to true before fetching data
      let querySnapshot;
      console.log(ids);
      const chatCollection = collection(db, "chats");

      if (Array.isArray(ids)) {
        // For multiple IDs
        console.log("test2");
        const q = query(chatCollection, where("__name__", "in", ids));
        querySnapshot = await getDocs(q);
      } else if (ids && ids.chatId) {
        // For a single ID
        const docRef = doc(chatCollection, ids.chatId);
        querySnapshot = await getDoc(docRef);
        // Wrapping single document in an array for consistency
        querySnapshot = { docs: [querySnapshot] };
      } else {
        // Handle cases where ids is not valid
        throw new Error("Invalid IDs provided");
      }

      const chatData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log({ chatData });
      setChats(chatData);
    } catch (error) {
      console.error("Error fetching chat details:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  }

  async function getCreatorChatId(creatorId) {
    try {
      const requestOptions = {
        method: "GET",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/chat-id/` + creatorId,
          requestOptions,
        );
        const result = await response.json();
        console.log({ result });
        if (result) {
          setChatIds(result);
        }
      } catch (error) {
        console.error(error);
        // return "";
      }
    } catch (error) {
      console.error(error);
      // return "";
    }
  }

  useEffect(() => {
    if (user) {
      console.log({ user });
      if (user.user_type === "user") {
        fetchChatIds(user.user_id);
        // if (chatIds.length > 0){
        // console.log("test");
        // fetchChats(chatIds);
        // }
      } else {
        getCreatorChatId(user.user_id);

        if (chatIds?.chatId) {
          const chatIdsArray = [chatIds.chatId];

          if (chatIdsArray.length > 0) {
            console.log({ chatIdsArray });
            fetchChats(chatIdsArray);
          }
        }
      }
    }
  }, [user]);

  useEffect(() => {
    // if (chatIds.length > 0) {
    console.log({ chatIds });
    // fetchChats(chatIds);
    if (!Array.isArray(chatIds)) {
      if (chatIds?.chatId) {
        const newArray = [chatIds.chatId];
        if (newArray.length > 0) {
          fetchChats(chatIds);
        }
      }
    } else {
      // for users
      console.log("User side");
      fetchChats(chatIds);
    }
    // }
  }, [chatIds]);

  const isCreator = user?.user_type === 'creator';

  return (
<div className="w-full lg:w-80 bg-black text-white h-full p-4 border-r border-slate-400">
  <h2 className={`text-lg font-semibold mb-4 ${isCreator ? 'mt-20' : 'mt-6'}`}>Messages</h2>
  <ul>
    {loading ? (
      // Skeleton structure when loading
      <div className="flex flex-col space-y-4 items-center justify-center">
        <p className="text-lg font-semibold mt-4">Loading chats</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 animate-spin fill-white mt-2"
          viewBox="0 0 32 32"
        >
          <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
        </svg>
      </div>
    ) : (
      // Actual chat list when data is loaded
      chats.map((chat) => (
        <li
          key={chat.id}
          onClick={() => setSelectedChat(chat)}
          className="flex items-center p-4 mb-4 cursor-pointer hover:bg-gray-700 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          {/* Avatar */}
          <img
            src={chat.picture || Default}
            alt={chat.chatName}
            className="w-12 h-12 rounded-full mr-4 object-cover shadow-md"
          />
          {/* Chat Info */}
          <div className="flex-grow">
            <span className="font-semibold block text-white">{chat.chatName}</span>
          </div>
        </li>
      ))
    )}
  </ul>
</div>

  );
};

export default Sidebar;
