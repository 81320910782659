import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditImage() {
    const { id } = useParams();
    const navigate = useNavigate();
    
    // State for image, preview, caption, and tags
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [caption, setCaption] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    
    // Loading state
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    // Available tags
    const availableTags = [
        "Fitness",
        "Meditation",
        "Lifestyle",
        "Coaching",
        "Mental Wellness",
        "Technology",
        "Health",
        "Education",
        "Business",
        "Music"
    ];

    const imageRef = useRef(null); // Ref for file input

    // Fetch post data from the backend when component mounts
    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/${id}`);
                const postData = response.data.post;
                console.log('Data fetched on edit image: ', postData);

                // Set the image preview, caption, and tags
                // setImage(post.image)
                setImagePreview(postData.image); // Assuming the image URL is stored as imageUrl
                setCaption(postData.text); // Assuming caption is stored in the post
                setSelectedTags(postData.tags); // Assuming the tags are an array
            } catch (error) {
                console.error("Error fetching post data:", error);
            } finally {
                setIsFetching(false);
            }
        };

        fetchPostData();
    }, [id]);

    // Handle image change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(file);
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle caption change
    const handleCaptionChange = (e) => {
        setCaption(e.target.value);
    };

    // Handle tag selection
    const handleTagClick = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((t) => t !== tag)); // Deselect tag if already selected
        } else if (selectedTags.length < 3) {
            setSelectedTags([...selectedTags, tag]); // Select tag if less than 3 tags are selected
        }
    };

    const triggerFileSelect = () => {
        if (imageRef.current) {
            imageRef.current.click();
        }
    };


    // Handle Edit button click
    const handleEdit = async () => {
        setIsLoading(true); // Set loading state
        const formData = new FormData();
        formData.append("imageFile", image);
        formData.append("text", caption);
        formData.append("tags", JSON.stringify(selectedTags)); // Convert tags to JSON string

        try {
            await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/edit-image/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
                },
            });
            toast.success("Post updated successfully", {
                position: "bottom-right",
              });
            navigate(-1); // Navigate back to the previous page
        } catch (error) {
            console.error("Error editing post:", error);
            toast.error("Error updating post", {
                position: "bottom-right",
              });
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    // if (isFetching) {
    //     return <div>Loading post...</div>;
    // }

    // console.log(imagePreview)
    return (
        <div className="flex flex-col min-h-screen pb-8 bg-black h-full text-white font-dm-sans">
            {/* Navbar */}
            <Navbar />
            
            {/* Main Content */}
            <main className="max-w-7xl mx-auto mt-20">
                <h1 className="text-5xl font-bold text-center mb-4 mt-6">
                    Edit Image
                </h1>
                {isFetching ?(
                    
                    <div className="text-center mt-12 mb-8">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 animate-spin mx-auto fill-white"
                          viewBox="0 0 32 32"
                          >
                          <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                        </svg>
                      </div>
                        ): (
                            <>
                {/* Image Upload Section */}
                <div className="flex flex-col md:flex-row w-full mt-12">
                    <div className="w-full md:w-1/2 flex flex-col items-start ">
                        <h1 className='text-2xl mb-4 ml-8 md:ml-36'>
                            Upload Image
                        </h1>
                        <label 
                          htmlFor="uploadFile2"
                          className="text-gray-500 font-semibold text-base rounded-xl w-[350px] h-[625px] flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed mx-auto font-[sans-serif]"
                        >
                          {imagePreview ? (
                            <div className="relative w-full h-full flex justify-center items-center">
                                <img
                                  className="w-full h-auto rounded-lg"
                                  src={imagePreview}
                                  alt="Uploaded Preview"
                                />
                                <div className="absolute bottom-0 right-0 m-4 ">
                                    <button
                                        className="border border-[#493676] text-white text-sm px-2 py-1 rounded-full "
                                        onClick={triggerFileSelect} 
                                    >
                                        Change
                                    </button>
                                </div>
                            </div>
                          ) : (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-14 mb-4 fill-[#493676]" viewBox="0 0 32 32">
                                <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                                <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                              </svg>
                              <span className="text-sm text-[#493676] ">Upload Image</span>
                              <p className="text-xs font-medium text-gray-400 mt-2">JPG, PNG, GIF allowed</p>
                            </>
                          )}
                          <input type="file" id="uploadFile2" className="hidden" accept="image/png,image/jpeg,image/gif" onChange={handleImageChange} ref={imageRef} disabled={image} />
                        </label>
                    </div>
                    <div className="w-[87%] md:w-1/2 mx-auto ">
                        <h1 className='text-xl my-4'>
                            Picture Caption
                        </h1>
                        <textarea
                            className='w-full h-20 text-black p-4 border border-[#493676] rounded-lg resize-none'
                            placeholder='Write a caption for your image...'
                            value={caption}
                            onChange={handleCaptionChange}
                        ></textarea>

                        {/* Tags Selection */}
                        <div>
                            <h1 className="text-xl my-4">Select Tags</h1>
                            <div className="flex flex-wrap gap-2">
                                {availableTags.map((tag) => (
                                    <button
                                        key={tag}
                                        className={`${
                                            selectedTags.includes(tag)
                                                ? "bg-[#493676] text-white"
                                                : "bg-gray-200 text-gray-800"
                                        } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
                                        onClick={() => handleTagClick(tag)}
                                        disabled={
                                            selectedTags.length >= 3 && !selectedTags.includes(tag)
                                        }
                                    >
                                        {tag}
                                    </button>
                                ))}
                            </div>
                            {selectedTags.length === 3 && (
                                <p className="text-red-500 text-sm mt-2">
                                    You can select up to 3 tags only.
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Edit Button */}
                <div className="flex flex-col items-end justify-end mt-10 mr-4 md:mr-0">
                    <button
                        className={`bg-[#493676] text-white px-4 py-2 rounded-lg text-xl transition duration-200 ease-in-out ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={handleEdit}
                    >
                        {isLoading ? 'Editing...' : 'Edit'}
                    </button>
                </div>
                </>
                    )}
            </main>
        </div>
    );
}
