import React from "react";
import Header from "../components/Header";
import SubFooter from "../components/SubFooter";
import Navbar from "../components/Navbar";

const textClass = "font-bold";
const buttonClass = "rounded-lg";

const SubscriptionCard = ({ imageSrc, altText, title, description }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-[1fr_1fr] gap-3">
      <div className="flex flex-col">
        <div className="flex items-center mb-4">
          <img
            className="w-16 h-16 rounded-full mr-4"
            src={imageSrc}
            alt={altText}
          />
          <span className="font-bold">Name Goes Here</span>
        </div>
        <h2 className="text-xl md:text-2xl font-bold text-[#4A3877]">{title}</h2>
        <p className="mb-4 text-sm md:text-base">{description}</p>
        <div className="flex justify-end w-full md:w-auto">
          <button
            className={
              "bg-[#493676] text-destructive-foreground px-4 py-2 " +
              buttonClass
            }
          >
            Unsubscribe
          </button>
        </div>
      </div>
      <div className="relative h-48 md:h-72">
        <img
          className="rounded-lg object-cover w-full h-full"
          src={imageSrc}
          alt={altText}
        />
      </div>
    </div>
  );
};

const CreatorCard = ({ imageSrc, altText, name, caption }) => {
  return (
    <div>
      <img className="w-full rounded-lg mb-4" src={imageSrc} alt={altText} />
      <h3 className="text-lg font-bold">{name}</h3>
      <p className={textClass + " mb-4 text-sm md:text-base"}>{caption}</p>
      <button
        className={
          "bg-primary text-primary-foreground px-4 py-2 " + buttonClass
        }
      >
        Subscribe
      </button>
    </div>
  );
};

const SubscriptionPage = () => {
  return (
    <div className="bg-black min-h-screen font-dm-sans text-white">
      <Navbar />
      <div className="max-w-6xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl md:text-5xl font-bold mb-4 mt-6">Your Subscriptions</h1>
          <p className={textClass}>You are subscribed to 2/3</p>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-12">
          <SubscriptionCard
            imageSrc="https://placehold.co/64x64"
            altText="David Goggins"
            title="Motivational Speaker"
            description="David Goggins is an internationally acclaimed motivational speaker known for his transformative talks and dynamic presentation style. He is the author of three bestselling books on personal development and leadership and has been featured in top publications such as Forbes and The New York Times. His mission is to help people unlock their full potential and lead more fulfilling lives."
          />
          <SubscriptionCard
            imageSrc="https://placehold.co/64x64"
            altText="Tony Robbins"
            title="Author and Coach"
            description="Tony Robbins is a renowned life coach, author, and motivational speaker known for his dynamic speaking style and self-help programs. With a career spanning over four decades, Robbins has empowered millions of individuals through his seminars, books, and personal development courses. His best-selling books, such as 'Awaken the Giant Within' and 'Unlimited Power,' offer practical strategies for achieving personal and professional success."
          />
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-bold">Suggested Creators</h2>
          <div className="flex justify-between items-center mt-2 flex-col md:flex-row">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <span className={textClass}>Sort by:</span>
              <select className="bg-input text-foreground border border-border rounded-lg px-3 py-1">
                <option>Popularity</option>
                <option>Newest</option>
                <option>Most Subscribed</option>
              </select>
            </div>
            <div className="space-x-2 flex flex-wrap">
              <button className={textClass}>All Creators</button>
              <button className={textClass}>Leadership</button>
              <button className={textClass}>Motivation</button>
              <button className={textClass}>Sports</button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <CreatorCard
            imageSrc="https://placehold.co/300x200"
            altText="Jay Shetty"
            name="Jay Shetty"
            caption="This is a caption #hashtag #relatable"
          />
          <CreatorCard
            imageSrc="https://placehold.co/300x200"
            altText="Les Brown"
            name="Les Brown"
            caption="This is a caption #hashtag #relatable"
          />
          <CreatorCard
            imageSrc="https://placehold.co/300x200"
            altText="Eric Thomas"
            name="Eric Thomas"
            caption="This is a caption #hashtag #relatable"
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
