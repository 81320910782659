import React, { useEffect, useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Navbar from "../components/Navbar";
import SubFooter from "../components/SubFooter";
import { CheckIcon } from '@heroicons/react/20/solid';
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import ReplaceModal from '../components/ReplaceModal';


const MAX_SUBSCRIPTIONS = 3;


const SearchSubscriptionsPage = () => {
  const { authState } = useContext(AuthContext);; // Get the user ID from AuthContext
  const [contentCreators, setContentCreators] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [newCreatorId, setNewCreatorId] = useState(null);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const subscribedCreators = contentCreators.filter(creator =>
    userSubscriptions.includes(creator.id)
  );

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleReplace = async (oldCreatorId, newCreatorId) => {
    try {
      const urlUnsubscribe = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/unsubscribe`;
      const urlSubscribe = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/subscribe`;
      console.log(oldCreatorId, newCreatorId);
  
      // Unsubscribe from the old content creator
      let response = await fetch(urlUnsubscribe, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, contentCreatorId: oldCreatorId }),
      });
      if (!response.ok) throw new Error("Failed to unsubscribe content creator");
  
      // Subscribe to the new content creator
      response = await fetch(urlSubscribe, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, contentCreatorId: newCreatorId }),
      });
      if (!response.ok) throw new Error("Failed to subscribe content creator");
  
      // Update user subscriptions state
      setUserSubscriptions((prev) =>
        prev.filter((id) => id !== oldCreatorId).concat(newCreatorId)
      );
  
      // Close modal after replacing
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  };
  
  async function fetchContentCreators() {
    try {
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/admin/approved`;
      const response = await fetch(url);

      if (!response.ok) throw new Error("Failed to fetch content creators");

      const data = await response.json();
      setContentCreators(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchUserSubscriptions() {
    if (userId) {
    try {
      
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/subscriptions/${userId}`;
      const response = await fetch(url);

      if (!response.ok) throw new Error("Failed to fetch user subscriptions");

      const data = await response.json();
      setUserSubscriptions(data.map(sub => sub.contentCreatorId));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  }


  useEffect(() => {
    if (authState.user) {
      setUser(authState.user);
      setUserId(authState.user.user_id);
      
    }
  }, [authState.user]);

  useEffect(() => {
    if (user) {
      if (user.user_type === "user") {
        fetchUserSubscriptions();
      }
      else {
        navigate('/');
      }
    }
  }, [user]);

  // useEffect(() => {
  //   if (userId) {
  //     fetchUserSubscriptions();
  //   }
  // }, [userId]);
  useEffect(() => {
    fetchContentCreators();
  }, []);

  const handleSubscription = async (contentCreatorId, action) => {
    if(!userId ) {
      navigate('/login');
      return;
    }
    else {
      
      if (action === "subscribe" && userSubscriptions.length >= MAX_SUBSCRIPTIONS) {
        setNewCreatorId(contentCreatorId);
        handleOpenModal();
        return;
      }

    try {

      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/following/${action}`;
      const response = await fetch(url, {
        method: action === "subscribe" ? "POST" : "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, contentCreatorId }),
      });

      if (!response.ok) throw new Error(`Failed to ${action} content creator`);

      if (action === "subscribe") {
        setUserSubscriptions([...userSubscriptions, contentCreatorId]);
        console.log(userSubscriptions)
      } else {
        setUserSubscriptions(userSubscriptions.filter(id => id !== contentCreatorId));
      }
    } catch (error) {
      console.error(error);
    // }
  }};
};


  return (
    <div className="min-h-screen font-dm-sans bg-black text-white">
      <Navbar />
      <div>
      {/* Render the modal */}
      <ReplaceModal
          showModal={showModal}
          subscribedCreators={subscribedCreators}
          onClose={handleCloseModal}
          onReplace={handleReplace}
          newCreatorId={newCreatorId}
        />
    </div>
      <main className="max-w-5xl mx-auto p-6">
        <h1 className="text-5xl font-bold text-center mb-4 mt-6">
          Search Content Creators
        </h1>
        <p className="text-center text-lg text-slate-400 mb-6 mt-6 font-bold">
          Explore and subscribe to your favorite content creators
        </p>

        <div className="flex justify-center" id="subscription-search-bar">
          <div className="relative w-full md:w-2/3">
            <SearchIcon className="absolute z-0 top-1/2 left-4 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search for creators"
              className="w-[100%] bg-card p-4 rounded-2xl pl-12 text-gray-700"
            />
          </div>
        </div>

        <div className="flex justify-center space-x-2 mb-6 mt-2">
          {/* Category Buttons */}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {contentCreators.map((creator) => (
            <SubscriptionCard
              key={creator.id}
              id = {creator.id}
              name={creator.name}
              imageSrc={creator.profilePicture}
              caption={creator.bio}
              isSubscribed={userSubscriptions.includes(creator.id)}
              onSubscribe={() => handleSubscription(creator.id, "subscribe")}
              onUnsubscribe={() => handleSubscription(creator.id, "unsubscribe")}
            />
          ))}
        </div>

      </main>
      {authState.paid &&
      <SubFooter />
      }
            {/* Conditional Button */}
            {!authState.paid && userSubscriptions.length >= MAX_SUBSCRIPTIONS && (
        <div className="fixed bottom-8 right-8">
          <div class="group flex w-full cursor-pointer items-center justify-center rounded-md bg-green-500 px-6 py-2 text-white transition"
  onClick={() => navigate('/pricing')}
          >
  <span class="group flex w-full items-center justify-center rounded py-1 text-center font-bold"> Continue </span>
  <svg class="flex-0 ml-4 h-6 w-6 transition-all group-hover:ml-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
  </svg>
</div>
        </div>
      )}
    </div>
  );
};


const SubscriptionCard = ({ id, name, imageSrc, caption, isSubscribed, onSubscribe, onUnsubscribe }) => {
  const navigate = useNavigate();

  const sharedStyles = {
    card: "bg-card p-4 rounded-lg shadow-lg hover:bg-zinc-800 duration-300 cursor-pointer flex flex-col justify-between h-full", // Changed to flex column
    button: "py-1 px-3 rounded-full text-base flex items-center mt-2", // Removed absolute positioning
    subscribedButton: "bg-green-500 text-white hover:bg-green-600",
    subscribeButton: "bg-[#493676] text-white hover:bg-[#3e2a5a]",
    icon: "h-6 w-5 flex-none text-white mr-2",
  };

  const handleNavigation = () => {
    navigate(`/creator/${id}`);
  };

  const handleButtonClick = (e, action) => {
    e.stopPropagation();
    action();
  };

  return (
    <div className={sharedStyles.card} onClick={handleNavigation}>
      <div className="flex justify-center mb-2">
        <img src={imageSrc} alt={name} className="rounded-lg mb-1" />
      </div>
      <h2 className="text-lg font-bold">{name}</h2>
      <p className="mb-1 line-clamp-2">{caption}</p>
      <div className="flex justify-end mt-auto"> {/* This flex container ensures the button stays at the bottom */}
        {isSubscribed ? (
          <button
            className={`${sharedStyles.button} ${sharedStyles.subscribedButton}`}
            onClick={(e) => handleButtonClick(e, onUnsubscribe)}
          >
            <CheckIcon aria-hidden="true" className={sharedStyles.icon} />
            Subscribed
          </button>
        ) : (
          <button
            className={`${sharedStyles.button} ${sharedStyles.subscribeButton}`}
            onClick={(e) => handleButtonClick(e, onSubscribe)}
          >
            Subscribe
          </button>
        )}
      </div>
    </div>
  );
};




export default SearchSubscriptionsPage;
