import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import ReelCarousel from "../components/ReelCarousel";
import FilterCarousel from "../components/FilterCarousel";
import { FaSpinner } from "react-icons/fa";
import { CiGrid2V, CiGrid2H } from "react-icons/ci";
import ReelsContext from "../context/ReelsContext";
import ReelsList from "../components/ReelsList.jsx";
import VideoPost from "../components/videoPostForYou.jsx";

const ForYou = () => {
  const { authState } = useContext(AuthContext);
  const [filteredReels, setFilteredReels] = useState([]); // Initialize as an empty array
  const [currentFilteredReelIndex, setCurrentFilteredReelIndex] = useState(null);
  const [filter, setFilter] = useState("All");
  const [currentReelIndex, setCurrentReelIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [view, setView] = useState("horizontal"); // State to toggle view
  const [activeFilter, setActiveFilter] = useState("All");

  const filters = [
    "All",
    "Fitness",
    "Meditation",
    "Lifestyle",
    "Coaching",
    "Mental Wellness",
    "Technology",
    "Health",
    "Education",
    "Business",
    "Music",
  ];

  async function getFilteredReels(filter) {
    try {
      let url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/category?category=${filter}&page=${page}&pageSize=${pageSize}`;
      const response = await fetch(url);
      const data = await response.json();
      setFilteredReels(data.videos); // Ensure data is an array
      console.log(data.videos);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   getReels();
  // }, []);

  useEffect(() => {
    if (filter) {
      getFilteredReels(filter);
    }
  }, [filter]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${hours}:${formattedMinutes} ${ampm} · ${month} ${day}, ${year}`;
  }

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    setFilter(filter);
    setLoading(true);
  };

  return (
    <ReelsContext.Provider
      value={{
        currentReelIndex,
        setCurrentReelIndex,
        filteredReels,
        setFilteredReels,
        setFilter,
        currentFilteredReelIndex,
        setCurrentFilteredReelIndex,
        formatDate,
      }}
    >
      <div className="min-h-screen bg-black h-full pb-12 font-dm-sans">
        <Navbar />
        <div className="mx-auto max-w-2xl text-center px-6 sm:px-8 lg:px-12">
          <h2 className="font-bold tracking-tight text-white text-2xl sm:text-4xl lg:text-5xl mt-12 sm:mt-16">
            Curated Content Just for You
          </h2>
          <p className="mt-4 sm:mt-6 text-base sm:text-lg lg:text-xl leading-7 sm:leading-8 text-white">
            Explore personalized videos tailored to your interests, from fitness to technology. Enjoy top content creators in one place.
          </p>
        </div>

        {/* View Toggle Section */}
        <div className="flex justify-end mr-4 sm:mr-20 items-center mt-6">
          <button
            className={`mx-1 p-2 text-white ${view === "horizontal" ? "bg-[#493676]" : "bg-primary"} rounded-lg`}
            onClick={() => setView("horizontal")}
          >
            <CiGrid2H size={28} />
          </button>
          <button
            className={`mx-1 p-2 text-white ${view === "vertical" ? "bg-[#493676]" : "bg-primary"} rounded-lg`}
            onClick={() => setView("vertical")}
          >
            <CiGrid2V size={28} />
          </button>
        </div>

        {/* Display Reels based on selected view */}
        <div className="mt-12 px-2">
  {/* Filter Section - Always Visible */}
  <div className="flex flex-wrap justify-center space-x-4 bg-black py-8 rounded-lg">
    {filters.map((filter) => (
      <button
        key={filter}
        onClick={() => handleFilterClick(filter)}
        className={`${
          activeFilter === filter ? "bg-white text-black" : "text-white"
        } px-4 py-2 rounded-full font-semibold transition duration-200`}
      >
        {filter}
      </button>
    ))}
  </div>

  {/* Loader Section - Displayed below filters */}
  {loading ? (
    <div className="flex justify-center items-center h-[10rem]">
      <FaSpinner className="text-white animate-spin" size={32} />
    </div>
  ) : view === "vertical" ? (
    <FilterCarousel /> // Vertical Reel Carousel
  ) : (
    <>
      {/* Display Content once loading is complete */}
      <div>
      <ReelsList reels={filteredReels} />
      </div>
    </>
  )}
</div>

      </div>
    </ReelsContext.Provider>
  );
};

export default ForYou;
