import React, { useState, useEffect,  useRef, useCallback } from "react";
import Navbar from "../components/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
    FaHeart,
    FaShare,
    FaPlay,
    FaPause,
    FaVolumeMute,
    FaVolumeUp,
  } from "react-icons/fa"; 
  import { toast } from "react-toastify";

export default function EditVideo() {
    const { id } = useParams();
    const navigate = useNavigate();

    // State for video, preview, caption, and tags
    const [video, setVideo] = useState(null);
    const [videoPreview, setVideoPreview] = useState("");
    const [caption, setCaption] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailName, setThumbnailName] = useState(null);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false); // State to manage video play/pause

    // Loading state
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    // Available tags
    const availableTags = [
        "Fitness", "Meditation", "Lifestyle", "Coaching", "Mental Wellness", 
        "Technology", "Health", "Education", "Business", "Music"
    ];

    // Fetch post data from the backend when component mounts
    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video-details/${id}`);
                const postData = response.data;
                console.log('Data fetched on edit video: ', postData);

                // Set the video preview, caption, and tags
                // setVideoPreview(postData.video.videoUrl); // Assuming the video URL is stored as videoUrl
                setThumbnailName(postData.thumbnailName)
                setThumbnail(postData.thumbnail)
                setCaption(postData.title); // Assuming caption is stored in the post
                setSelectedTags(postData.tags); // Assuming the tags are an array
            } catch (error) {
                console.error("Error fetching post data:", error);
            } finally {
                setIsFetching(false);
            }
        };

        fetchPostData();
    }, [id]);


    function truncateFileNameWithExtension(filePath) {
        // Extract the file name with extension from the file path
        const fileNameWithExt = filePath.split('/').pop(); // Get the last part of the path
        const [fileName, extension] = fileNameWithExt.split(/(\.[^.]+)$/); // Split the name and extension
    
        // Split the file name into words
        const words = fileName.split(' ');
    
        // Truncate if more than 5 words
        if (words.length > 5) {
            const truncated = words.slice(0, 5).join(' '); // Get the first 5 words
            return `${truncated}...${extension}`; // Add ellipsis and extension
        }
        
        return fileNameWithExt; // Return the original file name with extension if it's 5 words or less
    }
    

    const handleThumbnailChange = (event) => {
        const selectedThumbnail = event.target.files[0];
        if (selectedThumbnail) {
            setThumbnail(selectedThumbnail);
            setThumbnailName(selectedThumbnail.name); // Corrected to use selectedThumbnail.name
            console.log("Changed thumbnail name: ", selectedThumbnail.name);
        }
    };
    


    // Handle video change
    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setVideo(file);
                setVideoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle caption change
    const handleCaptionChange = (e) => {
        setCaption(e.target.value);
    };

    // Handle tag selection
    const handleTagClick = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((t) => t !== tag)); // Deselect tag if already selected
        } else if (selectedTags.length < 3) {
            setSelectedTags([...selectedTags, tag]); // Select tag if less than 3 tags are selected
        }
    };

    const playOrPause = useCallback(() => {
        const video = videoRef.current;
        if (video) {
            if (video.paused || video.ended) {
                video.play();
                setIsPlaying(true);
            } else {
                video.pause();
                setIsPlaying(false);
            }
        }
    }, []);


    // Handle Edit button click
    const handleEdit = async () => {
        setIsLoading(true); // Set loading state
        try {
            // Create a FormData object to handle file and other data
            const formData = new FormData();
            formData.append("thumbnail", thumbnail); // Append the thumbnail file
            formData.append("title", caption); // Append the caption
            formData.append("tags", JSON.stringify(selectedTags)); // Append the tags as JSON string if they're an array

    
            // Send the request with FormData
            await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/edit-video/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
                },
            });
    
            toast.success("Video updated successfully", {
                position: "bottom-right",
              });
            navigate(-1); // Navigate back to the previous page
        } catch (error) {
            console.error("Error editing post:", error);
            toast.error("Error updating post", {
                position: "bottom-right",
              });
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };
    

    // if (isFetching) {
    //     return <div>Loading post...</div>;
    // }

    // console.log(caption);
    // console.log(selectedTags)
    // console.log(thumbnail)
    return (
        <div className="flex flex-col min-h-screen pb-8 bg-black h-full text-white font-dm-sans">
            {/* Navbar */}
            <Navbar />

            {/* Main Content */}
            <main className="max-w-7xl mx-auto mt-20">
                <h1 className="text-5xl font-bold text-center mb-4 mt-6">
                    Edit Video
                </h1>
                {isFetching ?(
                    
                    <div className="text-center mt-12 mb-8">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 animate-spin mx-auto fill-white"
                          viewBox="0 0 32 32"
                          >
                          <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                        </svg>
                      </div>
                        ): (
                            <>
                {/* Video Upload Section */}
                <div className="flex flex-col md:flex-row w-full mt-12">
                    <div className="w-full md:w-1/2 flex flex-col items-start ">
                        <h1 className='text-2xl mb-4 ml-8 md:ml-36'>
                            Edit Video
                        </h1>
                        <div 
                          className="text-gray-500 font-semibold text-base rounded-xl w-[350px] h-[625px] flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed mx-auto font-[sans-serif]"
                        >
                        
                            <div className="relative w-full h-full flex justify-center items-center" onClick={playOrPause}>
                                <video
                                  ref={videoRef}
                                  className="w-full h-auto rounded-lg"
                                  src={`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video/${id}`}
                                  poster={thumbnail}
                                  onPlay={() => setIsPlaying(true)}
                                  onPause={() => setIsPlaying(false)}
                                ></video>
                              {!isPlaying && (
                                <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-28 h-28 fill-white opacity-90"
                                    viewBox="0 0 32 32"
                                    >
                                    <path d="M12 10v12l10-6z" />
                                    </svg>
                                </div>
                                )}
                            </div>
                

                        </div>
                    </div>

                    {/* Caption & Tags Section */}
                    <div className="w-[87%] md:w-1/2 mx-auto ">
                    <h1 className="text-2xl mb-4 mt-4 md:mt-0">
                Upload Thumbnail
            </h1>
            <label
              htmlFor="uploadThumbnail"
              className="text-[#493676] font-semibold text-base rounded max-w-md h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed font-[sans-serif]"
              >
              {thumbnail ? (
                <span className="text-sm text-[#493676]">{truncateFileNameWithExtension(thumbnailName)}</span>
            ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-11 mb-2 fill-[#493676]" viewBox="0 0 32 32">
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                  </svg>
                  Upload Thumbnail
                  <p className="text-xs font-medium text-gray-400 mt-2">PNG, JPG, JPEG allowed.</p>
                </>
              )}
              <input type="file" id="uploadThumbnail" className="hidden" accept="image/png, image/jpeg" onChange={handleThumbnailChange} />
            </label>
                        <h1 className='text-xl my-4'>
                            Video Caption
                        </h1>
                        <textarea
                            className='w-full h-20 text-black p-4 border border-[#493676] rounded-lg resize-none'
                            placeholder='Write a caption for your video...'
                            value={caption}
                            onChange={handleCaptionChange}
                        ></textarea>

                        {/* Tags Selection */}
                        <div>
                            <h1 className="text-xl my-4">Select Tags</h1>
                            <div className="flex flex-wrap gap-2">
                                {availableTags.map((tag) => (
                                    <button
                                        key={tag}
                                        className={`${
                                            selectedTags.includes(tag)
                                            ? "bg-[#493676] text-white"
                                                : "bg-gray-200 text-gray-800"
                                        } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
                                        onClick={() => handleTagClick(tag)}
                                        disabled={
                                            selectedTags.length >= 3 && !selectedTags.includes(tag)
                                        }
                                    >
                                        {tag}
                                    </button>
                                ))}
                            </div>
                            {selectedTags.length === 3 && (
                                <p className="text-red-500 text-sm mt-2">
                                    You can select up to 3 tags only.
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Edit Button */}
                <div className="flex flex-col items-end justify-end mt-10 mr-4 md:mr-0">
                    <button
                        className={`bg-[#493676] text-white px-4 py-2 rounded-lg text-xl transition duration-200 ease-in-out ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={handleEdit}
                        >
                        {isLoading ? 'Editing...' : 'Edit'}
                    </button>
                </div>
                        </>
                    )}
            </main>
        </div>
    );
}
