import React, { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {} from "react-router-dom";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    accessToken: null,
    refreshToken: null,
    user: null,
    paid: false,
  });
  const login = async (email, password, navigate, setErrorMessage) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/login`,
        { email, password },
        {
          withCredentials: true,
        },
      );
      const { accessToken, refreshToken } = response.data;
      const user = jwtDecode(accessToken);

      setAuthState({
        accessToken,
        refreshToken,
        user,
      });

      localStorage.setItem("accessToken", accessToken);
      scheduleTokenRefresh(accessToken);

      console.log({ user });
      if (user.user_type === "admin") {
        navigate("/admin");
      } else if (user.user_type === "creator") {
        const videoCheckResponse = await axios.post(
          `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/check-video/${user.user_id}`,
          {},
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            withCredentials: true,
          },
        );

        console.log({ videoCheckResponse });

        if (!videoCheckResponse.data.video) {
          console.log("No video found.");

          navigate("/edit-information");
        } else {
          navigate("/");
        }
      } else if (user.user_type === "user") {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/api/subscription/check`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (response.ok) {
          console.log("Subscription status fetched.");

          const subscription = await response.json();
          console.log({ subscription });
          console.log(subscription.status);

          if (subscription.status === "active") {
            setAuthState({
              accessToken,
              refreshToken,
              user,
              paid: true,
            });
            navigate("/");
          } else {
            console.log("Subscription not active.");

            setAuthState({
              accessToken,
              refreshToken,
              user,
              paid: false,
            });
            navigate( "/search-subscriptions");
          }
        } else {
          console.error("Failed to fetch subscription status.");
          setAuthState({
            accessToken,
            refreshToken,
            user,
            paid: false,
          });
          navigate( "/search-subscriptions");
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.reason === "under_review") {
        navigate("/under-review");
      } else {
        setErrorMessage("Invalid email or password.");
      }
    }
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };
  const signOut = () => {
    auth.signOut();
  };

  const register = async (
    username,
    email,
    password,
    type,
    dob,
    gender,
    navigate,
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/register`,
        {
          username,
          email,
          password,
          type,
          dob,
          gender,
        },
      );

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const { accessToken, refreshToken } = response.data;
      setAuthState({
        accessToken,
        refreshToken,
        user: jwtDecode(accessToken),
      });
      localStorage.setItem("accessToken", accessToken);
      scheduleTokenRefresh(accessToken);
      navigate("/search-subscriptions");
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/logout`);
      setAuthState({
        accessToken: null,
        refreshToken: null,
        user: null,
      });
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      if (refreshTimeout) clearTimeout(refreshTimeout);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshAccessToken = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/refresh-token`,
        {
          withCredentials: true,
        },
      );
      const { accessToken } = response.data;
      setAuthState((prevState) => ({
        ...prevState,
        accessToken,
        user: jwtDecode(accessToken),
      }));
      localStorage.setItem("accessToken", accessToken);
      scheduleTokenRefresh(accessToken);
    } catch (error) {
      console.error(error);
      logout();
    }
  }, [logout]);

  const scheduleTokenRefresh = (accessToken) => {
    const { exp } = jwtDecode(accessToken);
    const expiryTime = exp * 1000 - Date.now() - 5 * 60 * 1000; // 5 minutes before expiry
    if (expiryTime > 0) {
      if (refreshTimeout) clearTimeout(refreshTimeout); // Clear any existing timeout
      refreshTimeout = setTimeout(refreshAccessToken, expiryTime);
    }
  };

  const handleAccessToken = () => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("accessToken");
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
    }
  };

  const setPaymentStatus = async () => {
    if (authState.user) {
      if (authState.user.user_type === "user") {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/api/subscription/check`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authState.accessToken}`,
            },
          },
        );

        if (response.ok) {
          const subscription = await response.json();
          if (subscription.status === "active") {
            setAuthState({
              ...authState,
              paid: true,
            });
          } else {
            setAuthState({
              ...authState,
              paid: false,
            });
          }
        } else {
          console.error("Failed to fetch subscription status.");
          setAuthState({
            ...authState,
            paid: false,
          });
        }
      }
    }
  };

  useEffect(() => {
    setPaymentStatus();
  }, [authState.user, authState.accessToken]);

  useEffect(() => {
    const token = handleAccessToken() || localStorage.getItem("accessToken");
    if (token && token !== authState.accessToken) {
      const user = jwtDecode(token);
      setAuthState({
        accessToken: token,
        refreshToken: null,
        user,
      });

      scheduleTokenRefresh(token);
    } else {
      console.log("No access token found.");
      // If no access token is found, attempt to refresh the token
      // Check if redirected from Google OAuth
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get("accessToken");
      if (accessToken) {
        setAuthState({
          accessToken,
          refreshToken: null, // Since refreshToken is not handled here
          user: jwtDecode(accessToken),
        });
        localStorage.setItem("accessToken", accessToken);
        window.history.replaceState({}, document.title, "/"); // Clean up URL
      }
    }
  }, [refreshAccessToken]);

  let refreshTimeout;

  return (
    <AuthContext.Provider
      value={{
        authState,
        login,
        register,
        logout,
        refreshAccessToken,
        setAuthState,

        googleSignIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
