import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Logo from "../assets/logo.png";
import Google from "../assets/google.png";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';


const Login = () => {
  const { authState, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailChecked, setEmailChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (authState.user) {
      navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [authState.user, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
    setErrorMessage("");
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCheckEmail = async () => {
    if (!email) {
      setErrorMessage("Enter a valid email.");
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage("Enter a valid email format.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/check-email`,
        { email },
      );
      if (response.data.exists) {
        setEmailChecked(true);
      } else {
        setErrorMessage("Account doesn't exist.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Account doesn't exist.");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password, navigate, setErrorMessage);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#131313] font-roboto text-white">
      <div className="w-full max-w-md p-8 space-y-6">
        <div className="text-center">
          <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
          <h2 className="text-2xl font-bold font-dm-sans">
            Log in to Unindulgent
          </h2>
        </div>
        <div className="space-y-4">
          <div>
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={handleEmailChange}
              className="appearance-none rounded-md relative block w-full px-3 py-2 border placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-[#493676] focus:border-[#493676] sm:text-sm"
              placeholder="Email"
            />
            {errorMessage && (
              <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
            )}
          </div>
          {!emailChecked && (
            <>
              <div className="flex items-center justify-center">
                <span className="text-white font-semibold">or</span>
              </div>
              <button
                onClick={() =>
                  window.location.assign(`${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/google`)
                }
                className="w-full flex items-center justify-center py-2 px-4 border border-border rounded-md shadow-sm text-sm font-medium bg-white text-gray-500 hover:bg-white/90"
              >
                <img src={Google} alt="Google logo" className="mr-4 w-6" />
                <p className="mt-0.5">Continue with Google</p>
              </button>

              <div className="w-full flex justify-center">
                <button
                  onClick={handleCheckEmail}
                  className="py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80"
                >
                  Continue
                </button>
              </div>
            </>
          )}
          {emailChecked && (
            <>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-[#493676] focus:border-[#493676] sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div className="w-full flex justify-center">
                <button
                  onClick={handleSubmit}
                  className="py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80"
                >
                  Login
                </button>
              </div>
              {/* now for forgot password */}
              <div className="text-center">
                <Link
                  to="/forgot-password"
                  className="text-sm text-muted-foreground hover:underline"
                >
                  Forgot your password?
                </Link>
              </div>
            </>
          )}
          <div className="text-center">
            <p className="text-sm text-muted-foreground">
              New to Unindulgent?{" "}
              <Link
                to="/register"
                className="text-sm text-muted-foreground hover:underline"
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
        <div className="text-center text-xs text-muted-foreground">
          By signing up, you are creating a Unindulgent account and agree to
          Unindulgent's{" "}
          <Link to="/#" className="underline">
            Terms
          </Link>{" "}
          and{" "}
          <Link to="/#" className="underline">
            Privacy Policy
          </Link>
          .
        </div>
      </div>
    </div>
  );
};

export default Login;
