import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Logo from "../assets/logo.png"; // Adjust the import path as needed
import { FaPen } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

const primaryButtonClass =
  "py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80";

const disabledButtonClass =
  "py-2 px-24 border border-transparent rounded-full shadow-sm bg-gray-400 text-gray-600 font-bold cursor-not-allowed hover:bg-gray-400";


const EditInformation = () => {
  const { authState } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    id: "",
    name: "",
    email: "",
    bio: "",
    profilePicture: "",
    instagramId: "",
    tiktokId: "",
    twitterId: "",
    facebookId: "",
    linkedinId: "",
    motivation: "",
    contentLinks: "",
    preferredContentCategories: "",
    sampleWork: "",
    contactNumber: "",
    location: "",
    websiteUrl: "",
    additionalComments: "",
    status: "",
    password: "",
    createdAt: "",
    updatedAt: "",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videoError, setVideoError] = useState(""); 

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        setUserData({ ...userData, profilePicture: base64 });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (authState.user) {
      getUserInfo(authState.user.user_id);
    }
  }, [authState.user]);

  async function getUserInfo(id) {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/${id}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) { // 50MB size limit
        setVideoError("File size is larger than 50 MB. Video size must not exceed 50 MB.");
      } else {
        setVideoError(""); // Reset error if file size is within limit
        setUserData({ ...userData, introductionVideo: file });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      for (const key in userData) {
        formData.append(key, userData[key]);
      }
  
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/update`;
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        toast.success("Information updated successfully", {
          position: "bottom-right"
        });
        navigate("/");
      } else if (response.status === 413) {
        // Specific error handling for 413 (Request Entity Too Large)
        toast.error("Uploaded file is larger than 50 MB", {
          position: "bottom-right"
        });
      } else {
        toast.error("An error occurred while updating information", {
          position: "bottom-right"
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
  
      // Handling client-side errors, including the "Failed to fetch" CORS error.
      if (error instanceof TypeError) {
        toast.error("An error occurred while updating information", {
          position: "bottom-right"
        });
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="min-h-screen font-roboto bg-black text-white flex items-center w-full justify-center p-4">
      <div className="w-full md:w-2/3 bg-card p-8 md:mx-auto mt-10 rounded-lg shadow-lg">
        <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
        <h1 className="text-3xl font-bold text-center mb-4">
          Complete Your Information
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="mt-2 relative">
            <span
              className="block w-40 h-40 rounded-full shadow"
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundImage: `url('${userData.profilePicture}')`,
              }}
            ></span>
            <button
              type="button"
              className="absolute bottom-2 left-2 p-1 bg-white hover:bg-white/80 text-black rounded-full"
              onClick={handleButtonClick}
              title="Select New Photo"
            >
              <FaPen />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="name" className="block mb-1 text-lg font-semibold">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              disabled={true}
              value={userData.name}
              onChange={handleChange}
              className="w-full p-2 border border-border rounded text-black disabled:bg-white disabled:border-gray-300 disabled:cursor-not-allowed"
              placeholder="Enter Your Full Name"
            />
          </div>

          <div>
            <label htmlFor="email" className="block mb-1 text-lg font-semibold">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              disabled={true}
              value={userData.email}
              onChange={handleChange}
              className="w-full p-2 border border-border rounded text-black disabled:bg-white disabled:border-gray-300 disabled:cursor-not-allowed"
              placeholder="Enter Your Email Address"
            />
          </div>

          <div>
            <label htmlFor="bio" className="block mb-1 text-lg font-semibold">
              About
            </label>
            <textarea
              id="bio"
              name="bio"
              value={userData.bio}
              onChange={handleChange}
              className="w-full p-2 border border-border rounded text-black min-h-32 resize-none"
              placeholder="Tell Us About Yourself"
              rows="4"
            />
          </div>

          <div>
          <label
            htmlFor="introductoryVideo"
            className="block mb-1 text-lg font-semibold"
          >
            Introductory Video
            {/* Tooltip and info icon */}
            <span className="ml-2 relative inline-block group">
          <span
            onClick={(e) => e.preventDefault()} // Prevent file input dialog on click
          >
            <InformationCircleIcon className="w-5 h-5 text-white inline-block" />
          </span>
          {/* Tooltip */}
          <div className="absolute bottom-full left-0 mb-2 w-64 p-2 text-sm bg-gray-700 text-white rounded opacity-0 group-hover:opacity-100 transition-opacity duration-500">
            Video size limit is only 50 MB
          </div>
        </span>
          </label>
            <input
              type="file"
              id="introductoryVideo"
              name="introductoryVideo"
              onChange={handleVideoChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
             {videoError && (
              <p className="text-red-500 text-sm italic mt-2">{videoError}</p>
            )}
          </div>

          <div className="flex justify-center">
            <button type="submit" 
            className={`${primaryButtonClass} ${videoError !== "" ? disabledButtonClass : ""}`}
            disabled={videoError !== "" || loading}
            >
            {loading ? 
             <svg
             xmlns="http://www.w3.org/2000/svg"
             className="w-6 h-6 animate-spin mx-auto fill-white"
             viewBox="0 0 32 32"
           >
             <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
           </svg>
            : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditInformation;
