import React from 'react';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen font-roboto bg-black text-white flex items-center justify-center p-6">
            <div className="max-w-3xl w-full">
                <Link to="/">
                    <div className="flex justify-center mb-6">
                        <img src={Logo} alt="logo" className="w-12" />
                    </div>
                </Link>
                <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>
                
                <section className="mb-8">
                    <p className="text-lg leading-relaxed">
                        We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines what data we collect, how it is used, and your rights regarding your information.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
                    <p className="text-lg leading-relaxed mb-4">
                        During the sign-in process, we collect the following information from your Google account:
                    </p>
                    <ul className="list-disc list-inside text-lg leading-relaxed pl-6">
                        <li>Your profile information (name, profile picture)</li>
                        <li>Your email address</li>
                        <li>Your date of birth</li>
                        <li>Your gender</li>
                    </ul>
                </section>
                
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
                    <p className="text-lg leading-relaxed">
                        The information collected is used solely to create and manage your account within our platform. Your data helps us verify your identity and provide a personalized experience.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Data Security</h2>
                    <p className="text-lg leading-relaxed">
                        We implement industry-standard security measures to protect your information. However, please note that no method of transmission over the Internet is entirely secure.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Your Rights</h2>
                    <p className="text-lg leading-relaxed">
                        You have the right to access, update, or delete your personal information. To make any requests regarding your data, please contact our support team.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Changes to This Policy</h2>
                    <p className="text-lg leading-relaxed">
                        We may update this Privacy Policy from time to time. Changes will be posted on this page, so please review it periodically.
                    </p>
                </section>
                
                <p className="text-sm text-gray-400 mt-12 text-center">
                    Last updated: {new Date().toLocaleDateString()}
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
