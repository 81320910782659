import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  FaHeart,
  FaShare,
  FaPlay,
  FaPause,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa"; // Importing icons from react-icons
import { AuthContext } from "../context/AuthContext";
import { TbPinnedFilled } from "react-icons/tb";

const VideoPost = (props) => {
  const { authState } = useContext(AuthContext);
  const [playing, setPlaying] = useState(false); // State to manage video play/pause
  const [liked, setLiked] = useState(false); // State to manage like status
  const [totalLikes, setTotalLikes] = useState(0); // State to store total likes
  const [startTime, setStartTime] = useState(0);
  const [watchedDuration, setWatchedDuration] = useState(0);
  const videoRef = useRef(null); // Ref to access video element

  async function registerView() {
    try {
      setStartTime(Date.now());
      let url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/register-view`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          videoId: props.videoId,
          userId: authState.user.user_id,
        }),
      });

      if (response.ok) {
        console.log("View registered");
        return;
      }
      console.log("Failed to register view");
    } catch (error) {
      console.log(error);
    }
  }

  async function registerDuration() {
    try {
      const endTime = Date.now();
      const durationWatched = Math.floor((endTime - startTime) / 1000);
      const previousDuration = watchedDuration;
      setWatchedDuration((prevDuration) => prevDuration + durationWatched);

      let url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/register-view-duration`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          videoId: props.videoId,
          userId: authState.user.user_id,
          duration: durationWatched + previousDuration,
        }),
      });

      if (response.ok) {
        console.log("Duration registered");
        return;
      }
      console.log("Failed to register duration");
    } catch (error) {
      console.log(error);
      console.log("Failed to register duration");
    }
  }

  // Play or pause the video
  const playOrPause = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      if (video.paused || video.ended) {
        video.play();
        setPlaying(true);
      } else {
        video.pause();
        setPlaying(false);
      }
    }
  }, [authState]);

  // Automatically pause the video after it starts
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setPlaying(false);
    }
  }, []);

  useEffect(() => {}, [playing]);

  // Format the date string
  function formatDate(dateString) {
    const date = new Date(dateString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${hours}:${formattedMinutes} ${ampm} · ${month} ${day}, ${year}`;
  }

  // Fetch total likes and check if user has liked the video
  useEffect(() => {
    console.log(authState.user);
    async function fetchLikeData() {
      try {
        const [likesResponse, hasLikedResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${props.videoId}/likes`, {
            headers: {
              Authorization: `Bearer ${authState?.accessToken}`,
            },
          }),
          fetch(
            `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${props.videoId}/hasLiked`,
            {
              headers: {
                Authorization: `Bearer ${authState?.accessToken}`,
              },
            },
          ),
        ]);

        if (likesResponse.ok) {
          const { totalLikes } = await likesResponse.json();
          setTotalLikes(totalLikes);
        }

        if (hasLikedResponse.ok) {
          const { hasLiked } = await hasLikedResponse.json();
          setLiked(hasLiked);
        }
      } catch (error) {
        console.error("Error fetching like data:", error);
      }
    }

    fetchLikeData();
  }, [props.videoId, authState]);

  // Toggle like status
  const handleLikeClick = async () => {
    try {
      const method = liked ? "DELETE" : "POST";
      const url = liked
        ? `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${props.videoId}/unlike`
        : `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${props.videoId}/like`;

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${authState?.accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setLiked(!liked);
        setTotalLikes((prev) => (liked ? prev - 1 : prev + 1));
      } else {
        console.error("Failed to like/unlike the video");
      }
    } catch (error) {
      console.error("Error liking/unliking video:", error);
    }
  };

  console.log({ authState });

  return (
    <div className="bg-[#1b1c20] rounded-xl mx-auto p-4 w-full max-w-lg text-white">
      <div className="flex items-center mb-2">
        <img
          className="w-10 h-10 rounded-full object-cover"
          src={props.profilePicture}
          alt=""
        />
        <div className="ml-3 w-full flex justify-between items-center">
          <span className="font-bold mr-4">{props.contentCreator}</span>
          {props.pinned && (
            <div className="flex">
              <TbPinnedFilled className="text-white ml-2" size={20} />
            </div>
          )}
        </div>
      </div>

      <p className="text-lg mb-2">{props.caption}</p>
      <div className="border border-gray-500 rounded-lg overflow-hidden mb-2 relative w-full h-[855px]  md:h-[855px] lg:h-[855px]">
        <div className="relative w-full h-full" onClick={playOrPause}>
          {authState?.accessToken && (
            <video
              ref={videoRef}
              className="absolute inset-0 w-full h-full object-cover"
              onPlay={registerView}
              onPause={registerDuration}
              onEnded={registerDuration}
              style={{ objectFit: "cover" }}
            >
              <source
                src={`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video/${props.videoId}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}

          {!playing && (
            <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
              <FaPlay className="w-16 h-16 text-white opacity-90" />
            </div>
          )}
          {playing && (
            <div className="absolute bottom-0 right-0 m-4">
              <button
                onClick={playOrPause}
                className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
              >
                <FaVolumeUp />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-wrap mb-2">
        {props.tags.map((tag) => (
          <span
            key={tag}
            className="inline-block bg-[#493676] text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
          >
            {tag}
          </span>
        ))}
      </div>
      <span className="text-sm text-gray-400">{formatDate(props.date)}</span>
      <div className="flex items-center mt-3 space-x-4">
        <button
          onClick={handleLikeClick}
          className={`flex items-center duration-200 ${
            liked ? "text-purple-500" : "text-gray-400 hover:text-white"
          }`}
        >
          <FaHeart className="w-5 h-5 mr-1" />
          <span>{liked ? "Liked" : "Like"}</span>
        </button>
      </div>
    </div>
  );
};

export default VideoPost;
