import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useParams, useNavigate } from "react-router-dom";  // Import useNavigate
import axios from "axios";
import { toast } from "react-toastify";

export default function EditText() {
    const { id } = useParams();
    const navigate = useNavigate();  // Initialize useNavigate

    // State for post content
    const [content, setContent] = useState("");

    // State to track if changes were made
    const [isChanged, setIsChanged] = useState(false);

    // Loading state for the button
    const [isLoading, setIsLoading] = useState(false);

    // State to track loading for fetching data
    const [isFetching, setIsFetching] = useState(true);

    // Character limit
    const maxLength = 300;

    // Available tags (example tags, replace with actual tags if necessary)
    const availableTags = [
        "Fitness",
        "Meditation",
        "Lifestyle",
        "Coaching",
        "Mental Wellness",
        "Technology",
        "Health",
        "Education",
        "Business",
        "Music"
    ];

    // State for selected tags
    const [selectedTags, setSelectedTags] = useState([]);

    // Fetch post data from the backend when component mounts
    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/${id}`);
                const postData = response.data.post;
                console.log('Data fetched on edit text: ', postData);
                
                // Set the post content and tags
                setContent(postData.text);
                setSelectedTags(postData.tags); // Assuming the tags are an array
            } catch (error) {
                console.error("Error fetching post data:", error);
            } finally {
                setIsFetching(false);
            }
        };

        fetchPostData();
    }, [id]);

    // Handle content change in textarea
    const handleContentChange = (e) => {
        if (e.target.value.length <= maxLength) {
            setContent(e.target.value);
            setIsChanged(true);  // Set to true when content is changed
        }
    };

    // Handle tag selection
    const handleTagClick = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((t) => t !== tag)); // Deselect tag if already selected
        } else if (selectedTags.length < 3) {
            setSelectedTags([...selectedTags, tag]); // Select tag if less than 3 tags are selected
        }
        setIsChanged(true);  // Set to true when tags are changed
    };

    // Handle Edit button click
    const handleEdit = async () => {
        setIsLoading(true);  // Set loading state
        try {
            // Send updated content and tags to the backend using the new endpoint
            await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/edit-text/${id}`, {
                text: content,  // Update to match the expected parameter name in the backend
                tags: selectedTags,
            });
            setIsChanged(false);  // Reset isChanged after editing
            
            // Show alert and navigate back
            toast.success("Post updated successfully", {
                position: "bottom-right",
              });
            navigate(-1); // Navigate back to the previous page
            
        } catch (error) {
            console.error("Error editing post:", error);
            toast.error("Error updating post", {
                position: "bottom-right",
              });
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    if (isFetching) {
        return <div>Loading post...</div>;
    }
    console.log(content)
    console.log(selectedTags)
    return (
        <div className="flex flex-col min-h-screen pb-8 bg-black h-full text-white font-dm-sans">
            {/* Navbar */}
            <Navbar />
            
            {/* Main Content */}
            <main className="max-w-7xl mx-auto mt-20">
                <h1 className="text-5xl font-bold text-center mb-4 mt-6">
                    Edit Post Content 
                </h1>
                {isFetching ?(
                    
                    <div className="text-center mt-12 mb-8">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 animate-spin mx-auto fill-white"
                          viewBox="0 0 32 32"
                          >
                          <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                        </svg>
                      </div>
                        ): (
                            <>
                {/* Post Content Textarea */}
                <div className="md:mx-0 mx-4">
                    <div className="mt-8 sm:mx-4">
                        <h2 className="text-xl my-4"> Write post content</h2>
                        <textarea 
                            className="w-full h-28 p-4 border rounded-lg text-black" 
                            placeholder="Write your post content here..." 
                            value={content}
                            onChange={handleContentChange}
                        />
                        <p className="text-right mt-2 text-sm text-gray-500">
                            {maxLength - content.length} characters left
                        </p>
                    </div>

                    {/* Tags Selection */}
                    <div>
                        <h1 className="text-xl my-4">Select Tags</h1>
                        <div className="flex flex-wrap gap-2">
                            {availableTags.map((tag) => (
                                <button
                                    key={tag}
                                    className={`${
                                        selectedTags.includes(tag)
                                            ? "bg-[#493676] text-white"
                                            : "bg-gray-200 text-gray-800"
                                    } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
                                    onClick={() => handleTagClick(tag)}
                                    disabled={
                                        selectedTags.length >= 3 && !selectedTags.includes(tag)
                                    }
                                >
                                    {tag}
                                </button>
                            ))}
                        </div>
                        {selectedTags.length === 3 && (
                            <p className="text-red-500 text-sm mt-2">
                                You can select up to 3 tags only.
                            </p>
                        )}
                    </div>
                </div>

                {/* Edit Button */}
                <div className="flex flex-col items-end justify-end mt-10 mr-4 md:mr-0">
                    <button
                        className={`bg-[#493676] text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg shadow-lg text-base sm:text-lg ${!isChanged ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={handleEdit}
                        disabled={!isChanged || isLoading}
                    >
                        {isLoading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 sm:w-6 sm:h-6 animate-spin mx-auto fill-white"
                                viewBox="0 0 32 32"
                            >
                                <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                            </svg>
                        ) : (
                            "Edit"
                        )}
                    </button>
                </div>
                </>
                    )}
            </main>
        </div>
    );
}
