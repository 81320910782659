import React, { useRef, useEffect } from "react";

function Test() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute("src");
      videoRef.current.load();
    }
  });
  return (
    <video ref={videoRef} width="320" height="240" controls autoPlay>
      <source
        src={`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video/3`}
        type="video/mp4"
      ></source>
      Your browser does not support the video tag.
    </video>
  );
}

export default Test;
