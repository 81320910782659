import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import TextPost from '../components/TextPost';
import ImagePost from '../components/imagePost';
import VideoPost from '../components/videoPost';
import { useNavigate } from 'react-router-dom';

const ViewContent = () => {
  const { authState } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [user, setUser] = useState(null);
  const [filters, setFilters] = useState([]); // Holds filter names (e.g., creator names)
  const [activeFilter, setActiveFilter] = useState("All"); // Default to 'All'
  const [creatorIds, setCreatorIds] = useState([]); // Hold content creator IDs
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/following/subscriptions-details/${authState.user.user_id}`, {
          headers: { Authorization: `Bearer ${authState.accessToken}` },
        });
  
        const subscriptions = response.data;
        const creatorNames = subscriptions.map((sub) => sub.name);
        const creatorIds = subscriptions.map((sub) => sub.contentCreatorId);
  
        setFilters(["All", ...creatorNames]);
        setCreatorIds(creatorIds);
      } catch (error) {
        console.error('Error fetching subscriptions with details:', error);
      }
    };
  
    if (authState.user) {
      fetchSubscriptions();
    }
  }, [authState.user, authState.accessToken]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const userId = authState.user.user_id;
        const selectedCreatorId = activeFilter !== "All" ? creatorIds[filters.indexOf(activeFilter) - 1] : null;

        const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/private`, {
          params: {
            userId,
            page,
            limit: 5,
            contentCreatorId: selectedCreatorId,
          },
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });

        setPosts((prevPosts) => [...prevPosts, ...response.data.posts]);
        setTotalPosts(response.data.totalPosts);
        setLoading(false);
        setLoadingMore(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false);
        setLoadingMore(false);
      }
    };

    if (user === undefined) return;

    if (user) {
      if (user?.user_type === 'user') {
        if (authState.paid === undefined) {
          return;
        }
        
        if (authState.paid === true) {
          fetchPosts();
        } else {
          navigate('/pricing');
        }
      } else {
        navigate('/');
      }
    }
  }, [page, user, authState, activeFilter, creatorIds, filters, navigate]);

  useEffect(() => {
    if (authState.user) {
      setUser(authState.user);
    }
    else{
      navigate('/login');
    }
  }, [authState.user]);

  const handleFilterClick = (filter) => {
    setPosts([]);
    setPage(1);
    setActiveFilter(filter);
    setLoading(true);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loadingMore
    )
      return;
    if (posts.length < totalPosts) {
      setLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [posts, loadingMore, totalPosts]);

  return (
    <div className="min-h-screen pb-8 bg-black text-white font-dm-sans">
      <Navbar />
      <main className="max-w-7xl mx-auto mt-20 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="font-bold tracking-tight text-white text-2xl sm:text-4xl lg:text-5xl mt-16">
            Discover Exclusive Content
          </h2>
          <p className="mt-6 text-sm sm:text-base lg:text-lg leading-7 sm:leading-8 text-white">
            Dive into a world of unique and exclusive videos, handpicked just
            for you. Enjoy premium content from your favorite creators, all in
            one place.
          </p>
        </div>

        <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 bg-black py-8 rounded-lg">
          {filters.map((filter) => (
            <button
              key={filter}
              onClick={() => handleFilterClick(filter)}
              className={`${
                activeFilter === filter ? "bg-white text-black" : "text-white"
              } px-2 sm:px-4 py-2 rounded-full font-semibold transition duration-200`}
            >
              {filter}
            </button>
          ))}
        </div>

        <div className="mt-10">
          {loading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 animate-spin mx-auto fill-white"
              viewBox="0 0 32 32"
            >
              <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
            </svg>
          ) : (
            <div className="space-y-8">
            {posts.map((post) => {
              const isPinned = activeFilter === "All" ? false : post.pinned;

              if (post.type === "text" && post.text) {
                return (
                  <TextPost
                    key={post.id}
                    contentCreator={post.ContentCreator?.name || "Unknown Creator"}
                    profilePicture={post.ContentCreator?.profilePicture || "/default-profile.png"}
                    content={post.text}
                    date={post.updatedAt}
                    postId={post.id}
                    tags={post.tags || []}
                    pinned={isPinned}
                  />
                );
              } else if (post.type === "image" && post.image) {
                return (
                  <ImagePost
                    key={post.id}
                    contentCreator={post.ContentCreator?.name || "Unknown Creator"}
                    profilePicture={post.ContentCreator?.profilePicture || "/default-profile.png"}
                    caption={post.text || "No caption provided"}
                    imageSrc={post.image}
                    date={post.updatedAt}
                    tags={post.tags || []}
                    postId={post.id}
                    pinned={isPinned}
                  />
                );
              } else if (post.Video) {
                return (
                  <VideoPost
                    key={post.id}
                    contentCreator={post.ContentCreator?.name || "Unknown Creator"}
                    profilePicture={post.ContentCreator?.profilePicture || "/default-profile.png"}
                    caption={post.Video.title || "Untitled Video"}
                    thumbnailSrc={post.Video.thumbnail || "/default-thumbnail.png"}
                    videoId={post.Video.id}
                    date={post.updatedAt}
                    tags={post.tags || []}
                    pinned={isPinned}
                  />
                );
              }

              return null;
            })}
              {loadingMore && (
                <div className="text-center mt-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 animate-spin mx-auto fill-white"
                    viewBox="0 0 32 32"
                  >
                    <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                  </svg>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ViewContent;
