import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    CenteredTextContainer: {
        justifyContent: 'center',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    },
    LateralSplitContainer: {
        height: '100vh',
        alignItems: 'center',
        display: 'flex'
    },
    splitSingleContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        textAlign: 'center',
        flexDirection: 'column',
        opacity: 0,
        transition: 'opacity 3s ease-in', // Adjust the transition duration and timing function as needed
    },
    fadeIn: {
        opacity: 1,
    },
    overlayContainer: {
        position: 'relative',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
    },
    backgroundImage: {
        opacity: 0.5,
        height: '100vh',
        width: '100%',
        objectFit: 'cover',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    centeredText: {
        position: 'relative',
        zIndex: 1,
    },
    footer: {
        background: 'black',
        color: 'white',
        padding: '50px',
        paddingTop: 30,
        marginTop: 50,
        fontFamily: 'raleway'
    },
    footerText: {
        color: 'inherit',
        fontFamily: 'inherit',
    },
    ceneredContentDiv: {
        justifyContent: 'center',
        alignItems: 'ceneter',
        display: 'flex',
        flexDirection: 'column'
    },
    appFeatureDesc: {
        position: 'relative', 
        // border: '1px solid white', 
        borderRadius: 20, 
        left: '15%', 
        maxWidth: '33%',
        padding: 20
        
    }
}));

export default useStyles;