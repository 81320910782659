import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Google from "../assets/google.png";
import { Link } from "react-router-dom";

const Signup = () => {
  const { authState, register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userType, setUserType] = useState("user");
  const [email, setEmail] = useState("");
  const [emailChecked, setEmailChecked] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [detailsChecked, setDetailsChecked] = useState(false);
  const [dob, setDob] = useState({
    day: "1",
    month: "Jan",
    year: `${new Date().getFullYear()}`,
  });
  const [gender, setGender] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (authState.user) {
      // navigate("/home"); // Redirect to home page if user is already logged in
      //Redirect to the previous page if user is already logged in
      navigate(-1);
    }
  }, [authState.user, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
    console.log(email);
    setErrorMessage("");
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCheckEmail = async () => {
    if (!email) {
      setErrorMessage("Enter a valid email.");
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage("Enter a valid email format.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/check-email`,
        { email },
      );
      if (response.data.exists) {
        setErrorMessage("Email already exists.");
      } else {
        setEmailChecked(true);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 404) {
        setEmailChecked(true);
      } else {
        setErrorMessage("An error occurred while checking the email.");
      }
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleDetailsSubmit = (e) => {
    e.preventDefault();
    setDetailsChecked(true);
  };

  const handleDobChange = (e) => {
    const { id, value } = e.target;
    setDob({ ...dob, [id]: value });
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (gender === "") {
      setErrorMessage("Please enter your gender.");
      return;
    }

    e.preventDefault();
    const Dob = `${dob.year}-${dob.month}-${dob.day}`;
    try {
      await register(name, email, password, userType, Dob, gender, navigate);
      //   navigate("/login");
    } catch (error) {
      console.error(error);
      setErrorMessage("Signup failed.");
    }
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#131313] font-roboto text-white">
      <div className="w-full max-w-md p-8 space-y-6">
        <div className="text-center">
          <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
          <h2 className="text-2xl font-bold font-dm-sans">
            Sign Up to Unindulgent
          </h2>
        </div>
        <div className="space-y-4">
          {!emailChecked && (
            <>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={handleEmailChange}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-[#493676] focus:border-[#493676] sm:text-sm"
                  placeholder="Email"
                />
                {errorMessage && (
                  <div className="text-red-500 text-sm mt-2">
                    {errorMessage}
                  </div>
                )}
                <div className="flex items-center justify-center my-4">
                  <span className="text-white font-semibold">or</span>
                </div>
                <button
                  onClick={() =>
                    (window.location.assign =
                      `${process.env.REACT_APP_SERVER_ADDRESS}/api/auth/google`)
                  }
                  className="w-full flex items-center justify-center py-2 px-4 border border-border rounded-md shadow-sm text-sm font-medium bg-white text-gray-500 hover:bg-white/90"
                >
                  <img src={Google} alt="Google logo" className="mr-4 w-6" />
                  <p className="mt-0.5">Continue with Google</p>
                </button>
                <div className="w-full flex justify-center mt-4">
                  <button
                    onClick={handleCheckEmail}
                    className="py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </>
          )}
          {emailChecked && !detailsChecked && (
            <>
              <div>
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  value={name}
                  onChange={handleNameChange}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-[#493676] focus:border-[#493676] sm:text-sm"
                  placeholder="Name"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-[#493676] focus:border-[#493676] sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div className="w-full flex justify-center mt-4">
                <button
                  onClick={handleDetailsSubmit}
                  className="py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80"
                >
                  Continue
                </button>
              </div>
            </>
          )}
          {detailsChecked && (
            <>
              <div className="mb-4">
                <label htmlFor="dob" className="block text-sm font-medium mb-1">
                  Date of birth <span className="text-muted-foreground">ⓘ</span>
                </label>
                <div className="flex space-x-2">
                  <select
                    id="day"
                    onChange={handleDobChange}
                    className="bg-[#131313] border border-border text-white rounded-md p-2 w-1/3 max-h-40 overflow-y-auto"
                  >
                    {Array.from({ length: 31 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  <select
                    id="month"
                    onChange={handleDobChange}
                    className="bg-[#131313] border border-border text-white rounded-md p-2 w-1/3 max-h-40 overflow-y-auto"
                  >
                    {[
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ].map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select
                    id="year"
                    onChange={handleDobChange}
                    className="bg-[#131313] border border-border text-white rounded-md p-2 w-1/3 max-h-40 overflow-y-auto"
                  >
                    {Array.from({ length: 50 }, (_, i) => (
                      <option
                        key={new Date().getFullYear() - i}
                        value={new Date().getFullYear() - i}
                      >
                        {new Date().getFullYear() - i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium mb-1"
                >
                  Gender <span className="text-muted-foreground">ⓘ</span>
                </label>
                <div className="flex space-x-2">
                  <label className="flex items-center bg-input border border-border text-foreground rounded-md p-2 w-1/3 cursor-pointer accent-[#7055ad]">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      className="form-radio text-primary mr-2"
                      onChange={handleGenderChange}
                    />
                    Female
                  </label>
                  <label className="flex items-center bg-input border border-border text-foreground rounded-md p-2 w-1/3 cursor-pointer accent-[#7055ad]">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      className="form-radio text-primary mr-2"
                      onChange={handleGenderChange}
                    />
                    Male
                  </label>
                  <label className="flex items-center bg-input border border-border text-foreground rounded-md p-2 w-1/3 cursor-pointer accent-[#7055ad]">
                    <input
                      type="radio"
                      name="gender"
                      value="custom"
                      className="form-radio text-primary mr-2"
                      onChange={handleGenderChange}
                    />
                    Custom
                  </label>
                </div>
              </div>
              {errorMessage && (
                <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
              )}
              <div className="w-full flex justify-center mt-4">
                <button
                  onClick={handleSubmit}
                  className="py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80"
                >
                  Sign up
                </button>
              </div>
            </>
          )}
        </div>
        <div className="text-center">
          <p className="text-sm text-muted-foreground">
            Already registered on Unindulgent?{" "}
            <Link
              to="/login"
              className="text-sm text-muted-foreground hover:underline"
            >
              Log in
            </Link>
          </p>
        </div>
        <div className="text-center text-xs text-muted-foreground mt-4">
          By signing up, you are creating a Unindulgent account and agree to
          Unindulgent's{" "}
          <Link to="#" className="underline">
            Terms
          </Link>{" "}
          and{" "}
          <Link to="#" className="underline">
            Privacy Policy
          </Link>
          .
        </div>
      </div>
      {/* <div className="absolute bottom-0 right-0 mb-4 mr-4">
        <Link
          to="/register-creator"
          className="text-sm text-[#614a97] hover:underline"
        >
          Signing up on Unindulgent as a Content Creator?
        </Link>
      </div> */}
    </div>
  );
};

export default Signup;
