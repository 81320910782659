import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Logo from "../assets/logo.png";
import { AuthContext } from "../context/AuthContext";

const MainSidebar = () => {
  const { authState, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  // Check if the user is a creator
  const user = authState?.user;
  const isCreator = user?.user_type === "creator";

  // Slide in/out animation using framer-motion
  const sidebarVariants = {
    open: { x: 0, opacity: 1 },
    closed: { x: "-100%", opacity: 0 },
  };

  if (!isCreator) {
    return null; // Don't render sidebar if the user isn't a creator
  }

  return (
    <>
      {/* Sidebar Menu Button */}
      <div className="fixed top-5 left-5 z-50">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="focus:outline-none"
        >
          <img src={Logo} className="w-10" alt="hamburger-menu" />
        </button>
      </div>

      {/* Sidebar */}
      <motion.div
        className="fixed top-0 left-0 h-full bg-[#1E1E1E] text-white w-80 p-6 z-40 shadow-xl flex flex-col"
        initial="closed"
        animate={isSidebarOpen ? "open" : "closed"}
        variants={sidebarVariants}
        transition={{ duration: 0.5 }}
      >
        {/* Logo and Close Button */}
        {/* <div className="flex items-center justify-between mb-6">
          <img src={Logo} alt="logo" className="w-10" />
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="text-white text-2xl focus:outline-none"
          >
            ✕
          </button>
        </div> */}

        {/* Sidebar Links for creators */}
        <motion.div
          className="grid grid-cols-1 gap-6 mt-20"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Link to="/">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-[#252525] text-white p-4 rounded-lg shadow-md hover:bg-[#343434] transition duration-300 ease-in-out"
            >
              Home
            </motion.div>
          </Link>

          <Link to="/post-video">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-[#252525] text-white p-4 rounded-lg shadow-md hover:bg-[#343434] transition duration-300 ease-in-out"
            >
              For You Post
            </motion.div>
          </Link>

          <Link to="/post-content">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-[#252525] text-white p-4 rounded-lg shadow-md hover:bg-[#343434] transition duration-300 ease-in-out"
            >
              For Subscribers Post
            </motion.div>
          </Link>

          <Link to={`/dashboard/${authState.user.user_id}`}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-[#252525] text-white p-4 rounded-lg shadow-md hover:bg-[#343434] transition duration-300 ease-in-out"
            >
              Dashboard
            </motion.div>
          </Link>

          <Link to="/chat">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-[#252525] text-white p-4 rounded-lg shadow-md hover:bg-[#343434] transition duration-300 ease-in-out"
            >
              Chat
            </motion.div>
          </Link>
        </motion.div>

        {/* Logout Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-auto"
        >
          <button
            onClick={handleLogout}
            className="w-full bg-transparent border border-white text-white py-3 rounded-lg hover:bg-white hover:text-[#1E1E1E] transition duration-300 ease-in-out"
          >
            Logout
          </button>
        </motion.div>
      </motion.div>

      {/* Overlay to close sidebar when clicked outside */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </>
  );
};

export default MainSidebar;
