import React from "react";
import { useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Default from "../assets/default.jpg";
import { AuthContext } from "../context/AuthContext";
import TextPost from "../components/TextPost";
import ImagePost from "../components/imagePost";
import VideoPost from "../components/videoPost";
import axios from "axios";
import { useEffect, useContext, useCallback } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { FaThumbtack } from 'react-icons/fa';
import MainSidebar from "../components/MainSidebar";

import { FaVolumeUp } from "react-icons/fa";
import { toast } from "react-toastify";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Utils,
} from "chart.js";

export default function Creator() {
  const [activeTab, setActiveTab] = useState("Video");
  const { authState } = useContext(AuthContext);
  const [allPosts, setAllPosts] = useState([]);
  const [publicPosts, setPublicPosts] = useState([]);
  const [user, setUser] = useState(null);
  const [allPage, setAllPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [subscribedPage, setSubscribedPage] = useState(1);
  const [publicPage, setPublicPage] = useState(1);
  const [allTotalPosts, setAllTotalPosts] = useState(0);
  const [publicTotalPosts, setPublicTotalPosts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false); // For loading more posts
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const [isFetchingPublic, setIsFetchingPublic] = useState(false);
  const [activeContentType, setActiveContentType] = useState("POST");  
  const [subscribedPosts, setSubscribedPosts] = useState([]);
  const [subscribedTotalPosts, setSubscribedTotalPosts] = useState(0);
  const [isFetchingSubscribed, setIsFetchingSubscribed] = useState(false);
  const [videoIDforAnalytics, setVideoIDforAnalytics] = useState(null);
  const [analyticsFlag, setAnalyticsFlag] = useState(false);
  const [chartData, setChartData] = useState({})
  const [views,setViews] = useState(0)

  const navigate = useNavigate();

  const TabNavigation = ({ activeTab, setActiveTab }) => {
    const tabs = ["Video", "Image", "Text"];

    return (
      <div className="flex flex-wrap mx-4 sm:mx-10 md:mx-20 lg:mx-32 xl:mx-48 justify-center border-b border-gray-700">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`py-2 px-6 sm:px-10 md:px-12 lg:px-16 xl:px-20 text-sm sm:text-md font-medium ${
              activeTab === tab
                ? "text-white border-b-2 border-white"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => {
              setAnalyticsFlag(false);
              setActiveTab(tab);
              setAllPage(1);
            }}
          >
            {tab}
          </button>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        console.log("Fetching Posts...");
        if (activeTab) {
          console.log(activeTab);
          if (isFetchingAll) {
            console.log("All posts already fetched or currently fetching");
            return; // All posts already fetched or currently fetching
          }
          setLoading(true); // Show loading indicator

          setIsFetchingAll(true); // Set fetching state
          console.log(allPage);

          // Fetch posts for "All" tab
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_ADDRESS}/api/content/posts/${user.user_id}`,
            {
              params: {
                page: allPage,
                limit: 10,
                type: activeTab.toLowerCase(),
              },
              headers: {
                Authorization: `Bearer ${authState.accessToken}`,
              },
            },
          );
          console.log(response.data.posts);
          setAllPosts((prevPosts) => {
            const newPosts = response.data.posts.filter(
              (newPost) =>
                !prevPosts.some(
                  (post) =>
                    post.id === newPost.id && post.type === newPost.type,
                ),
            );
            return [ ...newPosts];
          });
          console.log(response.data.posts);
          setTotalPages(response.data.totalPages); // Set total pages count
          setAllTotalPosts(response.data.totalPosts); // Set total posts count
          setIsFetchingAll(false); // Reset fetching state
        }

        setLoading(false);
        setLoadingMore(false); // Stop loading more animation
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
        setLoadingMore(false); // Stop loading more animation
        setIsFetchingAll(false); // Ensure fetch state reset on error
        setIsFetchingPublic(false); // Ensure fetch state reset on error
      }
    };

    if (user) {
      if (user.user_type === "creator") {
        fetchPosts();
      } else {
        navigate("/");
      }
    }
  }, [
    allPage,
    publicPage,
    user,
    authState,
    navigate,
    activeTab,
    activeContentType,
    subscribedPage,
  ]);

  useEffect(() => {
    if (authState.user) {
      setUser(authState.user);
    }
  }, [authState.user]);

  // Example function to fetch views for a specific video

  // Infinite scroll handler
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loadingMore
    )
      return;
    if (activeTab === "All" && allPosts.length < allTotalPosts) {
      setLoadingMore(true);
      setAllPage((prevPage) => prevPage + 1);
    }
    if (activeTab === "For You" && publicPosts.length < publicTotalPosts) {
      setLoadingMore(true);
      setPublicPage((prevPage) => prevPage + 1);
    }
    if (
      activeTab === "Subscribed" &&
      subscribedPosts.length < subscribedTotalPosts
    ) {
      setLoadingMore(true);
      setSubscribedPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    allPosts,
    loadingMore,
    allTotalPosts,
    publicPosts,
    publicTotalPosts,
    subscribedPosts,
    subscribedTotalPosts,
  ]);

  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
  );

  const fetchVideoViews = async (videoId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/view/video/${videoId}`);
      if (response.status === 200) {
        setChartData(response.data)  // Assuming the API returns views count
        setAnalyticsFlag(true);
      }
    } catch (error) {
      console.error("Error fetching video views", error);
      return 0; // Default to 0 in case of error
    }
  };
  
  

  const LineChart = ({chartData,views}) => {

    const options = {
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: `Total Views ${views}`,
        },
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
        },
      },
    };

    return <Line data={chartData} options={options} />;
  };

  const [playing, setPlaying] = useState(false); // State to manage video play/pause
  const videoRef = useRef(null); // Ref to access video element

  // Play or pause the video
  const playOrPause = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      if (video.paused || video.ended) {
        video.play();
        setPlaying(true);
      } else {
        video.pause();
        setPlaying(false);
      }
    }
  }, []);

  // Automatically pause the video after it starts
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setPlaying(false);
    }
  }, []);

  return (
    <div className="bg-black text-white font-dm-sans min-h-screen pb-8">
    <MainSidebar />
    <main className="mx-4 sm:mx-8 md:mx-20 lg:mx-32 xl:mx-40 pt-20 mb-8">
      <div className="mx-auto max-w-xl md:max-w-2xl lg:max-w-3xl text-center mb-12">
        <h2 className="font-bold tracking-tight text-white text-3xl sm:text-4xl md:text-5xl mt-8 sm:mt-12">
          Analyze Your Content Performance
        </h2>
        <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-white">
          Gain insights into how your content is performing with in-depth
          analytics. Track views, engagement, and audience growth, and
          optimize your content strategy with data-driven decisions.
        </p>
      </div>
  
      <div>
        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
  
        <div className="mt-4">
          {/* Conditionally render content based on active tab */}
  
          {analyticsFlag ? (
            <>
              <div
                onClick={() => {
                  setAnalyticsFlag(false);
                }}
                className="flex items-center cursor-pointer text-white text-sm gap-2 mt-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1="21" x2="9" y1="12" y2="12" />
                </svg>
                Go back
              </div>
              <div className="mt-8 flex flex-col md:flex-row justify-center items-center ">
                <div className="w-full md:w-1/2 h-[450px] sm:h-[440px] bg-black rounded-lg ">
                  <LineChart chartData={chartData} views={views} />
                </div>
  
                <div className="border border-gray-500 rounded-lg overflow-hidden w-full sm:w-[300px] md:w-[380px] h-[684px] relative">
                  <div
                    className="relative w-full h-full"
                    onClick={playOrPause}
                  >
                    <video
                      ref={videoRef}
                      className="absolute inset-0 w-full h-full object-cover"
                      style={{ objectFit: "cover" }}
                      src={`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video/${videoIDforAnalytics}`}
                    />
                    {!playing && (
                      <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                        <FaPlay className="w-12 h-12 sm:w-16 sm:h-16 text-white opacity-90" />
                      </div>
                    )}
                    {playing && (
                      <div className="absolute bottom-0 right-0 m-4">
                        <button
                          onClick={playOrPause}
                          className="text-white text-xl sm:text-2xl bg-black bg-opacity-50 p-2 rounded-full"
                        >
                          <FaVolumeUp />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="mt-10">
              {loading ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 animate-spin mx-auto fill-white"
                  viewBox="0 0 32 32"
                >
                  <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                </svg>
              ) : (
                <div>
                  <PostTable
                    allPosts={allPosts}
                    setAllPosts={setAllPosts}
                    setVideoID={setVideoIDforAnalytics}
                    setAnalyticsFlag={setAnalyticsFlag}
                    activeTab={activeTab}
                    fetchVideoViews={fetchVideoViews}
                    setViews={setViews}
                  />
                  <PaginationControls
                    currentPage={allPage}
                    totalPages={totalPages}
                    totalPosts={allTotalPosts}
                    postsPerPage={10}
                    onPageChange={setAllPage}
                  />
  
                  {loadingMore && (
                    <div className="text-center mt-4 mb-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 animate-spin mx-auto fill-white"
                        viewBox="0 0 32 32"
                      >
                        <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  </div>
  
  );
}

const PaginationControls = ({
  currentPage,
  totalPosts,
  postsPerPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
<div className="flex flex-col sm:flex-row justify-center items-center mt-4 sm:mt-8 space-y-2 sm:space-y-0">
  <button
    onClick={handlePrevPage}
    disabled={currentPage === 1}
    className={`px-3 py-1 sm:px-4 sm:py-2 mx-1 sm:mx-2 border rounded ${
      currentPage === 1 ? "text-gray-500" : "text-white"
    }`}
  >
    Previous
  </button>
  <span className="text-white text-sm sm:text-base px-2 sm:px-4 py-1 sm:py-2">
    Page {currentPage} of {totalPages}
  </span>
  <button
    onClick={handleNextPage}
    disabled={currentPage === totalPages}
    className={`px-3 py-1 sm:px-4 sm:py-2 mx-1 sm:mx-2 border rounded ${
      currentPage === totalPages ? "text-gray-500" : "text-white"
    }`}
  >
    Next
  </button>
</div>

  );
};

const tableHeaderClasses =
  "px-4 sm:px-6 py-2 sm:py-3 text-left text-xs sm:text-sm font-medium uppercase tracking-wider";
const tableCellClasses = "px-4 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm";
const imageClasses = "h-8 w-8 sm:h-12 sm:w-12 rounded-md mr-2 object-cover";


function ImageRow({ id, thumbnailText, thumbnailSrc, date, likes, visibility, isPinned, removeVideo,setAllPosts }) {
  const tableCellClasses = "px-3 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-xs sm:text-sm";
const imageClasses = "h-8 w-8 sm:h-10 sm:w-10 rounded-lg mr-2 object-cover";

  const [loading, setLoading] = useState(false); // Loading state

  const togglePinned = async () => {
    try {
      setLoading(true);

      // Make the API call to toggle the pinned status
      const response = await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/toggle-pinned`, {
        type: "image", // Assuming this is the type for text posts
        id,
      });

      setAllPosts((prevPosts) =>
        prevPosts.map((post) => ({
          ...post,
          pinned: post.id === id ? !post.pinned : false,
        }))
      );

      console.log(response.data.message);
    } catch (error) {
      console.error("Error toggling pinned status:", error);
    } finally {
      setLoading(false); // Ensure loading is turned off
    }
  };
  console.log({ thumbnailText, thumbnailSrc, date, likes, visibility });
  const navigate = useNavigate()

  const deletePost = async (postId) => {
    // Confirm deletion
    const confirmed = window.confirm("Are you sure you want to delete this post?");
    if (!confirmed) return;
  
    try {
      // Make DELETE request to the API
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/delete-post/${postId}`);
      if (response.status === 200) {
        toast.success("Post deleted successfully", {
          position: "bottom-right",
        });
        removeVideo(postId)
        // Optionally, you can call a function to refresh the post list or update state here
        // fetchPosts(); // Uncomment if you want to refresh posts after deletion
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      alert("Failed to delete post. Please try again.");
    }
  };


  return (
    <tr>
    <td className={`${tableCellClasses} max-w-[12rem] sm:max-w-none`}>
      <div className="flex items-center">
        <img src={thumbnailSrc} alt="" className={imageClasses} />
        <span className="truncate w-32 sm:w-[40rem]">{thumbnailText}</span>
      </div>
    </td>
    <td className={`${tableCellClasses} text-xs sm:text-sm`}>
      <div className="flex gap-1 sm:gap-2 items-center">
        <PrivateSVG />
        {visibility}
      </div>
    </td>
    <td className={`${tableCellClasses} text-xs sm:text-sm`}>{date}</td>
    <td className={`${tableCellClasses} text-xs sm:text-sm`}>{likes}</td>
    <td className={`${tableCellClasses}`}>
      <button
        className={`flex items-center text-gray-400 hover:text-white duration-200 ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={togglePinned}
        disabled={loading}
      >
        <FaThumbtack
          className={`w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 mr-1 ${isPinned ? 'text-yellow-500' : 'text-gray-400'}`}
        />
      </button>
    </td>
    <td className={`${tableCellClasses} text-xs sm:text-sm`}>
      <div className="flex gap-1 sm:gap-2 items-center">
        {/* Edit Button */}
        <button
          onClick={() => navigate(`/edit-images/${id}`)}
          className="border-2 border-white text-white px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm"
        >
          Edit
        </button>
        {/* Delete Button */}
        <button
          onClick={() => deletePost(id)}
          className="bg-red-500 text-white px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm"
        >
          Delete
        </button>
      </div>
    </td>
  </tr>
  
  );
}

function TextRow({ id, textContent, date, likes, visibility, isPinned, removeVideo,setAllPosts }) {
  const tableCellClasses = "px-6 py-4 whitespace-nowrap sm:px-4 md:px-6";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  const togglePinned = async () => {
    try {
      setLoading(true);

      // Make the API call to toggle the pinned status
      const response = await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/toggle-pinned`, {
        type: "text", // Assuming this is the type for text posts
        id,
      });

     setAllPosts((prevPosts) =>
        prevPosts.map((post) => ({
          ...post,
          pinned: post.id === id ? !post.pinned : false,
        }))
      );

      console.log(response.data.message);
    } catch (error) {
      console.error("Error toggling pinned status:", error);
    } finally {
      setLoading(false); // Ensure loading is turned off
    }
  };

  const deletePost = async (postId) => {
    // Confirm deletion
    const confirmed = window.confirm("Are you sure you want to delete this post?");
    if (!confirmed) return;
  
    try {
      // Make DELETE request to the API
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/delete-post/${postId}`);
      if (response.status === 200) {
        toast.success("Post deleted successfully", {
          position: "bottom-right",
        });
        removeVideo(postId)
        // Optionally, you can call a function to refresh the post list or update state here
        // fetchPosts(); // Uncomment if you want to refresh posts after deletion
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      alert("Failed to delete post. Please try again.");
    }
  };

  return (
    <tr>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <span className="truncate w-[40rem]">{textContent}</span>
    </td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <div
        style={{
          display: "flex",
          gap: ".5rem",
        }}
      >
        <PrivateSVG />
        {visibility}
      </div>
    </td>
  
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>{date}</td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>{likes}</td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <button
        className={`flex items-center text-gray-400 hover:text-white duration-200 ${
          loading ? "cursor-not-allowed opacity-50" : ""
        }`}
        onClick={togglePinned}
        disabled={loading}
      >
        <FaThumbtack
          className={`w-4 h-4 md:w-5 md:h-5 mr-1 ${
            isPinned ? "text-yellow-500" : "text-gray-400"
          }`}
        />
      </button>
    </td>
  
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <div style={{ display: "flex", gap: ".5rem" }}>
        {/* Edit Button */}
        <button
          onClick={() => navigate(`/edit-text/${id}`)}
          className="border-2 border-white text-white px-3 py-1 rounded"
        >
          Edit
        </button>
  
        {/* Delete Button */}
        <button
          onClick={() => deletePost(id)} // Function to handle deletion
          className="bg-red-500 text-white px-3 py-1 rounded"
        >
          Delete
        </button>
      </div>
    </td>
  </tr>
  
  );
}

const PostTable = ({ allPosts, setAllPosts, setVideoID, setAnalyticsFlag, activeTab, fetchVideoViews,setViews }) => {
  function convertDate(dateStr) {
    const dateObj = new Date(dateStr);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = dateObj.getUTCDate();
    const month = monthNames[dateObj.getUTCMonth()];
    const year = dateObj.getUTCFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  const removeVideoFromList = (id) => {
    setAllPosts((prevPosts) => prevPosts.filter(post => post.id !== id));
  };


  useEffect(() => {
    console.log(allPosts);
  }, []);
  return (
    <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-border">
      <thead className="bg-background text-foreground">
        <tr>
          <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>
            {
              {
                Video: "Video",
                Image: "Image",
                Text: "Text",
              }[activeTab]
            }
          </th>
          <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>Visibility</th>
          <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>Date</th>
  
          {activeTab === "Video" && (
            <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>Views</th>
          )}
          {activeTab === "Video" && (
            <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>View Time</th>
          )}
          <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>Likes</th>
          {activeTab === "Video" && (
            <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}></th>
          )}
          {activeTab === "Video" && (
            <th className={`${tableHeaderClasses} sm:px-4 md:px-6`}>Analytics</th>
          )}
        </tr>
      </thead>
      <tbody className="bg-card divide-y divide-border">
        {allPosts
          .filter((post) => post.type === activeTab.toLowerCase())
          .map(
            (post) =>
              ({
                Video: (
                  <VideoRow
                    id={post.id}
                    key={post.id}
                    thumbnailText={post.text}
                    thumbnailSrc={post.thumbnail}
                    date={convertDate(post.updatedAt)}
                    likes={post.likes}
                    setVideoID={setVideoID}
                    views={post.views}
                    viewTime={post.viewTime}
                    setAnalyticsFlag={setAnalyticsFlag}
                    visibility={
                      post.visibility.charAt(0).toUpperCase() +
                      post.visibility.slice(1)
                    }
                    fetchVideoViews={fetchVideoViews}
                    setViews={setViews}
                    isPinned={post.pinned}
                    removeVideo={removeVideoFromList}
                    setAllPosts={setAllPosts}
                  />
                ),
                Image: (
                  <ImageRow
                    id={post.id}
                    key={post.id}
                    thumbnailText={post.text}
                    thumbnailSrc={post.image}
                    date={convertDate(post.updatedAt)}
                    likes={post.likes}
                    visibility="Private"
                    isPinned={post.pinned}
                    removeVideo={removeVideoFromList}
                    setAllPosts={setAllPosts}
                  />
                ),
                Text: (
                  <TextRow
                    id={post.id}
                    key={post.id}
                    textContent={post.text}
                    date={convertDate(post.updatedAt)}
                    likes={post.likes}
                    visibility="Private"
                    isPinned={post.pinned}
                    removeVideo={removeVideoFromList}
                    setAllPosts={setAllPosts}
                  />
                ),
              })[activeTab]
          )}
      </tbody>
    </table>
  </div>
  
  );
};

const VideoRow = ({
  id,
  thumbnailText,
  visibility,
  restrictions,
  date,
  views,
  viewTime,
  comments,
  likes,
  thumbnailSrc,
  setVideoID,
  setAnalyticsFlag,
  fetchVideoViews,
  setViews,
  isPinned,
  removeVideo,
  setAllPosts
}) => {

  const [isDeleted, setIsDeleted] = useState(false)
  const [loading, setLoading] = useState(false); // Loading state

  const togglePinned = async () => {
    try {
      setLoading(true)

      // Make the API call to toggle the pinned status
      const response = await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/toggle-pinned`, {
        type: "video", // Assuming this is the type for text posts
        id,
      });

          // Update allPosts state to unpin all posts, except the current one
      setAllPosts((prevPosts) =>
        prevPosts.map((post) => ({
          ...post,
          pinned: post.id === id ? !post.pinned : false,
        }))
      );

      console.log(response.data.message);
    } catch (error) {
      console.error("Error toggling pinned status:", error);
    } finally {
      setLoading(false); // Ensure loading is turned off
    }
  };
  const navigate = useNavigate();
  const deleteVideo = async (videoId) => {
    // Confirm deletion
    const confirmed = window.confirm("Are you sure you want to delete this video?");
    if (!confirmed) return;

    try {
      // Make DELETE request to the API
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/delete-video/${videoId}`);
      if (response.status === 200) {
        toast.success("Video deleted successfully", {
          position: "bottom-right",
        });
        removeVideo(videoId);
        setIsDeleted(true)
        // Optionally, you can call a function to refresh the video list or update state here
        // fetchVideoViews(); // Uncomment if you want to refresh views after deletion
      }
    } catch (error) {
      console.error("Error deleting video:", error);
      alert("Failed to delete video. Please try again.");
    }
  };

  function formatTime(seconds) {
    if (seconds < 60) {
      return `${seconds} sec${seconds === 1 ? '' : ''}`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min${minutes === 1 ? '' : 's'}${remainingSeconds > 0 ? ` and ${remainingSeconds} sec${remainingSeconds === 1 ? '' : ''}` : ''}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours} hr${hours === 1 ? '' : ''}${remainingMinutes > 0 ? `, ${remainingMinutes} min${remainingMinutes === 1 ? '' : ''}` : ''}${remainingSeconds > 0 ? ` and ${remainingSeconds} sec${remainingSeconds === 1 ? '' : ''}` : ''}`;
    }
  }
  

  return (
    <tr>
    <td className={`${tableCellClasses} ${isDeleted ? 'none' : ''} sm:px-4 md:px-6`}>
      <div className="flex items-center ">
        <img
          src={thumbnailSrc}
          alt="Video Thumbnail"
          className={imageClasses}
        />
        <span className="truncate w-32 sm:w-[40rem]">{thumbnailText}</span>
      </div>
    </td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <div style={{ display: "flex", gap: ".5rem" }}>
        {visibility === "Private" ? <PrivateSVG /> : <PublicSVG />}
        {visibility}
      </div>
    </td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>{date}</td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>{views}</td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>{formatTime(viewTime)}</td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>{likes}</td>
    
    {visibility === "Private" ? (
      <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
        <button
          className={`flex items-center text-gray-400 hover:text-white duration-200 ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
          onClick={togglePinned}
          disabled={loading}
        >
          <FaThumbtack
            className={`w-4 h-4 md:w-5 md:h-5 mr-1 ${isPinned ? 'text-yellow-500' : 'text-gray-400'}`}
          />
        </button>
      </td>
    ) : (
      <td className={`${tableCellClasses} sm:px-4 md:px-6`}></td>
    )}
    
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <OpenAnalytics
        setViews={setViews}
        setVideoID={setVideoID}
        id={id}
        views={views}
        setAnalyticsFlag={setAnalyticsFlag}
        fetchVideoViews={fetchVideoViews}
      />
    </td>
    <td className={`${tableCellClasses} sm:px-4 md:px-6`}>
      <div style={{ display: "flex", gap: ".5rem", flexWrap: "wrap" }}>
        {/* Edit Button */}
        <button
          onClick={() => navigate(`/edit-video/${id}`)} // Function to handle editing
          className="border-2 border-white text-white px-3 py-1 rounded"
        >
          Edit
        </button>
  
        {/* Delete Button */}
        <button
          onClick={() => deleteVideo(id)} // Function to handle deletion
          className="bg-red-500 text-white px-3 py-1 rounded"
        >
          Delete
        </button>
      </div>
    </td>
  </tr>
  
  );
  
};


function PublicSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-globe"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
      <path d="M2 12h20" />
    </svg>
  );
}

function PrivateSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-lock"
    >
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
    </svg>
  );
}

function OpenAnalytics({ setVideoID, id, setAnalyticsFlag, fetchVideoViews,views,setViews }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-external-link"
      cursor={"pointer"}
      onClick={() => {
        console.log("Clicked analytics ", id);
        setVideoID(id);
        setViews(views)
        fetchVideoViews(id);

      }}
    >
      <path d="M15 3h6v6" />
      <path d="M10 14 21 3" />
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
    </svg>
  );
}
