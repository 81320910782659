// ReelsList.jsx
import React, { useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import VideoPost from './videoPostForYou'; // Adjust import based on your folder structure
import { useState, useContext } from "react";
import ReelsContext from "../context/ReelsContext";
import {
    FaHeart
  } from "react-icons/fa"; 
import Play from "../assets/play.png";
import Slider from "react-slick";
import { FaSpinner } from "react-icons/fa";
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; //
import { AuthContext } from "../context/AuthContext"; 


const ReelsList = ({ reels }) => {
    const {
      filteredReels,
      currentFilteredReelIndex = null,
      setCurrentFilteredReelIndex,
    } = useContext(ReelsContext);
  
    return (
      <div className="grid gap-8 sm:gap-6">
        {reels.map((reel, index) => (
          <VideoPost
            key={reel.id}
            profilePicture={reel.ContentCreator.profilePicture}
            contentCreator={reel.ContentCreator.name}
            index={index}
            thumbnailSrc={reel.thumbnail}
            caption={reel.title}
            videoId={reel.id}
            tags={reel.tags}
            date={reel.updatedAt}
            setCurrentFilteredReelIndex={setCurrentFilteredReelIndex}
          />
        ))}
  
        {currentFilteredReelIndex !== null && (
          <ReelViewer
            onClose={() => setCurrentFilteredReelIndex(null)}
            currentReelIndex={currentFilteredReelIndex}
            reels={reels}
            setCurrentReelIndex={setCurrentFilteredReelIndex}
          />
        )}
      </div>
    );
  };

const ReelViewer = ({
    onClose,
    currentReelIndex,
    setCurrentReelIndex,
    reels,
  }) => {
    const navigate = useNavigate();
    // const { currentReelIndex, reels, setCurrentReelIndex } = useContext(ReelsContext);
    const { formatDate } = useContext(ReelsContext);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRefs = useRef([]);
    const [loadingStates, setLoadingStates] = useState({}); // Individual loading states
    const [lineClamp, setLineClamp] = useState(true);
    const [likedReels, setLikedReels] = useState({});
    const [totalLikes, setTotalLikes] = useState({});
    const [likesLoading, setLikesLoading] = useState({});
    const { authState } = useContext(AuthContext);
    const [startTime, setStartTime] = useState(0);
    const [watchedDuration, setWatchedDuration] = useState(0);

    const sliderRef = useRef(null);

  
  
  
  
    const handleLikeClick = async (videoId, index) => {
      try {

        console.log(reels[index])


        const method = likedReels[videoId] ? "DELETE" : "POST";
        const url = likedReels[videoId]
          ? `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${videoId}/unlike`
          : `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${videoId}/like`;
  
        setLikesLoading((prev) => ({ ...prev, [videoId]: true }));
  
        const response = await fetch(url, {
          method,
          headers: {
            Authorization: `Bearer ${authState?.accessToken}`,
            "Content-Type": "application/json",
          },
        });
  
        if (response.ok) {
          setLikedReels((prev) => ({ ...prev, [videoId]: !prev[videoId] }));
          setTotalLikes((prev) => ({
            ...prev,
            [videoId]: likedReels[videoId]
              ? prev[videoId] - 1
              : prev[videoId] + 1,
          }));
        } else {
          console.error("Failed to like/unlike the video");
        }
      } catch (error) {
        console.error("Error liking/unliking video:", error);
      } finally {
        setLikesLoading((prev) => ({ ...prev, [videoId]: false }));
      }
    };
  
    const toggleLineClamp = () => {
      setLineClamp((prev) => !prev);
    };
  
    const playOrPause = useCallback((index) => {
      const video = videoRefs.current[index];
      if (video) {
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }
    }, []);
  
    const onPlay = useCallback(() => {
      setIsPlaying(true);
    }, []);
    const onPause = useCallback(() => setIsPlaying(false), []);
  
    async function registerView(videoId = null, postId = null) {
      try {
        const userId = authState?.user?.user_id;
    
        // Ensure userId is available before proceeding
        if (!userId) {
          console.log("User ID is null or undefined. Skipping view registration.");
          return;
        }
        setStartTime(Date.now());
        const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/register-view`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoId: videoId,
            postId: postId,
            userId: userId,
          }),
        });
    
        if (response.ok) {
          console.log("View registered");
        } else {
          console.log("Failed to register view");
        }
      } catch (error) {
        console.log("Error registering view:", error);
      }
    }
    
    async function registerDuration(videoId = null) {
      try {
        const userId = authState?.user?.user_id;
    
        // Ensure userId is available before proceeding
        if (!userId) {
          console.log("User ID is null or undefined. Skipping duration registration.");
          return;
        }
    
        const endTime = Date.now();
        const durationWatched = Math.floor((endTime - startTime) / 1000);
        const previousDuration = watchedDuration;
        setWatchedDuration((prevDuration) => prevDuration + durationWatched);
    
        const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/register-view-duration`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoId: videoId,
            userId: userId,
            duration: durationWatched + previousDuration,
          }),
        });
    
        if (response.ok) {
          console.log("Duration registered");
        } else {
          console.log("Failed to register duration");
        }
      } catch (error) {
        console.log("Error registering duration:", error);
      }
    }



    const topArrow = () => (
      <button
        className="bg-black text-white p-4 rounded-full flex items-center justify-center hover:bg-gray-800 border-2 border-gray-500 absolute right-4 top-[40%]"
        onClick={() => {
          if (sliderRef.current) {
            sliderRef.current.slickPrev(); // Move to the previous slide
          }
        }}
      >
        <FaArrowUp size={20} /> {/* Up arrow icon */}
      </button>
    );
  
    const botArrow = () => (
      <button
        className="bg-black text-white p-4 rounded-full flex items-center justify-center border-2 border-gray-500 hover:bg-gray-800 absolute right-4 bottom-[40%]"
        onClick={() => {
          if (sliderRef.current) {
            sliderRef.current.slickNext(); // Move to the next slide
          }
        }}
      >
        <FaArrowDown size={20} /> {/* Down arrow icon */}
      </button>
    );
    
  
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      swipeToSlide: true,
      verticalSwiping: true,
      initialSlide: currentReelIndex,
      beforeChange: (oldIndex, newIndex) => {
        setCurrentReelIndex(newIndex);
        // Pause video when changing slides
        if (videoRefs.current[oldIndex]) {
          registerDuration(reels[oldIndex]?.id);
          videoRefs.current[oldIndex].pause();
        }
      },
      afterChange: (index) => {
        // Reset playing state after changing slides
        setWatchedDuration(0);
        setIsPlaying(true);
      },
      arrows: false,
    };
  
    useEffect(() => {
      const currentVideo = videoRefs.current[currentReelIndex];
  
      if (currentVideo) {
        // Function to clean up the current video
        const cleanUpVideo = () => {
          currentVideo.pause();
          currentVideo.src = ""; // Clear the source
        };
  
        // Clean up previous video source if it exists
        cleanUpVideo();
  
        // Set new video source
        currentVideo.src = `${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video/${reels[currentReelIndex]?.id}`;
  
        // Handle video load and play
        const handleLoadedMetadata = () => {
          currentVideo.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        };
  
        // Add event listener to play video once it's loaded
        currentVideo.addEventListener("loadedmetadata", handleLoadedMetadata);
  
        // Clean up function to pause video and remove source on component unmount
        return () => {
          currentVideo.removeEventListener(
            "loadedmetadata",
            handleLoadedMetadata,
          );
          cleanUpVideo();
        };
      }
    }, [currentReelIndex, reels]);
  
      // Fetch likes data for each reel
      useEffect(() => {
        async function fetchLikeData() {
          try {
            const videoId = reels[currentReelIndex]?.id;
            const [likesResponse, hasLikedResponse] = await Promise.all([
              fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${videoId}/likes`, {
                headers: {
                  Authorization: `Bearer ${authState?.accessToken}`,
                },
              }),
              fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/like/video/${videoId}/hasLiked`, {
                headers: {
                  Authorization: `Bearer ${authState?.accessToken}`,
                },
              }),
            ]);
    
            if (likesResponse.ok) {
              const { totalLikes } = await likesResponse.json();
              setTotalLikes((prev) => ({ ...prev, [videoId]: totalLikes }));
            }
    
            if (hasLikedResponse.ok) {
              const { hasLiked } = await hasLikedResponse.json();
              setLikedReels((prev) => ({ ...prev, [videoId]: hasLiked }));
            }
          } catch (error) {
            console.error("Error fetching like data:", error);
          }
        }
    
        fetchLikeData();
      }, [currentReelIndex, reels, authState]);
    
  
    return (
<div className="fixed inset-0 bg-black z-50 flex justify-center items-center">
  <button
    onClick={(e) => {
      e.stopPropagation(); 
      const currentVideo = videoRefs.current[currentReelIndex];
      if (currentVideo && !currentVideo.paused && !currentVideo.ended) {
        registerDuration(reels[currentReelIndex]?.id);
      }
      onClose();
    }}
    className="absolute top-4 right-4 text-white text-xl z-50"
  >
    Close
  </button>

  <div className="w-full h-full">
    <Slider ref={sliderRef} {...settings}>
      {reels.map((reelItem, index) => (
        <div
          key={index}
          className="flex justify-center items-center h-screen"
        >
          <div
            className="w-[300px] h-[500px] sm:w-[350px] sm:h-[600px] md:w-[475px] md:h-[832px] relative flex justify-center items-center mx-auto rounded-xl my-6 border-2 border-gray-500 bg-black"
            onClick={() => playOrPause(index)}
          >
            {loadingStates[index] ? (
              <div className="absolute inset-0 flex justify-center items-center">
                <img
                  src={reelItem.thumbnail}
                  alt="Thumbnail"
                  className="absolute inset-0 w-full h-full rounded-xl object-cover"
                />
                <FaSpinner className="text-white animate-spin" size={32} />
              </div>
            ) : (
              <div className="w-full relative">
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  preload="metadata"
                  onCanPlay={() =>
                    setLoadingStates((prev) => ({
                      ...prev,
                      [index]: false,
                    }))
                  }
                  onPlay={() => {
                    onPlay();
                    registerView(reelItem.id, null);
                  }}
                  onPause={() => {
                    onPause();
                    registerDuration(reelItem.id);
                  }}
                  onEnded={() => {
                    registerDuration(reelItem.id);
                  }}
                  className="w-full max-h-[500px] sm:max-h-[600px] md:max-h-[821px] object-cover"
                >
                  <source
                    src={
                      currentReelIndex === index
                        ? `${process.env.REACT_APP_SERVER_ADDRESS}/api/post/video/${reelItem.id}`
                        : ""
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                  {!isPlaying && (
                    <img
                      src={Play}
                      alt="play icon"
                      className="w-[100px] sm:w-[120px] md:w-[150px] opacity-90"
                    />
                  )}
                </div>
              </div>
            )}
            <div className="absolute bottom-8 left-4 right-4 text-white">
              <div className="flex flex-col items-start">
                <div className="flex justify-between items-center w-full">
                  <img
                    src={reelItem.ContentCreator.profilePicture}
                    alt="Creator Profile"
                    className="w-10 h-10 sm:w-12 sm:h-12 rounded-full object-cover border-2 border-white cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/creator/${reelItem.ContentCreator.id}`);
                    }}
                  />
                  <div className="flex flex-col items-center mr-4">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeClick(reelItem.id, index);
                      }}
                      className={`flex items-center duration-200 ${
                        likedReels[reelItem.id] ? "text-purple-500" : "text-gray-400 hover:text-white"
                      }`}
                    >
                      <FaHeart className="w-6 h-6 sm:w-8 sm:h-8 mr-1" />
                    </button>
                    <span>{likedReels[reelItem.id] ? "Liked" : "Like"}</span>
                  </div>
                </div>
                <div>
                  <p
                    className="font-semibold cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/creator/${reelItem.ContentCreator.id}`);
                    }}
                  >
                    {reelItem.ContentCreator.name}
                  </p>
                  <p
                    className={`text-xs w-[280px] sm:w-[320px] md:w-[430px] ${
                      lineClamp ? "line-clamp-2" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLineClamp();
                    }}
                  >
                    {reelItem.title}
                  </p>
                  <p
                    className={`text-xs w-[280px] sm:w-[320px] md:w-[430px] ${
                      lineClamp ? "line-clamp-2" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLineClamp();
                    }}
                  >
                    {formatDate(reelItem.updatedAt)}
                  </p>
                  <div className="flex flex-wrap mt-4">
                    {reelItem.tags.map((tag) => (
                      <span
                        key={tag}
                        className="inline-block bg-[#493676] text-white text-xs font-medium mr-2 mb-2 px-2.5 py-0.5 rounded"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
    {topArrow()}
    {botArrow()}
  </div>
</div>


    );
  };
  

export default ReelsList;
