// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Layout from "./components/Layout";
import Layout2 from "./components/Layout2";
import HomePage from "./pages/Home"; // Example of another page
import ProfileComplete from "./pages/ProfileComplete";
import ExploreCreators from "./pages/ExploreCreators";
import CreatorInfo from "./pages/CreatorInfo";
import Admin from "./pages/Admin";
import SearchSubscriptionsPage from "./pages/SearchSubscriptions";
import SubscriptionPage from "./pages/SubscriptionPage";
import SignupCreator from "./pages/SignupCreator";
import Pricing from "./pages/Pricing";
import LandingPage from "./pages/Landing";
import ResetPasswordForm from "./pages/ResetPassword";
import ForgotPasswordForm from "./pages/ForgotPassword";
import UnderReview from "./pages/UnderReview";
import EditText from "./pages/EditText";
import EditImage from "./pages/EditImages"
import EditVideo from "./pages/EditVideo"
import EditInformation from "./pages/EditInformation";
import ForYou from "./pages/ForYou";
import PostVideo from "./pages/PostVideo";
import PostContent from "./pages/PostContent";
import { ToastContainer } from "react-toastify";
import ViewContent from "./pages/ViewContent";
import ChatPage from "./pages/Chat";
import Creator from "./pages/Creator";
import PrivacyPolicy from "./pages/Privacy";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Test from "./pages/Test";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ToastContainer />

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/complete-profile" element={<ProfileComplete />} />
          <Route path="/register-creator" element={<SignupCreator />} />
          <Route path="/under-review" element={<UnderReview />} />
          <Route path="/edit-information" element={<EditInformation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/"
            element={<LandingPage />}
          />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/pricing"
            element={
              <Layout2>
                <Pricing />
              </Layout2>
            }
          />
          {/* <Route path="/home" element={<LandingPage />} /> */}
          <Route path="/for-you" element={<ForYou />} />
          <Route path="/post-video" element={<PostVideo />} />
          <Route path="/post-content" element={<PostContent />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route
            path="/search-subscriptions"
            element={<SearchSubscriptionsPage />}
          />
          <Route path="/subscriptions" element={<SubscriptionPage />} />
          <Route path="/creator/:id" element={<CreatorInfo />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/view-content" element={<ViewContent />} />
          <Route path="/test" element={<Test />} />
          <Route path= "/dashboard/:id" element={<Creator/>} />
          <Route path= "/edit-text/:id" element={<EditText/>} />
          <Route path= "/edit-images/:id" element={<EditImage/>} />
          <Route path= "/edit-video/:id" element={<EditVideo/>} />
          {/* Add more routes here */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
