import React, { useEffect, useState, useContext, createContext } from "react";
import {
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  IconButton,
  useMediaQuery,
  Drawer,
  InputBase,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as DashboardIcon } from "../assets/dashboardIcon.svg";
import { ReactComponent as PendingRequestIcon } from "../assets/pendingRequestIcon.svg";
import { ReactComponent as SettingsIcon } from "../assets/settingsIcon.svg";
import { ReactComponent as LogoutIcon } from "../assets/logoutIcon.svg";
import DropDownIcon from "../assets/dropDownIcon.svg";
import ApprovedIcon from "../assets/approvedIcon.png";
import ProfileImage from "../assets/profile_image.png";
import ContentCreatorDetails from "../components/ContentCreatorDetails";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import axios from "axios";
import { toast } from "react-toastify";

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
});

const AdminContext = createContext();

function Admin() {
  const navigate = useNavigate();
  const { authState, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (authState.user === null) {
      setLoading(true);
    } else if (authState.user && authState.user.user_type !== "admin") {
      navigate("/"); // Redirect to home page if user is not an admin
    } else {
      setLoading(false); // Set loading to false once authState is determined and user is admin
    }
  }, [authState.user, navigate]);

  const [detailsOpened, setDetailsOpened] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedTab, setSelectedTab] = useState("pending");

  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
    navigate("/login"); // Redirect to login page after logout
  };

  const handleRowClick = (creator) => {
    setSelectedCreator(creator);
    setDetailsOpened(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <AdminContext.Provider value={{ selectedTab, setSelectedTab }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
            minHeight: "100vh",
            overflowY: "scroll",
            backgroundColor: "black",
          }}
        >
          <Sidebar
            setDetailsOpened={setDetailsOpened}
            setSelectedCreator={setSelectedCreator}
            handleLogout={handleLogout}
          />
          <Box
          sx={{
            width: "83%",
            height: "100%",
            backgroundColor: "black",
            marginLeft: {
              xs: 0, // marginLeft 0 for screens <= 1025px (xs breakpoint)
              md: "17%", // marginLeft 17% for screens > 1025px (sm breakpoint and above)
            },
            marginX:{
              xs:"auto",
            },
            marginTop:{
              xs:"3rem",

            }
          }}
        >
            {!detailsOpened ? (
              <>
<Box
  sx={{
    width: "100%",
    height: "17%",
    backgroundColor: "black",
    display: "flex",
    paddingLeft: {
      xs: "0", // Remove left padding on small screens (xs)
      md: "2rem", // Keep the padding on medium screens and above
    },
    justifyContent: {
      xs: "center", // Center content horizontally on small screens
      md: "flex-start", // Align to the left on medium screens and above
    },
    alignItems: "center", // Center vertically
  }}
>
  <Typography
    sx={{
      fontSize: {
        xs:"28px",
        sm:"36px"
      },
      fontWeight: "700",
      color: "white",
      marginTop: "2rem",
      fontFamily: "DM Sans",
    }}
  >
    {
      {
        pending: "Pending Requests",
        approved: "Approved Requests",
        monthly: "Monthly View Time",
        set: "Set Daily Quote",
      }[selectedTab]
    }
  </Typography>
</Box>
                <Table
                  setSelectedCreator={setSelectedCreator}
                  setDetailsOpened={setDetailsOpened}
                  handleRowClick={handleRowClick}
                />
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    height: "17%",
                    backgroundColor: "black",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "2rem",
                  }}
                >
                  {/* Add Breadcrumbs Here */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.7rem",
                      marginTop: "3rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#667085",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDetailsOpened(false);
                      }}
                    >
                      {
                        {
                          pending: "Pending Requests",
                          approved: "Approved Requests",
                          monthly:"Monthly View Time",
                          set:"Set Daily Quote"
                        }[selectedTab]
                      }
                      &nbsp; {" > "}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#9782CA",
                      }}
                    >
                      Request Details
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "36px",
                      fontWeight: "700",
                      color: "white",
                      fontFamily: "DM Sans",
                      marginBottom: "2rem",
                    }}
                  >
                    Content Creator Details
                  </Typography>
                </Box>

                {/* <ContentCreatorDetails selectedCreator={selectedCreator} /> */}
                {selectedCreator && (
                  <ContentCreatorDetails selectedCreator={selectedCreator} />
                )}
              </>
            )}
          </Box>
        </Box>
      </AdminContext.Provider>
    </ThemeProvider>
  );
}

function Table({ setSelectedCreator, setDetailsOpened, handleRowClick }) {
  const { selectedTab } = useContext(AdminContext);
  const [contentCreators, setContentCreators] = useState([]);
  const [loadingStates, setLoadingStates] = useState({}); // Track loading for each creator
  const [viewDurations, setViewDurations] = useState({});
  const [viewTime, setViewTime] = useState('monthly'); // Default to "monthly"
  const [quotes, setQuotes] = useState([]);
  
  const [loadingQuoteStates, setLoadingQuoteStates] = useState({});

  // Helper function to get the current month
const getCurrentMonth = () => {
  const month = new Date().toLocaleString("default", { month: "long" });
  return month;
};
const [viewMonth, setViewMonth] = useState(getCurrentMonth());

  const handleViewTimeChange = (event) => {
    setViewTime(event.target.value);
  };

    // Handle change for viewMonth dropdown
    const handleViewMonthChange = (event) => {
      setViewMonth(event.target.value);
    };

  // Function to update loading state for a specific creator
  const setCreatorLoading = (id, isLoading) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: isLoading,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Number of items per page
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  

  

  async function fetchPendingContentCreators() {
    try {
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/admin`;
      const response = await fetch(url);
      let data = await response.json();

      const totalItems = data.length;
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(totalPages);

      if (data.length < 7) {
        const emptyObjects = new Array(7 - data.length).fill({});
        data = [...data, ...emptyObjects];
      }

      setContentCreators(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pending content creators: ", error);
    }
  }

  async function fetchApprovedContentCreators() {
    try {
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/admin/approved`;
      const response = await fetch(url);
      let data = await response.json();

      const totalItems = data.length;
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(totalPages);

      if (data.length < 7) {
        const emptyObjects = new Array(7 - data.length).fill({});
        data = [...data, ...emptyObjects];
      }

      setContentCreators(data);
    } catch (error) {
      console.error("Error fetching approved content creators: ", error);
    }
  }

  const base64ToBlob = (base64, contentType = "image/jpeg") => {
    const byteCharacters = atob(base64.split(",")[1]); // Decode base64
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  async function getAggregatedViewDuration(creatorID) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/creators/${creatorID}/view-duration`,
        {
          params: { viewTime, viewMonth },
        }
      );
  
      const { publicViewDuration, privateViewDuration } = response.data;
      return { publicViewDuration, privateViewDuration };
    } catch (error) {
      console.error(
        "Error fetching view duration:",
        error.response ? error.response.data : error.message
      );
      return null;
    }
  }
  
  
  function formatTime(seconds) {
    if (seconds < 60) {
      return `${seconds} sec${seconds === 1 ? '' : ''}`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min${minutes === 1 ? '' : 's'}${remainingSeconds > 0 ? ` and ${remainingSeconds} sec${remainingSeconds === 1 ? '' : ''}` : ''}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours} hr${hours === 1 ? '' : ''}${remainingMinutes > 0 ? `, ${remainingMinutes} min${remainingMinutes === 1 ? '' : ''}` : ''}${remainingSeconds > 0 ? ` and ${remainingSeconds} sec${remainingSeconds === 1 ? '' : ''}` : ''}`;
    }
  }
  const createChatForCreator = async (
    creatorName,
    creatorPictureBase64,
    creatorId,
  ) => {
    try {
      let mediaUrl = null;
      const creatorPictureBlob = base64ToBlob(creatorPictureBase64);

      // Create a reference to the Firebase Storage
      const mediaRef = ref(storage, `channels/${creatorId || "new"}`);

      // Upload the Blob to Firebase Storage
      const snapshot = await uploadBytes(mediaRef, creatorPictureBlob);

      // Get the download URL
      mediaUrl = await getDownloadURL(snapshot.ref);

      // Prepare chat data
      const chatData = {
        chatName: `${creatorName}'s Channel`,
        picture: mediaUrl,
        participants: [creatorId], // Add participants later when users join
        createdAt: new Date(),
      };

      // Add chat data to Firestore and get the chat ID
      const chatRef = await addDoc(collection(db, "chats"), chatData);
      console.log("Chat created with ID: ", chatRef.id);
      return chatRef.id; // Return the chat ID
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  async function approveContentCreator(id) {
    try {
        setCreatorLoading(id, true); // Set loading for this specific creator

        // Fetch content creator details
        const creator = await fetch(
            `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/${id}`
        ).then((res) => res.json());

        if (!creator) return;

        // Create the chat for the content creator
        const chatId = await createChatForCreator(
            creator.name,
            creator.profilePicture,
            id
        );

        // Approve the content creator and send the chatId
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_ADDRESS}/api/admin/approve/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ chatId }), // Include chatId in the body
            }
        );

        if (response.ok) {
            console.log("Content creator approved successfully with chatId", chatId);
            // Update the content creators state by filtering out the approved creator
            toast.success("Content Creator Approved successfully", {
              position: "bottom-right",
            });
            setContentCreators((prevCreators) =>
                prevCreators.filter((creator) => creator.id !== id)
            );
        } else {
            console.error("Error approving content creator");
        }
    } catch (error) {
        console.error("Error approving content creator:", error);
    } finally {
        setCreatorLoading(id, false); // Set loading for this specific creator
    }
}


async function rejectContentCreator(id) {
  try {
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/admin/reject/${id}`;

      const response = await fetch(url, {
          method: "PUT",
      });

      if (response.ok) {
          console.log("Content creator rejected successfully");
          toast.success("Content Creator rejected successfully", {
            position: "bottom-right",
          });
          // Update the content creators state by filtering out the rejected creator
          setContentCreators((prevCreators) =>
              prevCreators.filter((creator) => creator.id !== id)
          );
      } else {
          console.error("Error rejecting content creator");
      }
  } catch (error) {
      console.error("Error rejecting content creator: ", error);
  }
}


async function fetchQuotes() {
  try {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/quote/fetch-quotes`; // API endpoint for fetching quotes
    const response = await fetch(url, {
      method: 'GET', // GET request to fetch quotes
    });
    if (response.ok) {
      const data = await response.json();
      console.log('Quotes fetched successfully:', data.quotes);
      // Optionally, update the state with the fetched quotes if using React
      setQuotes(data.quotes); // Ensure you have a `setQuotes` state setter
    } else {
      console.error('Error fetching quotes');
    }
  } catch (error) {
    console.error('Error fetching quotes:', error);
  }
}

  // Function to activate a quote
  const activateQuote = async (quoteId) => {
    try {
      // Set loading state
      setLoadingQuoteStates((prevState) => ({ ...prevState, [quoteId]: true }));

      // Call the API to activate the quote
      const response = await axios.put(`${process.env.REACT_APP_SERVER_ADDRESS}/api/quote/activate/${quoteId}`);
      if (response.status === 200) {
        // Update the state of quotes - Set the activated one as 'isQuoteOfTheDay' and reset others
        setQuotes((prevState) =>
          prevState.map((quote) =>
            quote.id === quoteId
              ? { ...quote, isQuoteOfTheDay: true }
              : { ...quote, isQuoteOfTheDay: false }
          )
        );
        toast.success('Quote of the day updated successfully!',
          {
            position:"bottom-right"
          }
        );
      }
    } catch (error) {
      toast.error('Failed to activate the quote.',
        {
          position:"bottom-right"
        });
    } finally {
      // Remove loading state
      setLoadingQuoteStates((prevState) => ({ ...prevState, [quoteId]: false }));
    }
  };

    // Function to handle button click
    const handleQuoteButtonClick = (quote) => {
      if (!quote.isQuoteOfTheDay) {
        // Only activate if the quote is not already the Quote of the Day
        activateQuote(quote.id);
      }
      else{
        activateQuote(quote.id + 1)
      }
    };


  useEffect(() => {
    setContentCreators([]);
    switch (selectedTab) {
      case "pending":
        console.log("Fetching pending content creators");
        fetchPendingContentCreators();
        break;

      case "approved":
        console.log("Fetching approved content creators");
        fetchApprovedContentCreators();
        break;
      case "monthly":
        console.log("Fetching approved content creators");
        fetchApprovedContentCreators();
        break;
      case "set":
        console.log("Fetching Quotes");
        fetchQuotes();
        break;
      default:
        console.log("Not correct type");
        break;
    }
  }, [selectedTab]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Box
          key={i}
          sx={customStyling.paginationCounter}
          onClick={() => handlePageClick(i)}
          style={{
            cursor: "pointer",
            color: currentPage === i ? "#7F56D9" : "#493676",
          }}
        >
          {i}
        </Box>,
      );
    }

    return pages;
  };

  useEffect(() => {
    if (selectedTab === "monthly") {
      contentCreators.forEach((creator) => {
        if (creator.id) {
          getAggregatedViewDuration(creator.id).then((durations) => {
            if (durations) {
              setViewDurations((prev) => ({
                ...prev,
                [creator.id]: {
                  public: formatTime(durations.publicViewDuration),
                  private: formatTime(durations.privateViewDuration),
                },
              }));
            }
          });
        }
      });
    } else {
      setViewDurations({});
    }
  }, [contentCreators, selectedTab, viewTime, viewMonth]);
  
  

  const customStyling = {
    headingTypography: {
      textAlign: "center",
      fontSize: {
        xs: "12px", // Smaller font size on extra-small screens
        sm: "14px", // Default font size on small screens and up
        md: "16px", // Larger font size on medium screens and up
      },
      fontWeight: "700",
      fontFamily: "DM Sans",
    },
    rowTypography: {
      fontFamily: "DM Sans",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffffff",
      textAlign: "center",
      fontSize: {
        xs: "12px", // Smaller font size on extra-small screens
        sm: "14px", // Default font size on small screens and up
        md: "16px", // Larger font size on medium screens and up
      },
      fontWeight: "700",
    },
    paginationCounter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: {
        xs: "15px", // Smaller on extra-small screens
        sm: "20px", // Default size on small screens and up
      },
      width: {
        xs: "15px",
        sm: "20px",
      },
    },
  };
  
  console.log(viewDurations)
  return (
    <>
    <Box
    sx={{
      overflow:"auto",
      position: "relative",
    "&::after": {
      content: '""',
      top: 0,
      right: 0,
      bottom: 0,
      width: "50px", // Adjust width as needed
      pointerEvents: "none",
    },
    }}
    >
    <Box
      sx={{
        marginX: "3rem",
        marginTop: "2rem",
        width: "100rem",
        height: "72%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#493676",
          color: "white",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          borderBottom: "1px solid #EAEAEA",
          minWidth: "auto", // Sets minimum width to prevent columns from stacking vertically
        }}
      >
        <Box
          sx={{
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={customStyling.headingTypography}>ID No</Typography>
        </Box>
        {
  selectedTab === "set" ? (
    <>
      <Box
        sx={{
          width: "50%",
          display: "flex", // One display rule to rule them all!
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography sx={customStyling.headingTypography}>Quote</Typography>
      </Box>
      <Box
        sx={{
          width: "20%",
          display: "flex", // One display rule to rule them all!
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography sx={customStyling.headingTypography}>Author</Typography>
      </Box>
      <Box
        sx={{
          width: "10%",
          display: "flex", // One display rule to rule them all!
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography sx={customStyling.headingTypography}>Status</Typography>
      </Box>
      <Box
          sx={{
            width: "30%",
            display: "flex", // One display rule to rule them all!
    alignItems: "center",
    justifyContent: "center"
          }}
        >
          <Typography sx={customStyling.headingTypography}>Action</Typography>
        </Box>
    </>
  ) : (
    <>
  <Box
    sx={{
      width: selectedTab === "monthly" ? "20%" : "25%", // Decreased width for monthly tab
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography sx={customStyling.headingTypography}>Applicant Name</Typography>
  </Box>

  <Box
    sx={{
      width: selectedTab === "monthly" ? "25%" : "30%", // Decreased width for monthly tab
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography sx={customStyling.headingTypography}>Email</Typography>
  </Box>

      {selectedTab !== "monthly" ? (
    // Date section for non-monthly tabs
    <Box
      sx={{
        width: "10%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={customStyling.headingTypography}>Date</Typography>
    </Box>
  ) : (
    // Public View Time section for monthly tab
    <Box
      sx={{
        width: "15%",
        display: selectedTab !== "monthly" ? "none" : "flex", // One display rule to rule them all!
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={customStyling.headingTypography}>Public View Time</Typography>
    </Box>
  )}

  {/* Private View Time for monthly tab */}
  {selectedTab === "monthly" && (
    <Box
      sx={{
        width: "15%",
        display: selectedTab !== "monthly" ? "none" : "flex", // One display rule to rule them all!
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={customStyling.headingTypography}>Private View Time</Typography>
    </Box>
  )}
    </>
  )
}

        <Box
          sx={{
            width: "25%",
            display: selectedTab !== "pending" ? "none" : "flex", // One display rule to rule them all!
    alignItems: "center",
    justifyContent: "center"
          }}
        >
          <Typography sx={customStyling.headingTypography}>Action</Typography>
        </Box>
        
        <Box
  sx={{
    width: "22%",
    display: selectedTab !== "monthly" ? "none" : "flex", // One display rule to rule them all!
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem", // Adds a nice bit of space between the text and dropdown
  }}
>
  {/* Dropdown for selecting time period */}
  <Box sx={{ minWidth: 120 }}>
    <select
      value={viewTime}
      onChange={handleViewTimeChange} // No change to function handling, just keeping it all smooth
      style={{
        padding: "0.5rem",
        borderRadius: "0.25rem",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        fontSize: "1rem",
        color:"black"
      }}
    >
      <option value="monthly">Full Month</option>
      <option value="week1">Week 1</option>
      <option value="week2">Week 2</option>
      <option value="week3">Week 3</option>
      <option value="week4">Week 4</option>
    </select>
  </Box>
  <Box sx={{ minWidth: 120 }}>
            <select
              value={viewMonth}
              onChange={handleViewMonthChange}
              style={{
                padding: "0.5rem",
                borderRadius: "0.25rem",
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                fontSize: "1rem",
                color: "black",
              }}
            >
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </Box>
</Box>



      </Box>
      {/* Data Rows */}
      {contentCreators.map((creator, index) => (
        <Box
          key={creator.id}
          onClick={() => creator.id && handleRowClick(creator)}
          sx={{
            display: "flex",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            borderBottom: "1px solid #ffffff",
            backgroundColor: "#000000",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "10%",
            }}
          >
            <Typography sx={customStyling.rowTypography}>
              {creator.id}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: selectedTab === "monthly" ? "20%" : "25%",
            }}
          >
            <Typography sx={customStyling.rowTypography}>
              {creator.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: selectedTab === "monthly" ? "25%" : "30%",
            }}
          >
            <Typography sx={customStyling.rowTypography}>
              {creator.email}
            </Typography>
          </Box>
          {selectedTab !== "monthly" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "10%",
            }}
          >
            <Typography sx={customStyling.rowTypography}>
              {creator.createdAt && new Date(creator.createdAt).toDateString()}
            </Typography>
          </Box>
          )}
          {selectedTab === "monthly" && (
    <>
      {/* Public View Time */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "15%", // Matches width of Public View Time heading
        }}
      >
        <Typography sx={customStyling.rowTypography}>
          {viewDurations[creator.id]?.public}
        </Typography>
      </Box>

      {/* Private View Time */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "15%", // Matches width of Private View Time heading
        }}
      >
        <Typography sx={customStyling.rowTypography}>
          {viewDurations[creator.id]?.private}
        </Typography>
      </Box>
    </>
  )}

          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              width: "25%",
              justifyContent: "center",
              alignItems: "center",
              height: "3.75rem",

              paddingY: "0.5rem",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#7F56D9",
                display: (!creator.id || selectedTab !== "pending") && "none",
                color: "white",
                width: "6rem",
                height: "2rem",
                borderRadius: "10px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#7F56D9",
                  color: "white",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                approveContentCreator(creator.id);
              }}
              disabled={loadingStates[creator.id]}
            >
              {loadingStates[creator.id] ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 animate-spin mx-auto fill-white"
                  viewBox="0 0 32 32"
                >
                  <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
                </svg>
              ) : (
                "Approve"
              )}
            </Button>
            <Button
              sx={{
                backgroundColor: "wite",
                color: "#7F56D9",
                display: (!creator.id || selectedTab !== "pending") && "none",
                textTransform: "none",
                borderRadius: "10px",
                width: "6rem",
                height: "2rem",
                border: "1px solid",

                borderImageSource:
                  "linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, #EAEAEA, #EAEAEA)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                rejectContentCreator(creator.id);
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
      ))}
      {selectedTab === "set" &&
  quotes.map((quote, index) => (
    <Box
      key={quote.id}
      sx={{
        display: "flex",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        borderBottom: "1px solid #ffffff",
        backgroundColor: "#000000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "10%",
        }}
      >
        <Typography sx={customStyling.rowTypography}>
          {quote.id}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "50%",
        }}
      >
        <Typography sx={customStyling.rowTypography}>
          {quote.text}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "20%",
        }}
      >
        <Typography sx={customStyling.rowTypography}>
          {quote.author}
        </Typography>
      </Box>
      <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    width: "10%",
  }}
>
  <Typography
    sx={{
      ...customStyling.rowTypography,
      color: quote.isQuoteOfTheDay ? "green" : "red", // Move the color styling here
    }}
  >
    {quote.isQuoteOfTheDay ? "Active" : "Inactive"}
  </Typography>
</Box>

      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          width: "30%",
          justifyContent: "center",
          alignItems: "center",
          height: "3.75rem",
          paddingY: "0.5rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: quote.isQuoteOfTheDay ? "red" : "green",
            color: "white",
            width: "6rem",
            height: "2rem",
            borderRadius: "10px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: quote.isQuoteOfTheDay ? "darkred" : "darkgreen",
              color: "white",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleQuoteButtonClick(quote);
          }}
        >
          {quote.isQuoteOfTheDay ? "Deactivate" : "Activate"}
        </Button>
      </Box>
    </Box>
  ))}
  </Box>
  </Box>
      <Box
        sx={{
          display: "flex",
          height: "5rem",
          justifyContent: "center",
          backgroundColor: "black",
          padding: "1rem",
          fontFamily: "DM Sans",
          marginRight:{ xs: "15rem", md: "0" },
        }}
      >
       <Box
          sx={{
            marginLeft: { xs: "0", sm: "0", md: "0", lg: "0", xl: "5rem" }, // No left margin up to lg
            display: "flex",
            width: { xs: "90%", sm: "90%", md: "60%", lg: "60%", xl: "22%" }, // Full width for all sizes up to lg
            borderRadius: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            position: { xs: "absolute", sm: "absolute", md: "relative", lg: "relative", xl: "relative" }, // Absolute positioning up to lg
            bottom: { xs: "1rem", sm: "1rem", md: "auto", lg: "auto", xl: "auto" }, // Position at bottom for all sizes up to lg
            left: { xs: "50%", sm: "50%", md: "auto", lg: "auto", xl: "auto" }, // Center horizontally up to lg
            transform: { xs: "translateX(-50%)", sm: "translateX(-50%)", md: "none", lg: "none", xl: "none" }, // Center transform up to lg
            paddingX: { xs: "1rem", sm: "1rem", md: "0.5rem", lg: "0.5rem", xl: "0.5rem" }, // Padding for all sizes up to lg
          }}
        >
          <Box
            sx={{
              color: "#ffffff",
              cursor: currentPage === 1 ? "default" : "pointer", // Change cursor based on state
              border: "1px solid #ffffff",
              paddingX: "1rem",
              paddingY: "0.5rem",
              // marginX: "0.5rem",
              borderRadius: "0.25rem",
              opacity: currentPage === 1 ? 0.5 : 1, // Dim the button if currentPage is 1 or 0
            }}
            onClick={currentPage === 1 ? undefined : handlePrevPage} // Prevent click if disabled
          >
            {"Previous"}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100px",
              gap: "1rem",
              alignItems: "center",
              color: "white",
              marginX: "1rem",
            }}
          >
            Page {totalPages === 0 ? 0 : currentPage} of {totalPages}
          </Box>

          <Box
            sx={{
              color: "#ffffff",
              cursor: currentPage === totalPages ? "default" : "pointer", // Change cursor based on state
              border: "1px solid #ffffff",
              paddingX: "1rem",
              paddingY: "0.5rem",
              // marginX: "0.5rem",
              borderRadius: "0.25rem",
              opacity: currentPage === totalPages || totalPages === 0 ? 0.5 : 1, // Dim the button if disabled
            }}
            onClick={currentPage === totalPages ? undefined : handleNextPage} // Prevent click if disabled
          >
            {"Next"}
          </Box>
        </Box>
      </Box>
      </>
   
  );
}

function Sidebar({ setDetailsOpened, setSelectedCreator, handleLogout }) {
  const [activeButton, setActiveButton] = useState("Pending Requests");
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Media query for responsiveness
  const isSmallScreen = useMediaQuery("(max-width: 1025px)");

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Sidebar content for reuse in both drawer and fixed sidebar
  const sidebarContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: isSmallScreen ? "75vw" : "100%", // Responsive width
        background: "linear-gradient(180deg, #4A3877 0%, #131313 50%)",
        minHeight: "100vh",
        paddingTop: isSmallScreen ? "20px" : "0", // Adjust padding for small screens
      }}
    >
      {/* Main Logo */}
      <Logo
        style={{
          marginTop: isSmallScreen ? "16px" : "48px",
          height: isSmallScreen ? "30px" : "41px",
          width: isSmallScreen ? "30px" : "34px",
        }}
      />

      {/* Sidebar Buttons */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: isSmallScreen ? "20px" : "calc(159px - 48px - 41px)",
          gap: isSmallScreen ? "1rem" : "2rem",
        }}
      >
        <SidebarButton
          icon={<PendingRequestIcon />}
          text="Pending Requests"
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setDetailsOpened={setDetailsOpened}
          setSelectedCreator={setSelectedCreator}
        />
        <SidebarButton
          icon={<img src={ApprovedIcon} alt="Approved" />}
          text="Approved Requests"
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setDetailsOpened={setDetailsOpened}
          setSelectedCreator={setSelectedCreator}
        />
        <SidebarButton
          icon={<PendingRequestIcon />}
          text="Monthly View Time"
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setDetailsOpened={setDetailsOpened}
          setSelectedCreator={setSelectedCreator}
        />
        <SidebarButton
          icon={<PendingRequestIcon />}
          text="Set Daily Quote"
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setDetailsOpened={setDetailsOpened}
          setSelectedCreator={setSelectedCreator}
        />
      </Box>

      {/* Logout Button */}
      <Box
        sx={{
          width: "75%",
          height: "16%",
          display: "flex",
          alignItems: "center",
          gap: "0.8rem",
          cursor: "pointer",
          marginBottom: isSmallScreen ? "5%" : "10%",
          marginTop: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: "5px",
              borderRadius: "30%",
            }}
          >
            <LogoutIcon sx={{ fontSize: "20px" }} />
          </Box>
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: isSmallScreen ? "14px" : "16px",
              fontWeight: "500",
              marginLeft: "8px",
            }}
          >
            Logout
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {isSmallScreen ? (
        <>
          {/* Menu Icon for Small Screens */}
          <IconButton
            sx={{
              position: "fixed",
              top: "10px",
              left: "10px",
              zIndex: 1300,
              color: "white",
            }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>

          {/* Sidebar as Drawer on Small Screens */}
          <Drawer anchor="left" open={isSidebarOpen} onClose={toggleSidebar}>
            {sidebarContent}
          </Drawer>
        </>
      ) : (
        // Fixed Sidebar for Larger Screens
        <Box
          sx={{
            width: "17%",
            position: "fixed",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {sidebarContent}
        </Box>
      )}
    </>
  );
}

function SidebarButton({
  icon,
  text,
  activeButton,
  setActiveButton,
  setDetailsOpened,
  setSelectedCreator,
}) {
  const { setSelectedTab } = useContext(AdminContext);

  const handleClick = (text) => {
    setActiveButton(text);

    const selectedTab = text.toLowerCase().split(" ")[0];
    setSelectedTab(selectedTab);
    setDetailsOpened(false);
    setSelectedCreator(null);
  };

  return (
    <Box
      sx={{
        width: "75%",
        height: "16%",
        borderRadius: "100px",
        border: "1px solid #AAAAAAEA",
        display: "flex",
        alignItems: "center",
        gap: "0.6rem",
        cursor: "pointer",
        backgroundColor: activeButton === text ? "#FFFFFF" : "transparent",
      }}
      onClick={() => {
        handleClick(text);
      }}
    >
      {icon}
      <Typography
        sx={{
          color: activeButton === text ? "#000000" : "#FFFFFF",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default Admin;
