import React, { useState } from "react";
import { FaInstagram, FaTiktok, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";

const ContentCreatorDetails = ({ selectedCreator }) => {
  const [fileName, setFileName] = useState("");

  const handleDownload = () => {
    if (selectedCreator.sampleWork) {
      const base64Data = selectedCreator.sampleWork;
      const byteCharacters = atob(base64Data.split(",")[1]);
      const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "downloaded-file";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <form className="space-y-4 w-full md:w-[65%] mx-auto text-white mb-16 px-4 md:px-0">
      <div className="mt-2 relative flex ">
        <span
          className="block bg-white border border-slate-500 w-32 h-32 md:w-40 md:h-40 rounded-full shadow"
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundImage: `url('data:image/png;base64,${selectedCreator.profilePicture.split(",")[1]}')`,
          }}
        ></span>
      </div>
      <div>
        <label className="block mb-1">Username</label>
        <input
          type="text"
          className="w-full p-2 border border-border rounded text-black"
          placeholder="Enter Your Full Name"
          name="username"
          value={selectedCreator.name}
          readOnly
        />
      </div>
      <div>
        <label className="block mb-1">Email</label>
        <input
          type="email"
          className="w-full p-2 border border-border rounded text-black"
          placeholder="Enter Your Email Address"
          name="email"
          value={selectedCreator.email}
          readOnly
        />
      </div>
      <div>
        <label className="block mb-1">Bio</label>
        <textarea
          className="w-full p-2 border border-border rounded text-black min-h-32 resize-none"
          placeholder="Tell Us About Yourself"
          name="bio"
          value={selectedCreator.bio}
          readOnly
        ></textarea>
      </div>
      <div>
        <label className="block mb-1">Why do you want to join Unindulgent? (Motivation or reason):</label>
        <textarea
          className="w-full p-2 border border-border rounded text-black min-h-32 resize-none"
          placeholder="Share your motivation or reason for joining"
          name="motivation"
          value={selectedCreator.motivation}
          readOnly
        ></textarea>
      </div>

      {selectedCreator.instagramId && (
        <div className="flex flex-col mb-4">
          <label className="block mb-1 font-semibold text-white">Instagram ID</label>
          <div className="flex items-center border bg-white border-border rounded text-black">
            <span className="p-2">
              <FaInstagram className="text-gray-500" />
            </span>
            <input
              name="social.instagram"
              value={selectedCreator.instagramId}
              type="text"
              className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
              placeholder="Enter Your Instagram Handle"
              readOnly
            />
          </div>
        </div>
      )}

      {/* Additional social media inputs */}
      {(selectedCreator.tiktokId || selectedCreator.twitterId || selectedCreator.facebookId || selectedCreator.linkedinId) && (
        <div className="flex flex-col space-y-4">
          <label className="block mb-1">Social Media Handles</label>
          {selectedCreator.tiktokId && (
            <SocialMediaInput icon={<FaTiktok />} label="TikTok ID" value={selectedCreator.tiktokId} />
          )}
          {selectedCreator.twitterId && (
            <SocialMediaInput icon={<FaTwitter />} label="Twitter ID" value={selectedCreator.twitterId} />
          )}
          {selectedCreator.facebookId && (
            <SocialMediaInput icon={<FaFacebook />} label="Facebook ID" value={selectedCreator.facebookId} />
          )}
          {selectedCreator.linkedinId && (
            <SocialMediaInput icon={<FaLinkedin />} label="LinkedIn ID" value={selectedCreator.linkedinId} />
          )}
        </div>
      )}

      {/* Content Links */}
      {selectedCreator.contentLinks.filter(link => link.trim() !== "").length > 0 && (
        <div className="flex flex-col space-y-2">
          <label className="block mb-1 font-semibold text-white">Content Links</label>
          {selectedCreator.contentLinks
            .filter(link => link.trim() !== "")
            .map((url, index) => (
              <input
                key={index}
                type="url"
                value={url}
                placeholder="e.g. YouTube, Blogs, Portfolio"
                className="w-full p-2 border border-gray-300 rounded-md text-black"
                readOnly
              />
            ))}
        </div>
      )}

      {/* Sample Work Download */}
      {selectedCreator.sampleWork && (
        <div className="flex flex-col mb-6">
          <label className="block mb-2 font-semibold text-white">Sample Work</label>
          <button
            type="button"
            onClick={handleDownload}
            className="px-4 py-2 rounded-md text-white bg-[#493676] hover:bg-[#493676]/80 focus:outline-none focus:ring-2 focus:ring-opacity-50"
          >
            Download File
          </button>
        </div>
      )}

      {/* Preferred Content Categories */}
      <div className="flex flex-col mb-6">
        <h1 className="block mb-2 font-semibold text-white">Preferred Content Categories</h1>
        <div className={`mb-4 pt-2 px-2 bg-gray-100 border border-gray-300 rounded-md flex flex-wrap items-center ${selectedCreator.preferredContentCategories.length === 0 ? 'h-12' : ''}`}>
          {selectedCreator.preferredContentCategories.map((category) => (
            <div
              key={category}
              className="flex items-center bg-gray-200 text-gray-700 rounded-full px-3 py-1 mr-2 mb-2"
            >
              {category}
            </div>
          ))}
        </div>
      </div>

      {/* Contact and Location */}
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex-1">
          <label className="block mb-1">Contact Number</label>
          <input
            type="text"
            className="w-full p-2 border border-border rounded text-black"
            placeholder="+92030289643271"
            name="contactNumber"
            value={selectedCreator.contactNumber}
            readOnly
          />
        </div>
        <div className="flex-1">
          <label className="block mb-1">Location</label>
          <input
            type="text"
            className="w-full p-2 border border-border rounded text-black"
            placeholder="London"
            name="location"
            value={selectedCreator.location}
            readOnly
          />
        </div>
      </div>
    </form>
  );
};

const SocialMediaInput = ({ icon, label, value }) => (
  <div className="flex items-center border bg-white border-border rounded text-black mb-4">
    <span className="p-2">{icon}</span>
    <input
      value={value}
      type="text"
      className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
      placeholder={`Enter Your ${label}`}
      readOnly
    />
  </div>
);

export default ContentCreatorDetails;
