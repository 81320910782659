import React, { useState } from "react";
import ErrorModal from "../components/ErrorModal";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function forgotPassword(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/pass/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.text();
      if (!response.ok) {
        switch (response.status) {
          case 400:
            setError("User not found");
            break;
          case 500:
            setError("Server error");
            break;
          default:
            setError("Error sending email");
        }
      } else {
        setError(null);
        setEmailSent(true);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setError("Error sending email");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#131313] font-roboto text-white">
      {emailSent ? (
        <div className="text-center">
          <Link to="/">
            <div className="text-center">
              <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
            </div>
          </Link>
          <h1 className="text-3xl font-bold mb-4">
            Password Reset Email Sent
          </h1>
          <p className="text-xl w-[40rem]">
            A password reset link has been sent to your email address. Please
            check your email and follow the instructions to reset your
            password.
          </p>
        </div>
      ) : (
        <div className="w-full max-w-md p-8 space-y-6">
          <>
            <div className="text-center">
              <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
              <h2 className="text-2xl font-bold font-dm-sans">
                Forgot Your Password?
              </h2>
            </div>
            <form onSubmit={forgotPassword} className="space-y-4">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-[#493676] focus:border-[#493676] sm:text-sm"
                  placeholder="Email"
                />
                {error && (
                  <div className="text-red-500 text-sm mt-2">{error}</div>
                )}
              </div>
              <button
                type="submit"
                className={`w-full py-2 px-4 border border-transparent rounded-full shadow-sm text-white font-bold  ${
                  isLoading ? 'bg-[#493676]/50' : 'bg-[#493676]'
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div role="status" className="flex justify-center items-center space-x-2">
                    <svg aria-hidden="true" className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  'Submit'
                )}
              </button>
            </form>
            <div className="text-center">
              <Link
                to="/login"
                className="text-sm text-muted-foreground hover:underline"
              >
                Remembered your password? Log in
              </Link>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
