import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion"; // Import motion and useAnimation
import Logo from "../assets/logo.png";
import Menu from "../assets/menu.png";
import { AuthContext } from "../context/AuthContext";

const Navbar = () => {
  const { authState, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility
  const controls = useAnimation(); // Animation controls for the mobile menu

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 950) {
        setIsMenuOpen(false); // Close menu on resize if it's open
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  // Check if authState.user is loaded
  const user = authState?.user;

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle menu state
    if (!isMenuOpen) {
      controls.start({ opacity: 1, y: 0 }); // Animate to visible
    } else {
      controls.start({ opacity: 0, y: -20 }); // Animate to hidden
    }
  };

  return (
    <>
      <nav
        id="navbar"
        className="bg-[#493676] text-white h-20 p-4 flex justify-between items-center font-roboto z-50"
      >
        <div className="ml-4 flex items-center">
          <Link to="/">
          <img src={Logo} alt="logo" className="w-8" />
          </Link>
        </div>

        {/* Desktop & tablet view for user navigation */}
        {user &&
          ((user.user_type === "user" && authState.paid) ||
            user.user_type === "creator") && (
            <div className="hidden lg:flex space-x-6">
              <Link
                to="/"
                className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
              >
                Home
              </Link>
              {authState.user.user_type === "user" && (
              <Link
                to="/for-you"
                className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
              >
                For You
              </Link>
              )}
              {authState.user.user_type === "user" && (
                <Link
                  to="/view-content"
                  className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
                >
                  Subscribed Content
                </Link>
              )}
              {authState.user.user_type === "user" && (
                <Link
                  to="/pricing"
                  className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
                >
                  Manage Billing
                </Link>
              )}
              {authState.user.user_type === "creator" && (
                <Link
                  to="/post-video"
                  className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
                >
                  Post Public Video
                </Link>
              )}
              {authState.user.user_type === "creator" && (
                <Link
                  to="/post-content"
                  className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
                >
                  Post Subscribed Content
                </Link>
              )}
              {authState.user.user_type === "user" && (
                <Link
                  to="/search-subscriptions"
                  className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
                >
                  Search Content Creators
                </Link>
              )}
              {authState.user.user_type === "creator" && (
                <Link
                  to={`/dashboard/${authState.user.user_id}`}
                  className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
                >
                  Dashboard
                </Link>
              )}
              <Link
                to="/chat"
                className="text-white hover:text-zinc-300 font-bold text-base lg:text-sm"
              >
                Chat
              </Link>
            </div>
          )}

        {/* Authentication buttons */}
        <div className="hidden lg:flex space-x-4 mr-4">
          {!authState.user ? (
            <>
              <button
                onClick={() => navigate("/login")}
                className="border-2 border-white text-white py-2 px-4 lg:px-3 rounded-full hover:bg-white hover:text-[#493676] duration-150 font-bold text-base lg:text-sm"
              >
                Log in
              </button>
              <button
                onClick={() => navigate("/register")}
                className="bg-white text-[#493676] py-2 px-4 lg:px-3 rounded-full hover:bg-zinc-200 duration-150 font-bold text-base lg:text-sm"
              >
                Sign up
              </button>
              <button
                onClick={() => navigate("/register-creator")}
                className="border-2 border-white text-white py-2 px-4 lg:px-3 rounded-full hover:bg-white hover:text-[#493676] duration-150 font-bold text-base lg:text-sm"
              >
                Create Content
              </button>
            </>
          ) : (
            <button
              onClick={handleLogout}
              className="border-2 border-white text-white py-2 px-4 lg:px-3 rounded-full hover:bg-white hover:text-[#493676] font-bold text-base lg:text-sm"
            >
              Logout
            </button>
          )}
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <img src={Menu} className="w-6" alt="hamburger-menu" />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <motion.div
        id="mobile-menu"
        className={`absolute top-16 left-0 w-full bg-[#493676] text-white p-4 space-y-4 font-roboto z-50 ${
          isMenuOpen ? "" : "hidden"
        } overflow-y-auto rounded-b-lg pb-5 text-lg`}
        initial={{ opacity: 0, y: -20 }} // Initial state
        animate={controls} // Animation controls
        transition={{ duration: 0.3 }} // Transition duration
      >
        {user &&
          ((user.user_type === "user" && authState.paid) ||
            user.user_type === "creator") && (
            <>
              <Link
                to="/"
                className="block text-white hover:text-zinc-300 font-bold"
              >
                Home
              </Link>
              {authState.user.user_type === "user" && (
                <Link
                  to="/for-you"
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  For You
                </Link>
              )}
              {authState.user.user_type === "user" && (
                <Link
                  to="/view-content"
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  Subscribed Content
                </Link>
              )}
              {authState.user.user_type === "user" && (
                <Link
                  to="/pricing"
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  Manage Billing
                </Link>
              )}
              {authState.user.user_type === "creator" && (
                <Link
                  to="/post-video"
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  Post Public Video
                </Link>
              )}
              {authState.user.user_type === "creator" && (
                <Link
                  to="/post-content"
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  Post Subscribed Content
                </Link>
              )}
              {authState.user.user_type === "user" && (
                <Link
                  to="/search-subscriptions"
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  Search Content Creators
                </Link>
              )}
              {authState.user.user_type === "creator" && (
                <Link
                  to={`/dashboard/${authState.user.user_id}`}
                  className="block text-white hover:text-zinc-300 font-bold"
                >
                  Dashboard
                </Link>
              )}
              <Link
                to="/chat"
                className="block text-white hover:text-zinc-300 font-bold"
              >
                Chat
              </Link>
            </>
          )}
        {!authState.user ? (
          <>
            <button
              onClick={() => navigate("/login")}
              className="w-full border border-white text-white py-2 rounded hover:bg-white duration-150 hover:text-[#493676] font-bold"
            >
              Log in
            </button>
            <button
              onClick={() => navigate("/register")}
              className="w-full border border-[#493676] text-[#493676] py-2 rounded hover:bg-zinc-200 duration-150 font-bold"
            >
              Sign up
            </button>
            <button
              onClick={() => navigate("/register")}
              className="w-full bg-white text-[#493676] py-2 rounded hover:bg-zinc-200 duration-150 font-bold"
            >
              Create Content
            </button>
          </>
        ) : (
          <button
            onClick={handleLogout}
            className="w-full border border-white text-white py-2 rounded hover:bg-white duration-150 hover:text-[#493676] font-bold"
          >
            Logout
          </button>
        )}
      </motion.div>
    </>
  );
};

export default Navbar;
