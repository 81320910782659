import React, { useState, useEffect, useContext } from 'react';
import { FaHeart, FaShare } from 'react-icons/fa'; // Importing icons from react-icons
import axios from 'axios';
import { AuthContext } from '../context/AuthContext'; // Import the AuthContext
import { TbPinnedFilled } from "react-icons/tb"

const ImagePost = (props) => {
    const [liked, setLiked] = useState(false); // State to manage like status
    const [likesCount, setLikesCount] = useState(0); // State to store total likes
    const { authState } = useContext(AuthContext); // Get authState from AuthContext

    const postId = props.postId; // Assuming postId is passed as a prop

    // Function to format date
    function formatDate(dateString) {
        const date = new Date(dateString);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        return `${hours}:${formattedMinutes} ${ampm} · ${month} ${day}, ${year}`;
    }

    // Fetch likes data when the component mounts
    useEffect(() => {
        if (authState.accessToken) {
            const fetchLikesData = async () => {
                try {
                    const likesResponse = await axios.get(
                        `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${postId}/likes`,
                        {
                            headers: {
                                Authorization: `Bearer ${authState.accessToken}`
                            }
                        }
                    );
                    setLikesCount(likesResponse.data.totalLikes);

                    const hasLikedResponse = await axios.get(
                        `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${postId}/hasLiked`,
                        {
                            headers: {
                                Authorization: `Bearer ${authState.accessToken}`
                            }
                        }
                    );
                    setLiked(hasLikedResponse.data.hasLiked);
                } catch (error) {
                    console.error("Failed to fetch like data", error);
                }
            };

            fetchLikesData();
        }
    }, [authState.accessToken, postId]);

    // Handle like/unlike actions
    const handleLikeClick = async () => {
        try {
            if (liked) {
                await axios.delete(
                    `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${postId}/unlike`,
                    {
                        headers: {
                            Authorization: `Bearer ${authState.accessToken}`
                        }
                    }
                );
                setLiked(false);
                setLikesCount((prevCount) => Math.max(0, prevCount - 1));
            } else {
                await axios.post(
                    `${process.env.REACT_APP_SERVER_ADDRESS}/api/like/post/${postId}/like`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${authState.accessToken}`
                        }
                    }
                );
                setLiked(true);
                setLikesCount((prevCount) => prevCount + 1);
            }
        } catch (error) {
            console.error("Failed to update like status", error);
        }
    };

    return (
        <div className="bg-[#1b1c20] rounded-xl mx-auto p-4 w-full max-w-[500px] text-white">
            <div className="flex items-center mb-2">
                <img
                    className="w-10 h-10 rounded-full object-cover"
                    src={props.profilePicture}
                    alt=""
                />
                <div className="ml-3 flex flex-col sm:flex-row w-full justify-between items-center">
                    <span className="font-bold text-sm sm:text-base">{props.contentCreator}</span>

                    {props.pinned && (
                        <div className="flex items-center mt-1 sm:mt-0">
                            <TbPinnedFilled className="text-white ml-2" size={20} />
                        </div>
                    )}
                </div>
            </div>

            <p className="text-sm sm:text-base mb-2">{props.caption}</p>

            <div className="border border-gray-500 rounded-lg overflow-hidden mb-2">
                <div className="relative pb-[70.25%]">
                    <img src={props.imageSrc} alt="Post" className="absolute inset-0 w-full h-full object-cover" />
                </div>
            </div>

            <div className="flex flex-wrap mb-2">
                {props.tags.map((tag) => (
                    <span
                        key={tag}
                        className="inline-block bg-[#493676] text-white text-xs sm:text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-1"
                    >
                        {tag}
                    </span>
                ))}
            </div>

            <span className="text-xs md:text-sm text-gray-400">{formatDate(props.date)}</span>

            <div className="flex items-center mt-3 space-x-4">
                <button
                    onClick={handleLikeClick}
                    className={`flex items-center duration-200 ${
                        liked ? "text-purple-500" : "text-gray-400 hover:text-white"
                    }`}
                >
                    <FaHeart className="w-5 h-5 mr-1" />
                    <span className="text-sm sm:text-base">{liked ? "Liked" : "Like"}</span>
                </button>
                
                <button className="flex items-center text-gray-400 hover:text-white duration-200">
                    <FaShare className="w-5 h-5 mr-1" />
                    <span className="text-sm sm:text-base">Share</span>
                </button>
            </div>
        </div>
    );
};

export default ImagePost;
