import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useState,useRef, useCallback, useContext } from "react";
import { MdOutlineTextFields } from "react-icons/md";
import { AiFillPicture } from "react-icons/ai";
import { FaVideo } from "react-icons/fa";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MainSidebar from "../components/MainSidebar";


const PostContent = () => {

    const { authState } = useContext(AuthContext);
    const [selectedType, setSelectedType] = useState('text');
    const [selectedTags, setSelectedTags] = useState([]);
    const [content, setContent] = useState('');
    const [caption, setCaption] = useState('');
    const [video, setVideo] = useState(null);
    const [creator, setCreator] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [videoType, setVideoType] = useState("private");
    const [videoPreview, setVideoPreview] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const navigate = useNavigate();
    
    // Reference to the video element
    const videoRef = useRef(null);

          // Function to toggle play/pause
          const playOrPause = useCallback(() => {
            const video = videoRef.current;
            if (video) {
            if (video.paused || video.ended) {
                video.play();
                setIsPlaying(true);
            } else {
                video.pause();
                setIsPlaying(false);
            }
            }
        }, []);
    
        const handleRemoveVideo = () => {
            setVideo(null);
            if (videoRef.current) {
              videoRef.current.pause();
              videoRef.current.src = "";
            }
          };
        
      useEffect(() => {
        if (authState.user) {
          setCreator(authState.user);
        }
      }, [authState.user]);

      useEffect(() => {
        if (creator) {
          console.log(creator.user_id)
          if (creator.user_type!=="creator"){
            navigate("/")
          }
        }
      }, [creator]);
        
  
        
      const handleVideoTypeChange = (event) => {
            setVideoType(event.target.value); // Update state when radio option is selected
            
          };
      
        
  
      const handleVideoChange = (event) => {
              const selectedVideo = event.target.files[0];
              setVideo(selectedVideo);
                  // Generate preview URL
      const previewUrl = URL.createObjectURL(selectedVideo);
      setVideoPreview(previewUrl);
            };
          
      const handleThumbnailChange = (event) => {
              const selectedThumbnail = event.target.files[0];
              setThumbnail(selectedThumbnail);
            };
  
    const maxLength = 300;

    const availableTags = [
        "Fitness",
        "Meditation",
        "Lifestyle",
        "Coaching",
        "Mental Wellness",
        "Technology",
        "Health",
        "Education",
        "Business",
        "Music"
      ];

    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const imageRef = useRef(null);

    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
      };
  
    const handleImageChange = (e) => {
          const file = e.target.files[0];
          if (file) {
              const reader = new FileReader();
              reader.onloadend = () => {
                  setImagePreview(reader.result);
              };
              reader.readAsDataURL(file);
              setImage(file);
          }
    };
  
    const handleRemoveImage = () => {
          setImage(null);
          setImagePreview(null);
          imageRef.current.value = null;
    };
    
    const handleTagClick = (tag) => {
        // Check if the tag is already selected
        if (selectedTags.includes(tag)) {
          // Remove the tag if already selected
          setSelectedTags(selectedTags.filter((t) => t !== tag));
        } else if (selectedTags.length < 3) {
          // Add the tag if less than 3 tags are selected
          setSelectedTags([...selectedTags, tag]);
        }
      }



    const handleContentChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxLength) {
            setContent(value);
        }
    };

    const getButtonStyles = (type) => {
        return selectedType === type 
            ? "bg-[#493676] border-[#493676] text-white" 
            : "border-[#493676] text-white";
    };


    const allFieldsFilled = (() => {
      if (selectedType === 'text') {
          return content !== '' && selectedTags.length > 0;
      } else if (selectedType === 'image') {
          return image !== null && caption !== '' && selectedTags.length > 0;
      } else if (selectedType === 'video') {
          return video !== null && caption !== '' && selectedTags.length > 0;
      }
      return false;
  })();

  const handleUpload = () => {
    if (!allFieldsFilled) {
        return; // Ensure all fields are filled and user data is available
    }

    setIsLoading(true); // Set loading to true when upload starts

    if (selectedType === 'text') {
        // Prepare FormData for text type
        const formData = new FormData();
        formData.append('text', content); // Adding content for text type
        formData.append('tags', selectedTags.join(',')); // Adding selected tags
        formData.append('ContentCreatorID', creator.user_id); // Adding user ID
        formData.append('type', 'text'); // Adding the type of the post

        // Send the request to create a post
        fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/create`, {
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if (response.ok) {
                toast.success('Text content uploaded successfully', { position: 'bottom-right' });
                // Clear all fields
                setContent('');
                setSelectedTags([]);
            } else {
                alert('Failed to upload text content. Please try again.');
            }
        })
        .catch(error => {
            toast.error('An error occurred during upload. Please check your connection and try again.', { position: 'bottom-right' });
            console.error('Upload error:', error);
        })
        .finally(() => {
            setIsLoading(false); // Reset loading state after upload
        });

    } else if (selectedType === 'image') {
        // Prepare FormData for image type
        const formData = new FormData();
        formData.append('imageFile', image); // Adding the image file
        formData.append('text', caption); // Adding caption for image
        formData.append('tags', selectedTags.join(',')); // Adding selected tags
        formData.append('ContentCreatorID', creator.user_id); // Adding user ID
        formData.append('type', 'image'); // Adding the type of the post

        // Send the request to create a post
        fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/create`, {
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if (response.ok) {
                toast.success('Image uploaded successfully', { position: 'bottom-right' });
                // Clear all fields
                setImage(null);
                setImagePreview(null);
                setCaption('');
                setSelectedTags([]);
            } else {
                alert('Failed to upload image. Please try again.');
            }
        })
        .catch(error => {
            toast.error('An error occurred during upload. Please check your connection and try again.', { position: 'bottom-right' });
            console.error('Upload error:', error);
        })
        .finally(() => {
            setIsLoading(false); // Reset loading state after upload
        });

    } else if (selectedType === 'video') {
        // Prepare FormData for video upload
        const uploadData = new FormData();
        if (video) {
          uploadData.append('videoFile', video); // Adding the video file
          uploadData.append('type', videoType); // Adding the video type
      }
        if (thumbnail) {
          uploadData.append('thumbnail', thumbnail); // Adding the thumbnail file
      }
        uploadData.append('title', caption); // Adding caption for video
        uploadData.append('description', 'lorem ipsum'); // Adding 'lorem ipsum' as description
        uploadData.append('tags', selectedTags.join(',')); // Adding selected tags
        uploadData.append('ContentCreatorID', creator.user_id); // Adding user ID

        // First, upload the video
        fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/for-you/upload`, {
            method: 'POST',
            body: uploadData,
        })
        .then(response => response.json())
        .then(data => {
            if (data.videoId) {

              console.log(data.videoId)
                // Prepare FormData for creating the post
                const postData = new FormData();
                postData.append('VideoID', data.videoId); // Adding videoId from the response
                postData.append('tags', selectedTags.join(',')); // Adding selected tags
                postData.append('ContentCreatorID', creator.user_id); // Adding user ID
                postData.append('type', 'video'); // Adding the type of the post

                // Send the request to create a post with videoId
                return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/api/post/create`, {
                    method: 'POST',
                    body: postData,
                });
            } else {
                throw new Error('Video upload failed');
            }
        })
        .then(response => {
            if (response.ok) {
                toast.success('Video uploaded successfully', { position: 'bottom-right' });
                // Clear all fields
                setVideo(null);
                setThumbnail(null);
                setVideoPreview(null);
                setCaption('');
                setSelectedTags([]);
                setVideoType('private');
                handleRemoveVideo();
                setIsPlaying(false);
            } else {
                alert('Failed to create post with video. Please try again.');
            }
        })
        .catch(error => {
            toast.error('An error occurred during upload. Please check your connection and try again.', { position: 'bottom-right' });
            console.error('Upload error:', error);
        })
        .finally(() => {
            setIsLoading(false); // Reset loading state after upload
        });
    }
};


    return (
        <div className="flex flex-col min-h-screen pb-8 bg-black h-full text-white font-dm-sans">
        <MainSidebar />
        <main className="max-w-7xl mx-auto mt-20">
        <h1 className="text-5xl font-bold text-center mb-4 mt-6">
      Post Content for Subscribers
    </h1>

    <div className="flex flex-wrap mt-12 gap-4 justify-center">
  <div 
    className={`border rounded-lg flex items-center cursor-pointer ${getButtonStyles('text')} w-full sm:w-auto md:mx-0 mx-10`}
    onClick={() => setSelectedType('text')}
  >
    <MdOutlineTextFields className="text-xl ml-2" />
    <h2 className="text-lg px-2 py-1">Create a Text Post</h2>
  </div>
  <div 
    className={`border rounded-lg flex items-center cursor-pointer ${getButtonStyles('video')} w-full sm:w-auto md:mx-0 mx-10`}
    onClick={() => setSelectedType('video')}
  >
    <FaVideo className="text-xl ml-2" />
    <h2 className="text-lg px-2 py-1">Create a Video Post</h2>
  </div>
  <div 
    className={`border rounded-lg flex items-center cursor-pointer ${getButtonStyles('image')} w-full sm:w-auto md:mx-0 mx-10`}
    onClick={() => setSelectedType('image')}
  >
    <AiFillPicture className="text-xl ml-2" />
    <h2 className="text-lg px-2 py-1">Create an Image Post</h2>
  </div>
</div>

        { selectedType === 'text' && (
    <div className="md:mx-0 mx-4">
        <div className="mt-8 sm:mx-4">
            <h2 className="text-xl my-4"> Write post content</h2>
            <textarea 
                className="w-full h-28 p-4 border rounded-lg text-black" 
                placeholder="Write your post content here..." 
                value={content}
                onChange={handleContentChange}
            ></textarea>
            <p className="text-right mt-2 text-sm text-gray-500">
                {maxLength - content.length} characters left
            </p>
        </div>

        <div>
      <h1 className="text-xl my-4">Select Tags</h1>
      <div className="flex flex-wrap gap-2">
        {availableTags.map((tag) => (
          <button
            key={tag}
            className={`${
              selectedTags.includes(tag)
                ? "bg-[#493676] text-white"
                : "bg-gray-200 text-gray-800"
            } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
            onClick={() => handleTagClick(tag)}
            disabled={
              selectedTags.length >= 3 && !selectedTags.includes(tag)
            }
          >
            {tag}
          </button>
        ))}
      </div>
      {selectedTags.length === 3 && (
        <p className="text-red-500 text-sm mt-2">
          You can select up to 3 tags only.
        </p>
      )}
    </div>
    </div>

        ) }

    { selectedType === 'image' && (
        <div className="flex flex-col md:flex-row w-full mt-12">
            <div className="w-full md:w-1/2 flex flex-col items-start ">
            <h1 className='text-2xl mb-4 ml-8 md:ml-36'>
                Upload Image
            </h1>
            <label 
              htmlFor="uploadFile2"
              className="text-gray-500 font-semibold text-base rounded-xl w-[350px] h-[625px] flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed mx-auto font-[sans-serif]"
            >
              {image ? (
                <div className="relative w-full h-full flex justify-center items-center">
                    <img
                      className="w-full h-auto rounded-lg"
                      src={imagePreview}
                      alt="Uploaded Preview"
                    />
                    <div className="absolute bottom-0 right-0 m-4">
                        <button
                            className="border border-[#493676] text-white text-sm px-2 py-1 rounded-full"
                            onClick={handleRemoveImage}
                        >
                            Remove
                        </button>
                    </div>
                </div>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-14 mb-4 fill-[#493676]" viewBox="0 0 32 32">
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                  </svg>
                  <span className="text-sm text-[#493676] ">Upload Image</span>
                  <p className="text-xs font-medium text-gray-400 mt-2">JPG, PNG, GIF allowed</p>
                </>
              )}
              <input type="file" id="uploadFile2" className="hidden" accept="image/png,image/jpeg,image/gif" onChange={handleImageChange} ref={imageRef} disabled={image} />
            </label>
            </div>
            <div className="w-[87%] md:w-1/2 mx-auto ">
            <h1 className='text-xl my-4'>
                Picture Caption
            </h1>
            <textarea
                className='w-full h-20 text-black p-4 border border-[#493676] rounded-lg resize-none'
                placeholder='Write a caption for your image...'
                value={caption}
                onChange={handleCaptionChange}
            ></textarea>

<div>
      <h1 className="text-xl my-4">Select Tags</h1>
      <div className="flex flex-wrap gap-2">
        {availableTags.map((tag) => (
          <button
            key={tag}
            className={`${
              selectedTags.includes(tag)
                ? "bg-[#493676] text-white"
                : "bg-gray-200 text-gray-800"
            } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
            onClick={() => handleTagClick(tag)}
            disabled={
              selectedTags.length >= 3 && !selectedTags.includes(tag)
            }
          >
            {tag}
          </button>
        ))}
      </div>
      {selectedTags.length === 3 && (
        <p className="text-red-500 text-sm mt-2">
          You can select up to 3 tags only.
        </p>
      )}
    </div>

            </div>
        </div>
        ) }

    { selectedType === 'video' && (
        <div className="flex flex-col md:flex-row w-full mt-12">
        <div className="w-full md:w-1/2">
        <h1 className="text-2xl mb-4 ml-8 md:ml-32">
                Upload Video
            </h1>
            <label 
              htmlFor="uploadFile1"
              className="text-gray-500 font-semibold text-base rounded-xl w-[350px] h-[625px] flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed mx-auto font-[sans-serif]"
            >
              {video ? (
                <div className="relative w-full h-full flex justify-center items-center">
                  <div
                    className="relative w-full h-full flex justify-center items-center"
                    onClick={playOrPause} // Play/pause functionality
                  >
                    <video
                      className="w-full h-auto"
                      controls={false} // Disable default controls for custom play/pause
                      ref={videoRef} // Reference to the video element
                      src={videoPreview}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                    ></video>
                    {!isPlaying && (
                      <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-28 h-28 fill-white opacity-90"
                          viewBox="0 0 32 32"
                        >
                          <path d="M12 10v12l10-6z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="absolute bottom-0 right-0 m-4">
                    <button
                        className="border border-[#493676] text-white text-sm px-2 py-1 rounded-full"
                        onClick={handleRemoveVideo}
                    >
                        Remove
                    </button>
                    </div>
                </div>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-14 mb-4 fill-[#493676]" viewBox="0 0 32 32">
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                  </svg>
                  <span className="text-sm text-[#493676]">Upload Video</span>
                  <p className="text-xs font-medium text-gray-400 mt-2">MP4, WEBM allowed</p>
                </>
              )}
              <input type="file" id="uploadFile1" className="hidden" accept="video/mp4,video/webm" onChange={handleVideoChange} disabled={video} />
            </label>

            </div>
            <div className="w-[87%] ml-8 md:w-1/2 md:ml-24">
            <h1 className="text-2xl mb-4 mt-4 md:mt-0">
                Upload Thumbnail
            </h1>
            <label
              htmlFor="uploadThumbnail"
              className="text-[#493676] font-semibold text-base rounded max-w-md h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-[#493676] border-dashed font-[sans-serif]"
            >
              {thumbnail ? (
                <span className="text-sm text-[#493676]">{thumbnail.name}</span>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-11 mb-2 fill-[#493676]" viewBox="0 0 32 32">
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" />
                  </svg>
                  Upload Thumbnail
                  <p className="text-xs font-medium text-gray-400 mt-2">PNG, JPG, JPEG allowed.</p>
                </>
              )}
              <input type="file" id="uploadThumbnail" className="hidden" accept="image/png, image/jpeg" onChange={handleThumbnailChange} />
            </label>
    <h1 className='text-xl my-4'>
                Video Caption
            </h1>
            <textarea
                className='w-full h-20 text-black p-4 border border-[#493676] rounded-lg resize-none'
                placeholder='Write a caption for your video...'
                value={caption}
                onChange={handleCaptionChange}
            ></textarea>
            <div>
      <h1 className="text-xl my-4">Select Tags</h1>
      <div className="flex flex-wrap gap-2">
        {availableTags.map((tag) => (
          <button
            key={tag}
            className={`${
              selectedTags.includes(tag)
                ? "bg-[#493676] text-white"
                : "bg-gray-200 text-gray-800"
            } px-4 py-2 rounded-full text-sm font-medium transition duration-200 ease-in-out focus:outline-none hover:bg-[#524279] hover:text-white`}
            onClick={() => handleTagClick(tag)}
            disabled={
              selectedTags.length >= 3 && !selectedTags.includes(tag)
            }
          >
            {tag}
          </button>
        ))}
      </div>
      {selectedTags.length === 3 && (
        <p className="text-red-500 text-sm mt-2">
          You can select up to 3 tags only.
        </p>
      )}
    </div>

            </div>
        </div>
    )
    }


<div className=" flex flex-col items-end justify-end mt-10 mr-4 md:mr-0">
  <button
    className={`bg-[#493676] text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg shadow-lg text-base sm:text-lg ${!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={handleUpload}
    disabled={!allFieldsFilled || isLoading}
  >
    {isLoading ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5 sm:w-6 sm:h-6 animate-spin mx-auto fill-white"
        viewBox="0 0 32 32"
      >
        <path d="M16 0v4a12 12 0 0 1 0 24v4a16 16 0 0 0 0-32z" />
      </svg>
    ) : (
      "Publish"
    )}
  </button>
</div>



    </main>
    </div>
    )
}

export default PostContent;