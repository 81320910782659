import React, { useState, useRef } from "react";
import Logo from "../assets/logo.png";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { FaPen } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";

export default function SignupCreator() {
  const [recorded, setRecorded] = useState(false);

  const allCategories = [
    "funny",
    "politics",
    "advertisement",
    "movies",
    "art",
    "gaming",
    "music",
    "sports",
    "technology",
    "science",
    "travel",
    "food",
    "fashion",
    "health",
    "education",
    "business",
    "news",
    "nature",
    "lifestyle",
    "history",
    "photography",
    "literature",
    "comics",
    "memes",
    "celebrities",
    "automobiles",
    "finance",
    "relationships",
    "spirituality",
    "animals",
    "DIY",
    "crafts",
    "environment",
    "parenting",
    "psychology",
    "wellness",
    "fitness",
    "architecture",
    "home decor",
    "gardening",
    "astrology",
    "language",
    "media",
    "programming",
    "startups",
    "entrepreneurship",
    "others", // "Others" category added
];
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [urls, setUrls] = useState([""]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [photoName, setPhotoName] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    bio: "",
    social: {
      instagram: "",
      tiktok: "",
      twitter: "",
      facebook: "",
      linkedin: "",
    },
    motivation: "",
    contactNumber: "",
    location: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    // Handle nested fields like social.instagram, social.tiktok, etc.
    if (name.startsWith("social.")) {
      const socialField = name.split(".")[1]; // Extract the field name after 'social.'
      setFormData((prevState) => ({
        ...prevState,
        social: {
          ...prevState.social,
          [socialField]: value,
        },
      }));
    } else if (name === "email") {
      // Convert email to lowercase
      setFormData((prevState) => ({
        ...prevState,
        [name]: value.toLowerCase(),
      }));
    } else {
      // Handle regular fields like username, email, etc.
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  
  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleUrlsChange = (index, event) => {
    const newUrls = [...urls];
    newUrls[index] = event.target.value;
    setUrls(newUrls);
  };

  const handleAddInput = () => {
    setUrls([...urls, ""]);
  };

  const handleRemoveInput = (index) => {
    const newUrls = [...urls];
    newUrls.splice(index, 1);
    setUrls(newUrls);
  };

  const handleCategoryClick = (category) => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleRemoveCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };

  const availableCategories = allCategories.filter(
    (category) => !selectedCategories.includes(category)
  );

  const fileInputRefPfp = useRef(null);

  const handlePfpChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhotoName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPhotoPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePfpClick = () => {
    fileInputRefPfp.current.click();
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Basic client-side validation
    if (
      !formData.username ||
      !formData.email ||
      !formData.bio ||
      !formData.contactNumber ||
      !formData.location ||
      !formData.motivation ||
      selectedCategories.length === 0 ||
      !fileInputRefPfp.current.files[0] ||
      !formData.password
    ) {

      //console the fields that are not filled

      toast.error("Please fill all the mandatory fields.", {
        position: "bottom-right",
      });
      return;
    }

    const profilePictureBase64 = await fileToBase64(
      fileInputRefPfp.current.files[0]
    );
  // Handle optional file for sample work
  let sampleWorkBase64 = null;
  if (fileInputRef.current.files[0]) {
    sampleWorkBase64 = await fileToBase64(fileInputRef.current.files[0]);
  }
    //Add the remaining fields to the formData object
    formData.contentLinks = urls;
    formData.preferredContentCategories = selectedCategories;
    formData.profilePicture = profilePictureBase64;
    formData.sampleWork = sampleWorkBase64;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/content-creator/pending-content-creators`,
        { formData },
        {}
      );
      console.log("Form submitted successfully:", response.data);
      // if status is 200 or 201, then set recorded to true
      setRecorded(true);

      // Handle success: redirect, show success message, etc.
    } catch (error) {
      console.error("Failed to submit form:", error);
      toast.error("Already applied for content creator", {
        position: "bottom-right",
      });

      // Handle error: show error message to user
    }
  };

  return (
    <div className="min-h-screen font-roboto bg-black text-white flex items-center justify-center p-4">
      {recorded ? (
        <div className="text-center">
          <Link to="/">
            <div className="text-center">
              <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
            </div>
          </Link>
          <h1 className="text-3xl font-bold mb-4">
            Your application has been recorded
          </h1>
          <p className="text-xl">
            Thank you for applying. We will review your application and get back
            to you soon.
          </p>
        </div>
      ) : (
        <div className="w-full md:w-2/3 bg-card p-8">
          <img src={Logo} alt="logo" className="mx-auto w-12 mb-4" />
          <h1 className="text-3xl font-bold text-center mb-4">
            Sign Up Content Creator
          </h1>
          <p className="text-center mb-8">
            Join our platform and showcase your talent to the world
          </p>
          <div className="flex flex-col md:flex-row justify-start items-center space-y-4 md:space-y-0 md:space-x-8 p-4 bg-background text-foreground my-4 mb-8">
            <div className="flex flex-col space-x-2 mr-4">
              <div className="flex items-center">
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full ${
                    formData.username &&
                    fileInputRefPfp.current.files[0] &&
                    formData.email &&
                    formData.bio &&
                    formData.social.instagram &&
                    formData.contactNumber &&
                    formData.location
                      ? "bg-[#493676] text-white"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  <TiTick className="w-4 h-4" />
                </div>
                <div
                  className={`w-[80%] ml-2 h-1 rounded-full bg-primary ${
                    formData.username &&
                    fileInputRefPfp.current.files[0] &&
                    formData.email &&
                    formData.bio &&
                    formData.social.instagram &&
                    formData.contactNumber &&
                    formData.location
                      ? "bg-[#493676]"
                      : "bg-gray-300"
                  }`}
                ></div>
              </div>
              <span className="font-semibold text-gray-300 mt-2">
                Personal Info
              </span>
            </div>

            <div className="flex flex-col space-x-2 mr-4">
              <div className="flex items-center">
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full ${
                    formData.motivation &&
                    urls.length > 0 &&
                    selectedCategories.length > 0
                      ? "bg-[#493676] text-white"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  <TiTick className="w-4 h-4" />
                </div>
                <div
                  className={`w-[80%] ml-2 h-1 rounded-full bg-primary ${
                    formData.motivation &&
                    urls.length > 0 &&
                    selectedCategories.length > 0
                      ? "bg-[#493676]"
                      : "bg-gray-300"
                  }`}
                ></div>
              </div>
              <span className="font-semibold text-gray-300 mt-2">
                Content Details
              </span>
            </div>
           
          </div>

          <form className="space-y-4 ">
            <div className="col-span-6 ml-2 sm:col-span-4 md:mr-3 relative">
              {/* Photo File Input */}
              <input
                type="file"
                className="hidden"
                ref={fileInputRefPfp}
                onChange={handlePfpChange}
              />

              <label className="block text-lg mb-1 font-extrabold">
                Profile Photo <span className="text-red-600 ml-1">*</span>
              </label>

              <div className="mt-4 text-center">
                {/* Current Profile Photo */}
                {!photoPreview && (
                  <div className="mt-2 relative">
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      className="w-40 h-40 rounded-full shadow"
                      alt="Current profile"
                    />
                    <button
                      type="button"
                      className="absolute bottom-2 left-2 p-1 bg-white hover:bg-white/80 text-black rounded-full"
                      onClick={handlePfpClick}
                      title="Select New Photo"
                    >
                      <FaPen />
                    </button>
                  </div>
                )}

                {/* New Profile Photo Preview */}
                {photoPreview && (
                  <div className="mt-2 relative">
                    <span
                      className="block w-40 h-40 rounded-full shadow"
                      style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundImage: `url('${photoPreview}')`,
                      }}
                    ></span>
                    <button
                      type="button"
                      className="absolute bottom-2 left-2 p-1 bg-white hover:bg-white/80 text-black rounded-full"
                      onClick={handlePfpClick}
                      title="Select New Photo"
                    >
                      <FaPen />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div>
              <label className="block mb-1 font-extrabold text-lg">
                Username <span className="text-red-600 ml-1">*</span>
              </label>
              <input
                type="text"
                className="w-full p-2 border border-border rounded text-black"
                placeholder="Enter Your Full Name"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block mb-1 font-extrabold text-lg">
                Email <span className="text-red-600 ml-1">*</span>
              </label>
              <input
                type="email"
                className="w-full p-2 border border-border rounded text-black"
                placeholder="Enter Your Email Address"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div>
            <label className="block mb-1 font-extrabold text-lg">
              Password <span className="text-red-600 ml-1">*</span>
              </label>
              <input
              type="password"
                className="w-full p-2 border border-border rounded text-black"
                placeholder="Enter your password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              ></input>
            </div>
            <div>
              <label className="block mb-1 font-extrabold text-lg">
                About <span className="text-red-600 ml-1">*</span>
              </label>
              <textarea
                className="w-full p-2 border border-border rounded text-black min-h-32 resize-none"
                placeholder="Tell Us About Yourself"
                name="bio"
                value={formData.bio}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div>
              <label className="block mb-1 font-extrabold text-lg">
                Bio and Why do you want to join Unindulgent? (Motivation or reason):{" "}
                <span className="text-red-600 ml-1">*</span>
              </label>
              <textarea
                className="w-full p-2 border border-border rounded text-black min-h-32 mb-4 resize-none"
                placeholder="Share your motivation or reason for joining"
                name="motivation"
                value={formData.motivation}
                onChange={handleInputChange}
              ></textarea>
            </div>
            
            <label className="block mb-2 text-lg font-extrabold">Social Media Handles</label>
              
            <div className="relative flex flex-col mb-4">
              <label className="block mb-1 text-white">
                Instagram ID
              </label>
              <div className="flex items-center border bg-white border-border rounded text-black">
                <span className="p-2">
                  <FaInstagram className="text-gray-500" />
                </span>
                <input
                  name="social.instagram"
                  value={formData.social.instagram}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
                  placeholder="Enter Your Instagram Handle"
                />
              </div>
            </div>

            <div className="relative flex flex-col mb-4">
              <label className="block mb-1 text-white">TikTok ID</label>
              <div className="flex items-center border bg-white border-gray-300 rounded text-black">
                <span className="p-2">
                  <FaTiktok className="text-gray-500" />
                </span>
                <input
                  name="social.tiktok"
                  value={formData.social.tiktok}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
                  placeholder="Enter Your TikTok Handle"
                />
              </div>
            </div>

            {/* Twitter Input */}
            <div className="relative flex flex-col mb-4">
              <label className="block mb-1 text-white">Twitter ID</label>
              <div className="flex items-center border bg-white border-gray-300 rounded text-black">
                <span className="p-2">
                  <FaTwitter className="text-gray-500" />
                </span>
                <input
                  name="social.twitter"
                  value={formData.social.twitter}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
                  placeholder="Enter Your Twitter Handle"
                />
              </div>
            </div>

            {/* Facebook Input */}
            <div className="relative flex flex-col mb-4">
              <label className="block mb-1  text-white">Facebook ID</label>
              <div className="flex items-center border bg-white border-gray-300 rounded text-black">
                <span className="p-2">
                  <FaFacebook className="text-gray-500" />
                </span>
                <input
                  name="social.facebook"
                  value={formData.social.facebook}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
                  placeholder="Enter Your Facebook Handle"
                />
              </div>
            </div>

            {/* LinkedIn Input */}
            <div className="relative flex flex-col mb-4">
              <label className="block mb-1  text-white">LinkedIn ID</label>
              <div className="flex items-center border bg-white border-gray-300 rounded text-black">
                <span className="p-2">
                  <FaLinkedin className="text-gray-500" />
                </span>
                <input
                  name="social.linkedin"
                  value={formData.social.linkedin}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full p-2 pl-0 border-0 focus:ring-0 outline-none"
                  placeholder="Enter Your LinkedIn Handle"
                />
              </div>
            </div>
            
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block mb-1 font-extrabold text-lg mt-4 text-white">
                  Content Links
                </label>
                {urls.map((url, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="url"
                      value={url}
                      onChange={(event) => handleUrlsChange(index, event)}
                      placeholder="e.g. YouTube, Blogs, Portfolio"
                      className="w-full p-2 border border-gray-300 rounded-md text-black mr-2"
                    />
                    {urls.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveInput(index)}
                        className="p-2 bg-red-500 text-white rounded-md"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddInput}
                  className="p-2 bg-[#493676] hover:bg-[#493676]/80 text-white rounded-md mt-2"
                >
                  Add Another URL
                </button>
              </div>
            </div>

            <div className="flex flex-col mb-6">
              <label className="block mb-2 mt-4 font-extrabold text-lg text-white">
                Sample Work
              </label>
              <div className="flex items-center space-x-4">
                <button
                  type="button"
                  onClick={handleFileClick}
                  className="px-4 py-2 rounded-md text-white bg-[#493676] hover:bg-[#493676]/80 focus:outline-none focus:ring-2 focus:ring-opacity-50"
                >
                  Upload File
                </button>
                {fileName && (
                  <div className="text-white truncate">{fileName}</div>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
              />
            </div>
            <div className="flex flex-col mb-6">
              <h1 className="block mb-2 mt-4 text-white text-lg font-extrabold">
                Preferred Content Categories{" "}
                <span className="text-red-600 ml-1">*</span>
              </h1>

              <div className={`mb-4 pt-2 px-2 bg-gray-100 border border-gray-300 rounded-md flex flex-wrap items-center ${selectedCategories.length === 0 ? 'h-12' : ''}`}>
                {selectedCategories.map((category) => (
                  <div
                    key={category}
                    className="flex items-center bg-gray-200 text-gray-700 rounded-full px-3 py-1 mr-2 mb-2"
                  >
                    {category}
                    <button
                      type="button"
                      onClick={() => handleRemoveCategory(category)}
                      className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex flex-wrap">
                {availableCategories.map((category) => (
                  <div
                    key={category}
                    onClick={() => handleCategoryClick(category)}
                    className="cursor-pointer bg-gray-200 text-gray-700 rounded-full px-3 py-1 mr-2 mb-2 hover:bg-gray-300"
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
              <div className="flex-1">
                <label className="block mb-1 text-lg font-extrabold">
                  Contact Number <span className="text-red-600 ml-1">*</span>
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-border rounded text-black"
                  placeholder="+1 (341) 263-0949"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex-1 ">
                <label className="block mb-1 text-lg font-extrabold">
                  City <span className="text-red-600 ml-1">*</span>
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-border rounded text-black"
                  placeholder="New York"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="w-full flex justify-center">
              <button
                type="submit"
                className="w-[25rem] mt-8 mb-12 py-2 px-24 border border-transparent rounded-full shadow-sm bg-[#493676] text-white font-bold hover:bg-[#493676]/80"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
