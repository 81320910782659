import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReplaceModal = ({ showModal, subscribedCreators, onClose, onReplace, newCreatorId }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-black font-dm-sans bg-opacity-50 flex items-center justify-center z-10">
      <div className="bg-zinc-900 border border-gray-900 text-white rounded-lg p-4 shadow-lg w-11/12 md:w-3/4 lg:w-2/3 overflow-y-auto max-h-[80vh]">
        <h2 className="text-2xl md:text-4xl text-center font-bold mb-8 md:mb-16 mt-4">
          Replace a Subscribed Content Creator
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {subscribedCreators.map((creator) => (
            <ReplaceSubscriptionCard
              key={creator.id}
              id={creator.id}
              name={creator.name}
              imageSrc={creator.profilePicture}
              caption={creator.bio}
              onReplace={onReplace}
              newCreatorId={newCreatorId}
            />
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="py-2 px-4 bg-red-500 text-white rounded-full hover:bg-red-600"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const ReplaceSubscriptionCard = ({ id, name, imageSrc, caption, onReplace, newCreatorId }) => {
  const navigate = useNavigate();

  const sharedStyles = {
    card: "bg-card p-4 rounded-lg hover:bg-zinc-800 duration-300 cursor-pointer flex flex-col items-center",
    button: "py-1 px-3 rounded-full text-base mt-4 flex items-center",
    replaceButton: "bg-[#493676] text-white hover:bg-[#3e2a5a] w-auto",
  };

  const handleNavigation = () => {
    navigate(`/creator/${id}`);
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    onReplace(id, newCreatorId);
  };

  return (
    <div className={sharedStyles.card} onClick={handleNavigation}>
      <img src={imageSrc} alt={name} className="rounded-lg mb-2 h-32 md:h-40 object-cover" />
      <h2 className="text-lg font-bold my-2 text-center">{name}</h2>
      <p className="mb-2 line-clamp-2 text-center">{caption}</p>
      <div className="flex justify-center md:justify-end mb-4 w-full ">
        <button
          className={`${sharedStyles.button} ${sharedStyles.replaceButton}`}
          onClick={handleButtonClick}
        >
          Replace
        </button>
      </div>
    </div>
  );
};

export default ReplaceModal;
